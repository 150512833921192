import axios from 'axios';
import moment from 'moment';
import classNames from 'classnames';
import { IntlShape, useIntl } from "react-intl";
import { DatePicker, DatePickerProps, Select } from "antd";
import { AlertManager, useAlert } from "react-alert";
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useState, useCallback, useEffect } from "react";
import en from 'world_countries_lists/data/countries/en/world.json';
import CountryPhoneInput, { CountryPhoneInputValue, ConfigProvider } from 'antd-country-phone-input';

import { paths } from "@paths";
import { channelSlug } from "@temp/constants";
import { commonMessages } from "@temp/intl";

import { maybe } from "../../../core/utils";
import { Button, Form, TextField } from "../..";
import { TypedAccountRegisterMutation } from "./queries";
import { RegisterAccount } from "./gqlTypes/RegisterAccount";

import "./scss/index.module.scss";

const validationMessage = 'Password Criteria \n- Minimum 8 characters \n- At least 1 lower case \n- At least 1 upper case \n- At least 1 digit'

const PASSWORD_ERROR = [{
  field: 'password',
  message:validationMessage,
}]

const showSuccessNotification = (
  data: RegisterAccount,
  hide: () => void,
  alert: AlertManager,
  intl: IntlShape,
) => {
  const successful = maybe(() => !data.accountRegister.errors.length);

  if (successful) {
    hide();
    alert.show(
      {
        title: data.accountRegister.requiresConfirmation
          ? intl.formatMessage({
            id: "Please check your e-mail for further instructions",
              defaultMessage:
                "Please check your e-mail for further instructions",
            })
          : intl.formatMessage({ id: "New user has been created", defaultMessage: "New user has been created" }),
      },
      { type: "success", timeout: 5000 },
    );
  }
};

const validatePassword = (pass = '') => {
  const hasLowerCase = () => (/[a-z]/.test(pass))
  const hasUpperCase = () => (/[A-Z]/.test(pass))
  const hasDigit = () => (/\d/.test(pass))
  const has8Chars = pass && pass.length >= 8
  return [hasLowerCase(), hasUpperCase(), hasDigit(), has8Chars]
    .filter( condition => condition)
    .length === 4
}

const RegisterForm: React.FC<{ hide: () => void }> = ({ hide }) => {
  const alert = useAlert();
  const intl = useIntl();
  const { signIn } = useAuth();
  const [ isPasswordValid, setPasswordValid ] = useState(true) 
  const [deviceID, setDeviceID] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [isPhoneInputEmpty, setPhoneInputIsEmpty] = useState(false);
  const [showPhoneInputFormatError, setShowPhoneInputFormatError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<CountryPhoneInputValue>({ short: 'MY' });
  const [isDateEmpty, setDateEmpty] = useState(false);
  const [isGenderEmpty, setGenderEmpty] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const token = await executeRecaptcha('signup');
    return !!token
    // Do whatever you want with the token
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (navigator) {
      /* eslint-disable global-require */
      const DeviceUUID = require("device-uuid");
      setDeviceID(new DeviceUUID.DeviceUUID().get());
    }
  }, [navigator]); // eslint-disable-line react-hooks/exhaustive-deps

  const disabledYear = (current) => 
    current.isAfter(moment().subtract(21, 'year'))

  const customDateFormat: DatePickerProps['format'] = (value) =>
    `${value.format('D MMMM YYYY')}`;

  return (
    <TypedAccountRegisterMutation
      onCompleted={data => {
        showSuccessNotification(data, hide, alert, intl)
      }}
    >
      {(registerCustomer, { loading, data }) => {
        return (
          <Form
            errors={[
              ...(maybe(() => data.accountRegister.errors, [])),
              ...(isPasswordValid ? [] : PASSWORD_ERROR),
            ]}
            onSubmit={async (event, { email, password, firstName, lastName }) => {
              event.preventDefault();
              let isValid = await handleReCaptchaVerify()
              if (!isValid) return;
              const redirectUrl = `${location.origin}${paths.accountConfirm}`;

              let validPass = validatePassword(password)
              if (!validPass) {
                return setPasswordValid(false)
              }
              if (dateOfBirth === ''){
                setDateEmpty(true)
                return;
              }
              if (gender === ''){
                setGenderEmpty(true)
                return;
              }
              if (isPhoneInputEmpty || showPhoneInputFormatError) {
                return;
              }
              
              try {
                const result = await axios.post("/api/getDeviceId", { deviceID });
                const { did, didh } = result.data
                const phone = `+${phoneNumber.code}${phoneNumber.phone}`

                registerCustomer({
                  variables: {
                    email,
                    password,
                    redirectUrl,
                    channel: channelSlug,
                    firstName,
                    lastName,
                    phone,
                    xDID: did,
                    xDIDH: didh,
                    dob: dateOfBirth,
                    gender: gender,
                  },
                });
                await signIn(email, password);
                setPasswordValid(true);
                document.dispatchEvent(new Event("new-user-registered"));
              } catch (err) {
                // @ts-ignore
                // console.log("API ERROR: ", err);
              }     
            }}
          >
            <TextField
              name="email"
              autoComplete="email"
              label={intl.formatMessage(commonMessages.eMail)}
              type="email"
              required
            />
            <TextField
              name="firstName"
              autoComplete="firstName"
              label="First name"
              type="text"
              required
            />
            <TextField
              name="lastName"
              autoComplete="lastName"
              label="Last name"
              type="text"
              required
            />
            <DatePicker 
              name="dateOfBirth"
              placeholder="Date of birth"
              style={{
                width: '100%', 
                height: '55px',
                border: isDateEmpty ? '1px solid #fe6e76' : '1px solid #7d7d7d', 
                color: '#7d7d7d', 
                marginBottom: '1rem',
                padding: '4px 16px',
              }}
              format={customDateFormat}
              showToday={false}
              disabledDate={disabledYear}
              defaultPickerValue={moment().subtract(21, 'year')}
              onChange={(date) => {
                // User can also clear value which returns ''
                // So only format if value is provided 
                if (date) {
                  let dob = date.format('YYYY-MM-DD');
                  setDateOfBirth(dob);
                }
              }}
            />
            <p className="helper_text">We only use this information for offers on your birthday.</p>
            {
              isDateEmpty && <div style={{ marginBottom: '1rem' }}><span className='input__error'>Please fill in this field.</span></div>
            }
            <ConfigProvider 
              locale={en}
              areaFilter={(area) => area.name!.includes('Malaysia')}
              areaMapper={(area) => {
                if (area.name?.includes('Malaysia')) {
                  return {
                    ...area,
                    name: 'Malaysia',
                    emoji: '🇲🇾',
                  };
                }
                return area;
              }}
            >
              <CountryPhoneInput
                name="phone"
                value={phoneNumber}
                allowClear
                autoComplete="phone"
                placeholder='123456789'
                pattern='[0-9]+'
                onChange={(v) => {
                  // Standardising how phone numbers are saved in the backend
                  if (v.phone.startsWith('0')) {
                    setShowPhoneInputFormatError(true);
                  } else {
                    setShowPhoneInputFormatError(false);
                  }
                  setPhoneNumber(v);
                }}
                onBlur={(e) => {
                  // Manually setting styling to ensure component behaves similar to other field components in Register form
                  // Added validation to ensure provided phone number is at least 7 digits in length
                  if (phoneNumber.phone === '' || phoneNumber.phone === undefined || phoneNumber.phone.length < 7) {
                    setPhoneInputIsEmpty(true);
                  } else {
                    setPhoneInputIsEmpty(false);
                  }
                }}
                className={
                  classNames('phone-input', {
                    'phone-input-error': isPhoneInputEmpty || showPhoneInputFormatError,
                  })
                }
                style={{
                  marginBottom: isPhoneInputEmpty || showPhoneInputFormatError ? '0px' : '1rem',
                  border: isPhoneInputEmpty || showPhoneInputFormatError ? '1px solid #fe6e76 !important' : 'none',
                  boxShadow: phoneNumber.phone === '' || phoneNumber.phone === undefined ? 'none' : '0 0 0 1px #79DDC4',
                }}
              />
            </ConfigProvider>
            {/* Div text error to notify user that the number 0 is not required at the start of their phone number (for standardization) */}
            {
              showPhoneInputFormatError && <div style={{ marginBottom: '1rem' }}><span className='input__error'>Please remove 0 at the front</span></div>
            }
            {/* Div text error to ensure field value is not empty */}
            {
              isPhoneInputEmpty && <div style={{ marginBottom: '1rem' }}><span className='input__error'>Please fill in this field with the correct format</span></div>
            }
            <Select
              placeholder="Select a gender"
              style={{
                width : "100%",
                height: '55px',
                border: isGenderEmpty ? '1px solid #fe6e76' : '1px solid #7d7d7d', 
                color: '#7d7d7d', 
                padding: '12px 5px',
              }}
              onChange={(value) => {
                setGender(value)
                setGenderEmpty(false)
              }}
              onBlur={(e) => {
                if (!gender){
                  setGenderEmpty(true)
                }
              }}
              
              options={[
                { value: 'M', label: 'Male' },
                { value: 'F', label: 'Female' },
                { value: 'P', label: 'Prefer Not To Say' },
              ]}
              bordered={false}
            />
            {
              isGenderEmpty && <div style={{ marginBottom: '1rem' }}><span className='input__error'>Please fill in this field.</span></div>
            }
            <TextField
              name="password"
              autoComplete="password"
              label={intl.formatMessage(commonMessages.password)}
              type="password"
              required
              style={{ marginTop: '1rem' }}
            />
            <div className="login__content__button">
              
              <Button
                testingContext="submitRegisterFormButton"
                type="submit"
                {...(loading && { disabled: true })}
                onClick={() => {
                  if (phoneNumber.phone === '' || phoneNumber.phone === undefined){
                    setPhoneInputIsEmpty(true)
                  }
                  if (!gender){
                    setGenderEmpty(true)
                  }
                  if (!dateOfBirth){
                    setDateEmpty(true)
                  }
                  return null;
                }}
              >
                {loading
                  ? intl.formatMessage(commonMessages.loading)
                  : intl.formatMessage({ id: "Register", defaultMessage: "Register" })}
              </Button>
            </div>

            <p className="captcha-notice">This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
              <a href="https://policies.google.com/terms"> Terms of Service </a> 
              apply.
            </p>
          </Form>
        );
      }}
    </TypedAccountRegisterMutation>
  );
};


export default RegisterForm;
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field } from "react-final-form";

import { Button } from "@components/atoms";
import { commonMessages } from "@temp/intl";

import { TextField } from "../TextField";
import * as S from "./styles";
import { IProps } from "./types";

export const ResetPasswordForm: React.FC<IProps> = ({
  handleSubmit,
  values,
  tokenError,
  passwordError,
  errors,
}: IProps) => {
  const intl = useIntl();
  return (
    <S.Wrapper>
      <h3>
        <FormattedMessage id="Reset your password" defaultMessage="Reset your password" />
      </h3>

      <p>
        <FormattedMessage id="Please provide new password" defaultMessage="Please provide new password" />
      </p>
      {tokenError && (
        <S.GeneralError>
          <FormattedMessage id="It seems that token for password reset is not valid anymore." defaultMessage="It seems that token for password reset is not valid anymore." />
        </S.GeneralError>
      )}
      <form onSubmit={handleSubmit}>
        <S.InputFields>
          <Field name="password">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage(commonMessages.password)}
                onBlur={(e) => {
                  input.onBlur(e);
                }}
                onChange={(e) => {
                  input.onChange(e);
                }}
                type="password"
                value={values.password}
                errors={
                  errors.password || passwordError
                    ? [
                        {
                          field: "password",
                          message: errors.password || passwordError,
                        },
                      ]
                    : undefined
                }
              />
            )}
          </Field>
          
          <Field name="retypedPassword">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage({ id: "Retype password", defaultMessage: "Retype password" })}
                onBlur={(e) => {
                  input.onBlur(e);
                }}
                onChange={(e) => {
                  input.onChange(e);
                }}
                type="password"
                value={values.retypedPassword}
                errors={
                  errors.retypedPassword
                    ? [
                        {
                          field: "retypedPassword",
                          message: errors.retypedPassword,
                        },
                      ]
                    : undefined
                }
              />
            )}
          </Field>
        </S.InputFields>

        <Button color='gradient' testingContext="submit" type="submit" fullWidth>
          <FormattedMessage id="SET NEW PASSWORD" defaultMessage="SET NEW PASSWORD" />
        </Button>
      </form>
    </S.Wrapper>
  );
};

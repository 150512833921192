import gql from "graphql-tag";

export const priceFragment = gql`
  fragment Price on Money {
    amount
    currency
  }
`;

export const taxedPriceFragment = gql`
  fragment TaxedPrice on TaxedMoney {
    gross {
      ...Price
    }
    net {
      ...Price
    }
  }
  ${priceFragment}
`;

export const basicProductFragment = gql`
  fragment BasicProductFields on Product {
    id
    slug
    name
    thumbnail {
      url
      alt
    }
    attributes {
      attribute {
        name
        id
        unit
      }
      values {
        name
      }
    }
    isAvailableForPurchase
    defaultVariant {
      id
    }
    productType {
      id
      name
    }
    variants {
      id
      name
      quantityAvailable
      media {
        url
      }
      pricing {
        price {
          gross {
            amount
            currency
          }
          net {
            amount
            currency
          }
        }
        priceUndiscounted {
          gross {
            amount
            currency
          }
          net {
            amount
            currency
          }
          }
        }
    }
    thumbnail2x: thumbnail(size: 510) {
      url
    }
  }
`;

export const productPricingFragment = gql`
  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...TaxedPrice
        }
        stop {
          ...TaxedPrice
        }
      }
      priceRange {
        start {
          ...TaxedPrice
        }
        stop {
          ...TaxedPrice
        }
      }
    }
  }
  ${taxedPriceFragment}
`;

export const featuredProductFragment = gql`
  fragment FeaturedProduct on Product {
    ...BasicProductFields
    ...ProductPricingField
    category {
      id
      name
    }
  }
  ${basicProductFragment}
  ${productPricingFragment}
`;

export const featuredProductsFragment = gql`
  fragment FeaturedProducts on Query {
    collection(slug: "featured-products", channel: $channel) {
      id
      name
      backgroundImage {
        url
      }
      products(first: 20) {
        edges {
          node {
            ...FeaturedProduct
            defaultVariant {
              id
            }
            productType {
              id
              name
            }
            variants {
              id
              name
              quantityAvailable
              media {
                url
              }
              attributes {
                attribute {
                  name
                  id
                  unit
                }
                values {
                  name
                }
              }
              pricing {
                price {
                  gross {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                }
                priceUndiscounted {
                  gross {
                    amount
                    currency
                  }
                  net {
                    amount
                    currency
                  }
                  }
                }
            }
          }
        }
      }
    }
  }
  ${featuredProductFragment}
`;

export const attributeFragment = gql`
  fragment Attribute on Attribute {
    id
    name
    slug
    choices(first: 100) {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

export const menuItemFragment = gql`
  fragment MenuItem on MenuItem {
    id
    name
    category {
      id
      name
      slug
    }
    url
    collection {
      id
      name
      slug
    }
    page {
      slug
    }
    parent {
      id
    }
  }
`;

// @ts-nocheck
import Image from 'next/image';
import React, { useState } from 'react';
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

import { paths } from "@paths";
import './SmsUnsubscribe.module.scss';
import { smsUnsubscribe } from './index';
import { Loader } from "@temp/components";
import { Button } from "@components/atoms";
import logo from '../../images/drinkies_logo.png';
import sms_unsubscribe from '../../images/sms_unsubscribe.png';
import sms_unsubscribe_success from '../../images/sms_unsubscribe_success.png';

const BrazeSMSUnsubscribe = ({ externalID }) => {
  const { replace } = useRouter();
  const [loading, setLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    if (isSuccess) {
      replace(
        {
          pathname: paths.home,
        },
      )
      return
    }

    const status = await smsUnsubscribe(externalID)
    setIsSuccess(status)
    setLoading(false)
  }

  return (
    <div className='sms-unsubscribe-wrapper'>
      <div className='container'>
        <Image className="logo" alt="drinkies-logo" src={logo} />
        <Image src={isSuccess ? sms_unsubscribe_success : sms_unsubscribe} className="rounded-16" alt="SMS Unsubscribe" />
        {!isSuccess && <h3><FormattedMessage id="Have our promotions proven to be bothersome?" defaultMessage="Have our promotions proven to be bothersome?" /></h3>}
        {isSuccess ? <p style={{ marginTop: '30px' }}><FormattedMessage id="You have been unsubscribed from our SMS promotion announcements." defaultMessage="You have been unsubscribed from our SMS promotion announcements." /></p> :
          <p><FormattedMessage id="We strive to make our announcements relevant and tentalising while keeping the frequency of those gentle nudges to a minimum. We regret not meeting your expectations and hope to see you on Drinkies for more exclusive deals on our wide array of quality alcoholic offerings." defaultMessage="We strive to make our announcements relevant and tentalising while keeping the frequency of those gentle nudges to a minimum. We regret not meeting your expectations and hope to see you on Drinkies for more exclusive deals on our wide array of quality alcoholic offerings." /></p>}
        <div className='confirmation'>
          <Button onClick={handleSubmit} color="gradient" className="rounded-50" disabled={loading}>
            {loading && <Loader circle height={25} color="#ffffff" />}
            {!loading && (isSuccess ? <FormattedMessage id="GO TO HOMEPAGE" defaultMessage="GO TO HOMEPAGE" /> : <FormattedMessage id="UNSUBSCRIBE FROM SMS" defaultMessage="UNSUBSCRIBE FROM SMS" />)}
          </Button>
        </div>
      </div>
    </div>
  )
}


export default BrazeSMSUnsubscribe

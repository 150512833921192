import gql from "graphql-tag";

export const getUserInfo = gql`
  query {
      me {
        id
        firstName
        lastName
        phone
        email
        dob
        gender
        orders(first:1) { 
            edges {
                node {
                    created
                    shippingAddress {
                    city
                    postalCode
                    countryArea
                    streetAddress1
                    }
                    billingAddress {
                    city
                    postalCode
                    countryArea
                    streetAddress1
                    }
                } 
            }
        }
    }
  }
`;

export const smsUnsubscribeMutation = gql`
  mutation($externalID: String!) {
    brazeSmsUnsubscribe(externalID: $externalID){
    status
   }
  }
`;

export const subscribeNewsletterMutation = gql`
  mutation($user_email: String!) {
    brazeSubscribeNewsletter(userEmail: $user_email){
    status
   }
  }
`;
// @ts-nocheck
import * as React from "react";
import { generatePath } from "react-router";
import Link from 'next/link';
import { isMobile } from "react-device-detect";

import { FeaturedProduct } from "@graphql/gqlTypes/FeaturedProduct";
import { paths } from "@paths";
import { FormattedMessage } from "react-intl";

import { Carousel, ProductListItem } from "..";

import "./scss/ProductsFeatured.module.scss";

interface ProductsFeaturedProps {
  title: string | undefined;
  products: FeaturedProduct[] | undefined;
  link?: string
}

export const ProductsFeatured: React.FC<ProductsFeaturedProps> = ({
  title,
  products,
  link,
}) =>
  products?.length ? (
    <div className="products-featured">
      <div className="container">
        {title &&
          <div className="title-row">
            <h3><FormattedMessage id={title} defaultMessage={title} /></h3>
            <Link href={link || "/collection/featured-products"}>
              <a><FormattedMessage id="SEE ALL" defaultMessage="SEE ALL" /></a>
            </Link>
          </div>
        }
        <Carousel dragging={false}>
          {products.map(product => (
            <ProductListItem 
              product={product} 
              key={product.id}
              href={generatePath(paths.product, { slug: product.slug })} 
            />
          ))}
        </Carousel>
      </div>
    </div>
  ) : null;

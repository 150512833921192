import React from "react";
import dynamic from "next/dynamic";

import * as S from "./styles";
import { IProps } from "./types";

const Icon = dynamic(
  () => import('@components/atoms').then((mod) => mod.Icon),
);

export const OverlayItem: React.FC<IProps> = ({
  children,
  selected,
  testingContextId,
  disabled,
  onClick,
}: IProps) => {
  return (
    <S.Wrapper
      selected={!!selected}
      disabled={!!disabled}
      onClick={onClick}
      data-test="attributeOption"
      data-test-id={testingContextId}
    >
      {children}
      {selected && <Icon name="tick" size={16} data-test="chosenIcon" />}
    </S.Wrapper>
  );
};

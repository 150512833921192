import React from "react";

import ShopProvider from "../components/ShopProvider";
import { SavedLocationContext, DeliverySlotContextProvider } from '../@next/components/contexts';
import Analytics from "@temp/components/Analytics";
import Braze from "@temp/components/Braze";
import {
    Footer,
    MainMenu,
    MetaConsumer,
    OverlayManager,
    OverlayProvider,
    LegalAgeGateway,
} from "../components";
// import OtpCheck from "../components/OtpCheck";
import Notifications from "./Notifications";
import SearchLocation from '../components/SearchLocation';
import { Loader } from "@components/atoms";
import { demoMode } from "@temp/constants";
import { SideBanner } from "@temp/views/Home/SideBanner";
import "./scss/index.module.scss"
import { getSaleorApi } from "@utils/ssr";
import { getCollectionsQuery } from "@temp/queries/queries";

const AppBody = ({
    shopConfig,
    providerValue,
    savedLocation,
    pathname,
    isReferedFromApp,
    loading,
    mainMenu,
    language,
    setValidateAddr,
    setSavedLocation,
    children,
    ageConfirmed,
}) => {
    const [leftcollection, setLeftCollection] = React.useState([])
    const [rightcollection, setRightCollection] = React.useState([])

    const getSideBanner = async () => {
        const { apolloClient } = await getSaleorApi();
        const hasBackgroundImage = item => item.node.backgroundImage

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { data, errors, loading } = await apolloClient
            .query({
                query: getCollectionsQuery,
                fetchPolicy: 'no-cache',
                variables: { 
                channel: 'default-channel',
                perPage: 100,
                filter: { metadata: [{ key: "side-banner", value: "true" }] },
                },
            })

        if (!loading && !errors) {
            let filterdata = data?.collections?.edges.filter(hasBackgroundImage)
            filterdata?.forEach(item => {
                item?.node?.metadata?.forEach(meta => {
                    if (meta.key === 'position' && meta.value === 'left') {
                        setLeftCollection(item)
                    } else if (meta.key === 'position' && meta.value === 'right') {
                        setRightCollection(item)
                    }
                })
            })
        }
    }

    React.useEffect(() => {
        getSideBanner();
        if (window.location.pathname !== '/GDIC/') window.addEventListener("resize", getSizes, false);
          
        let left = document.getElementById("left-banner");
        let right = document.getElementById("right-banner");
        let main = document.getElementById("main-drinkies-menu");
          
        function getSizes() {
            let zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
            if (zoom > 109 && zoom < 124) {
                left.style.width = '50px';
                right.style.width = '50px';
                main.style.maxWidth = '100%';
            } else if (zoom > 124 && zoom < 149) {
                left.style.width = '0px';
                right.style.width = '0px';
                // @ts-ignore
                main.style.zoom = '98%';
            } else if (zoom >= 149) {
                left.style.width = '0px';
                right.style.width = '0px';
                main.style.maxWidth = '100%';
            } else { 
                left.style.width = '365px';
                right.style.width = '365px';
                // @ts-ignore
                main.style.zoom = '100%';
            }
        }
    }, []);

    return (
        <ShopProvider shopConfig={shopConfig}>
            <SavedLocationContext.Provider value={providerValue}>
                <DeliverySlotContextProvider savedLocation={savedLocation}>
                <Analytics />
                <Braze />
                <div className="main-body">
                    {
                        window.location.pathname !== '/GDIC/' ? 
                        <>
                            <SideBanner
                                id="left-banner"
                                collections={leftcollection}
                            />
                            <div className="main-drinkies-menu" id="main-drinkies-menu">  
                                <OverlayProvider pathname={pathname}>
                                    <MetaConsumer />
                                    {!isReferedFromApp && <LegalAgeGateway ageConfirmed={ageConfirmed} />}
                                    {!isReferedFromApp && <MainMenu loading={loading} demoMode={demoMode} menu={mainMenu} language={language} />}
                                    {!isReferedFromApp && <SearchLocation pathname={pathname} setValidateAddr={setValidateAddr} setSavedLocation={setSavedLocation} />}
                                    {loading ? <Loader fullScreen /> : children}
                                    {!isReferedFromApp && <Footer menu={mainMenu} />}
                                    
                                    <OverlayManager />
                                    <Notifications />
                                    {/* <OtpCheck /> */}
                                </OverlayProvider>
                            </div>
                            <SideBanner
                                id="right-banner"
                                collections={rightcollection}
                            />
                        </>
                        :
                        <div style={{ overflow: 'hidden' }}>  
                            <OverlayProvider pathname={pathname}>
                                <MetaConsumer />
                                {!isReferedFromApp && <LegalAgeGateway ageConfirmed={ageConfirmed} />}
                                {!isReferedFromApp && <MainMenu loading={loading} demoMode={demoMode} menu={mainMenu} language={language} />}
                                {!isReferedFromApp && <SearchLocation pathname={pathname} setValidateAddr={setValidateAddr} setSavedLocation={setSavedLocation} />}
                                {loading ? <Loader fullScreen /> : children}
                                {!isReferedFromApp && <Footer menu={mainMenu} />}
                                
                                <OverlayManager />
                                <Notifications />
                                {/* <OtpCheck /> */}
                            </OverlayProvider>
                        </div>
                    }
                </div>
                </DeliverySlotContextProvider>
            </SavedLocationContext.Provider>
        </ShopProvider>
    );
}

export default AppBody;
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".social-icon{padding:1rem .5rem}.social-icon path{fill:#323232 !important}.social-icon svg *{transition:.3s}.social-icon:hover svg *{fill:#79ddc4}", "", {"version":3,"sources":["/vercel/path0/src/components/SocialMediaIcon/scss/index.module.scss"],"names":[],"mappings":"AAA6C,aAAa,kBAAkB,CAAC,kBAAkB,uBAAuB,CAAC,mBAAmB,cAAc,CAAC,yBAAyB,YAAY,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.social-icon{padding:1rem .5rem}.social-icon path{fill:#323232 !important}.social-icon svg *{transition:.3s}.social-icon:hover svg *{fill:#79ddc4}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import Modal from 'react-modal';
import Image from 'next/future/image';
import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";

import './LegalAgeGateway.module.scss';
import over21 from '../../images/over-21.svg';
import logo from '../../images/drinkies_logo.png';
import spongebob from '../../images/spongebob.gif';
import { Button, ButtonLink, Checkbox } from "@components/atoms";

export const LegalAgeGateway = ({ ageConfirmed }) => {
  
  const [firstOpen, setFirstOpen] = useState(true);
  const [secondOpen, setSecondOpen] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);

  if (ageConfirmed.storedValue === true) {
    return null
  }

  const handleYesSubmit = () => {
    setConfirmed(true)
    ageConfirmed.setValue(true)
    setSecondOpen(false)
    setFirstOpen(false)
    document.getElementById('__next').style.filter = null;
  }

  const handleNoSubmit = () => {
    ageConfirmed.setValue(false)
    setSecondOpen(true)
    setFirstOpen(false)
  }

  const handleReturnSubmit = () => {
    setFirstOpen(true)
    ageConfirmed.setValue(false)
    setSecondOpen(false)
  }

  if (firstOpen || secondOpen) {
    document.getElementById('__next').style.filter = 'blur(10px)';
  }

  if (document.getElementsByClassName('modal-styles').length > 0) {
    if (firstOpen) {
      document.getElementsByClassName('modal-styles')[0].style.height = '198px';
    } else {
      document.getElementsByClassName('modal-styles')[0].style.height = '164px';
    }
  }
  


  return (
    <Modal
      isOpen
      className={firstOpen ? 'modal-styles' : 'short-modal-styles'}
      contentLabel="LegalGatewayNo Modal"
      overlayClassName='modal-overlay'
    >
      <div className='column-styles'>
        <div className='row-styles'>
          {firstOpen ? <h3>Are you of legal drinking age?</h3> : <></>}
          <Image src={over21} width={37} height={38} className="image-styles" alt="Legal age" />
        </div>
          {firstOpen ? <p className='words-styles'>I&apos;m aged 
          21 years old and above and a non-muslim</p> : <p className='words-styles'>You must be 21 years old and above and
            non-muslim to access Drinkies</p>
          }
      </div>
      <div className='row'>
        <div className='col-12'>
          {secondOpen ? <button onClick={handleReturnSubmit} className='return-button-styles'>Go back</button> : 
          <div className='col-12'><button onClick={handleYesSubmit} className='yes-button-styles'>Yes, I am</button>
          <button onClick={handleNoSubmit} className='no-button-styles'>No, I am not</button></div>}
        </div>
      </div>
    </Modal>
  )
}
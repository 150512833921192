/* eslint-disable @typescript-eslint/no-shadow */
import moment from 'moment';
import { useCart } from "@saleor/sdk";
import React, { createContext, useEffect, useState, useRef } from 'react';

import useDraught from './useDraught';
import { getSaleorApi } from "@utils/ssr";
import { displayRemovedProducts } from '../../pages/CheckoutPage/utils';
import { getDeliverySlotsQuery, setDeliveryInfoQuery, getDraughtTypeQuery } from './queries';

const DeliverySlotContext = createContext()

const DeliverySlotContextProvider = ({ savedLocation, children }) => {

  const { items, removeItem } = useCart();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  const prevItems = usePrevious(items)

  const [values, setValues] = useState({
    type: 'standard',
    slots: [],
    warehouse_name: undefined,
    warehouse_uuid: undefined,
    delivery_date_from_now: 0,
    delivery_3pl: undefined,
  })

  const [currentDeliverySlot, setCurrentDeliverySlot] = useState();
  const [productDelayingDelivery, setProductDelayingDelivery] = useState();
  const [lastInput, setLastInput] = useState({});
  const [unavailableProducts, setUnavailableProducts] = useState([]);
  const [chilledDelivery, setChilledDelivery] = useState(false);
  const [discreetPackaging, setDiscreetPackaging] = useState(false);
  const [checkCounter, setCheckCounter] = useState(1);

  const getNewItem = (items, prevItems) => items.find(item => {
    let prevIds = (prevItems || []).map(prevItem => prevItem.id);
    return !prevIds.includes(item.id);
  });

  const getSavedPostcode = () => {
    if (!savedLocation) return null;
    let savedPostcode = savedLocation.gmaps.address_components.find(item => item.types.includes('postal_code'))?.long_name;
    return savedPostcode;
  }

  useEffect(() => {
    if (items && items.filter(item => item.variant?.id).length === items.length && JSON.stringify(items) !== JSON.stringify(prevItems || {})) {
      const newItem = getNewItem(items, prevItems);
      checkDraughtProducts();
      const lines = items?.map(item => {
        return {
          quantity: item.quantity,
          variant: {
            id: item.variant.id,
            product: {
              id: item.variant.product?.id,
              name: item.variant.product?.name,
            },
          },
        }
      });
      getDeliverySlots({
        lines,
        date_selected_from_now: 0,
        newItem,
      });
    }
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTimeout(() => {
      checkUnavailableInCart(null, true)
    }, 3000);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (items && items.length) {
      checkUnavailableInCart()
    }
  }, [getSavedPostcode(), checkCounter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onRecheckCartItems = e => {
      setCheckCounter(counter => counter + 1);
    }
    document.addEventListener("recheck-cart-items", onRecheckCartItems);
    return () => {
      document.removeEventListener("recheck-cart-items", onRecheckCartItems);
    };
  }, []);

  const checkUnavailableInCart = async (newPostCode, isCheckingStock) => {
    let removed = []
    if (items) {
      if (isCheckingStock) {
        await Promise.all(items.map(item => {
          return new Promise(resolve => {
            if (item.product && unavailableProducts.includes(item.product.id)) {
              removed.push(item.variant.product.name)
              removeItem(item.variant.id).then(() => resolve())
            } else {
              resolve()
            }
          })
        }))
      } else {
        // Postcode change
        await Promise.all(items.map(item => {
          return new Promise(resolve => {
            checkIfAvailable({ ...item.variant.product, variants: [item.variant] }, null, true, newPostCode)
              .then(isAvailable => {
                if (!isAvailable) {
                  removed.push(item.variant.product.name)
                  removeItem(item.variant.id).then(() => resolve())
                } else {
                  resolve()
                }
              })
          })
        }))
      }
      if (removed.length) {
        await displayRemovedProducts(removed)
      }
    }
    return removed
  }

  const getDeliverySlots = async (input, newPostCode) => {
    let { postcode, lines, delivery_date_from_now, newItem, isCheckingAvailability, isCheckingTimeslot, isReturnValue = false } = (input || {})

    if (newItem && !newItem.totalPrice) {
      // not loaded yet...
      return;
    }

    if (!lines) {
      lines = items
    }

    if (!lines || !lines.length) {
      return;
    }

    if (newPostCode) {
      postcode = newPostCode
    }

    if (!postcode && !lines) {
      postcode = lastInput.postcode
      lines = lastInput.lines
    } else if (!postcode) {
      postcode = getSavedPostcode();
    } else {
      setLastInput({ postcode, lines })
    }

    try {
      const { apolloClient } = await getSaleorApi();
      const { data } = await apolloClient.query({
        query: getDeliverySlotsQuery,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: {
          data: JSON.stringify({
            date_selected_from_now: delivery_date_from_now || 0,
            postcode,
            lines,
          }),
        },
      })

      const { response, slots, type } = handleResponse(data.getDeliveryInfo, newItem);

      if (isCheckingAvailability) {
        let isAvailable = !!response.delivery_warehouse_name;
        return isAvailable;
      } if (isReturnValue) {
        return {
          type,
          slots,
          warehouse_name: response.delivery_warehouse_name,
          warehouse_uuid: response.delivery_warehouse_uuid,
          delivery_date_from_now: response.delivery_date_from_now,
          delivery_3pl: response.delivery_3pl,
          delivery_date_possible: response.delivery_date_possible,
          warehouse_status: (response.in_stock === "NO" && !response.delivery_warehouse_name) ? "Out Of Stock" : (response.in_stock === "YES" && !response.delivery_warehouse_name) ? "Unavailable" : "Add",
        }
      }
      setValues({
        type,
        slots,
        warehouse_name: response.delivery_warehouse_name,
        warehouse_uuid: response.delivery_warehouse_uuid,
        delivery_date_from_now: isCheckingTimeslot ? values.delivery_date_from_now : response.delivery_date_from_now,
        delivery_3pl: response.delivery_3pl,
        delivery_date_possible: response.delivery_date_possible,
      })
      if (newItem) {
        checkLongerDelivery(newItem, response);
      }

    } catch (err) {
      if (isReturnValue === true) {
        throw new Error(err);
      }
    }
  }

  const handleResponse = (response, newItem) => {
    response = JSON.parse(response);
    let type = response.delivery_date_from_now === 7 ? 'standard' : 'ondemand';
    let slots = processSlots(response);
    return { response, slots, type };
  }

  const processSlots = response => {
    let deliverySlotKeys = Object.keys(response).filter(key => key.includes('slot'));
    return deliverySlotKeys.map(key => {
      let time_from = `${response[key].split('-')[0]}:00`;
      let time_to = `${response[key].split('-')[1]}:00`;
      return {
        text: `${time_from} - ${time_to}`,
        value: response[key],
        hour: response[key].split('-')[0],
      }
    })
  }

  const [
    draughtTypeID,
    isDraughtAllowed,
    checkDraughtProducts,
    draughtProductsInCart,
    setDraughtAllowed,
  ] = useDraught(getDeliverySlots, items);

  const checkIfAvailable = async (prod, isDraught, isForCart, newPostCode) => {
    let lines = mockCart(prod);
    let isAvailabe = await getDeliverySlots({ lines, isCheckingAvailability: true }, newPostCode);


    if (isForCart) {
      return isAvailabe;
    } if (isDraught) {
      setDraughtAllowed(isAvailabe);
    } else if (isAvailabe && unavailableProducts.includes(prod.id)) {
      setUnavailableProducts(values => values.filter(id => id !== prod.id));
    } else if (!isAvailabe) {
      setUnavailableProducts(items => ([...items, prod.id]));
    }
  }

  const checkLongerDelivery = (newItem, response) => {
    if ((items || []).length > 1 && newItem && Number(response.delivery_date_from_now) === 7 && Number(values.delivery_date_from_now) < 7) {
      setProductDelayingDelivery(newItem.variant.product.id);
    } else if (items.length === 1) {
      setProductDelayingDelivery(false);
    }
  }

  const setDeliverySlot = async (delivery_date_from_now, delivery_time_slot, token, deliverySlot) => {
    try {
      const { apolloClient } = await getSaleorApi();
      const { data } = await apolloClient.query({
        query: setDeliveryInfoQuery,
        variables: {
          data: JSON.stringify({
            delivery_date_from_now,
            delivery_time_slot,
            token,
            delivery_warehouse_name: values.warehouse_name,
            delivery_3pl: values.delivery_3pl,
            delivery_date_possible: moment().add(Number(delivery_date_from_now), 'days').format('YYYY-MM-DD'),
          }),
        },
      });
      setCurrentDeliverySlot(deliverySlot);
    } catch (err) {
      // console.log(err)
    }
  }

  const mockCart = prod => ([{
    quantity: 1,
    // totalPrice: prod.variants[0].pricing.price,
    variant: {
      id: prod.variants[0]?.id,
      // ...prod.variants[0],
      product: {
        id: prod.id,
        name: prod.name,
        // productType: prod.productType,
        // slug: prod.slug,
        // thumbnail: prod.thumbnail,
        // thumbnail2x: prod.thumbnail2x
      },
    },
  }])

  return (
    <DeliverySlotContext.Provider value={{
      values,
      getDeliverySlots,
      setDeliverySlot,
      checkUnavailableInCart,
      draughtProductsInCart: (!items || items.length === 1) ? [] : draughtProductsInCart,
      currentDeliverySlot,
      productDelayingDelivery,
      isDraughtAllowed,
      draughtTypeID,
      checkIfAvailable,
      unavailableProducts,
      postcode: getSavedPostcode(),
      chilledDelivery,
      setChilledDelivery,
      discreetPackaging,
      setDiscreetPackaging,
    }}>
      {children}
    </DeliverySlotContext.Provider>
  )
}
export { DeliverySlotContext, DeliverySlotContextProvider };
// @ts-nocheck
import { useRouter } from "next/router";
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import React, { useState, useMemo, useEffect } from "react";

import 'antd/dist/antd.css';
import AppBody from "./AppBody";
import { ShopConfig } from "@utils/ssr";
import 'antd-country-phone-input/dist/index.css';
import { tokenLogin } from "../components/LoginForm";
import { useDynamicRouteRedirect, useLocalStorage } from "@hooks";

type AppProps = ShopConfig & { language: any };

const App: React.FC<AppProps> = ({
  footer,
  mainMenu,
  shopConfig,
  children,
  language,
}) => {
  const router = useRouter();
  const { pathname } = router
  const willRedirect = useDynamicRouteRedirect();
  const { tokenRefreshing, tokenVerifying } = useAuth();
  const loading = tokenRefreshing || tokenVerifying || willRedirect;

  const [savedLocation, setSavedLocation] = useState(null);
  const [promptInput, setPromptInput] = useState(false);
  const [validateAddr, setValidateAddr] = useState(true);
  const providerValue = useMemo(() => ({
    savedLocation, setSavedLocation,
    promptInput, setPromptInput,
    validateAddr, setValidateAddr,
  }), [
    savedLocation, setSavedLocation,
    promptInput, setPromptInput,
    validateAddr, setValidateAddr,
  ]);
  const ageConfirmed = useLocalStorage('isAgeConfirmed', false)

  useEffect(() => {
    (async () => {
      let appleToken = router.asPath.split('&').find( item => item.includes('id_token'))
      if (appleToken) {
        appleToken = appleToken.replace('id_token=', '')
        try {
          await tokenLogin(appleToken, 'apple-id')
          router.push('/', undefined, { shallow: true })
          router.reload()
        } catch (err) {
          // console.log(err)
        }
      }
    })()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (() => {
      // braze SDK options
      const options = {}
      if (window && typeof window.braze !== 'undefined') {
        window.braze.initialize(process.env.NEXT_PUBLIC_BRAZE_API_KEY, {
          ...options,
          baseUrl: process.env.NEXT_PUBLIC_BRAZE_SDK_ENDPOINT,
          enableLogging: process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production',
        })
      }
    })()
  }, [])

  const isReferedFromApp = router.query.ref === 'mobile'

  return (
    <AppBody 
      shopConfig={shopConfig}
      providerValue={providerValue}
      savedLocation={savedLocation}
      pathname={pathname}
      isReferedFromApp={isReferedFromApp}
      loading={loading}
      mainMenu={mainMenu}
      language={language}
      setValidateAddr={setValidateAddr}
      setSavedLocation={setSavedLocation}
      ageConfirmed={ageConfirmed}
    >
      {children}
    </AppBody>
  );
};

export default App;

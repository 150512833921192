exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".not-found-page{display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;padding:2rem 1rem;margin-top:100px}.not-found-page__header{font-size:15rem;font-family:\"Montserrat\";font-weight:900;line-height:100%;color:#79ddc4;padding-bottom:20px}@media(max-width: 540px){.not-found-page__header{font-size:9rem}}.not-found-page__ruler{background:#79ddc4;min-width:10rem;height:2px;margin-top:1rem;margin-bottom:3rem}@media(max-width: 540px){.not-found-page__message p{display:inline}}.not-found-page__button{margin:2rem 0;min-width:88%}", "", {"version":3,"sources":["/vercel/path0/src/components/NotFound/scss/index.module.scss"],"names":[],"mappings":"AAA6C,gBAAgB,aAAa,mBAAmB,uBAAuB,sBAAsB,kBAAkB,kBAAkB,gBAAgB,CAAC,wBAAwB,gBAAgB,yBAAyB,gBAAgB,iBAAiB,cAAc,mBAAmB,CAAC,yBAAyB,wBAAwB,cAAc,CAAC,CAAC,uBAAuB,mBAAmB,gBAAgB,WAAW,gBAAgB,kBAAkB,CAAC,yBAAyB,2BAA2B,cAAc,CAAC,CAAC,wBAAwB,cAAc,aAAa,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.not-found-page{display:flex;align-items:center;justify-content:center;flex-direction:column;text-align:center;padding:2rem 1rem;margin-top:100px}.not-found-page__header{font-size:15rem;font-family:\"Montserrat\";font-weight:900;line-height:100%;color:#79ddc4;padding-bottom:20px}@media(max-width: 540px){.not-found-page__header{font-size:9rem}}.not-found-page__ruler{background:#79ddc4;min-width:10rem;height:2px;margin-top:1rem;margin-bottom:3rem}@media(max-width: 540px){.not-found-page__message p{display:inline}}.not-found-page__button{margin:2rem 0;min-width:88%}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
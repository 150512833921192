/* eslint-disable global-require */
import { generatePath } from "react-router";

import { paths } from "@paths";
import { ssrMode } from "@temp/constants";

export const PRODUCTS_PER_PAGE = 100;
export const SUPPORT_EMAIL = "support@drinkies.my";
export const PROVIDERS = {
  // BRAINTREE: {
  //   label: "Braintree",
  // },
  DUMMY: {
    label: "Dummy",
  },
  STRIPE: {
    label: "Stripe",
  },
  ADYEN: {
    label: "Adyen",
    script: {
      src:
        "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.js",
      integrity:
        "sha384-wG2z9zSQo61EIvyXmiFCo+zB3y0ZB4hsrXVcANmpP8HLthjoQJQPBh7tZKJSV8jA",
      crossOrigin: "anonymous",
    },
    style: {
      src:
        "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.10.1/adyen.css",
      integrity:
        "sha384-8ofgICZZ/k5cC5N7xegqFZOA73H9RQ7H13439JfAZW8Gj3qjuKL2isaTD3GMIhDE",
      crossOrigin: "anonymous",
    },
  },
};
export const STATIC_PAGES = [
  {
    label: "About",
    url: generatePath(paths.page, { slug: "about" }),
  },
];
export const SOCIAL_MEDIA = [
  {
    ariaLabel: "facebook",
    href: "https://www.facebook.com/drinkies.malaysia",
    path: require("../images/facebook-icon.svg"),
  },
  {
    ariaLabel: "instagram",
    href: "https://www.instagram.com/drinkiesmy/",
    path: require("../images/instagram-icon.svg"),
  },
  // {
  //   ariaLabel: "twitter",
  //   href: "https://twitter.com/getsaleor",
  //   path: require("../images/twitter-icon.svg"),
  // },
  // {
  //   ariaLabel: "youtube",
  //   href: "https://www.youtube.com/channel/UCg_ptb-U75e7BprLCGS4s1g/videos",
  //   path: require("../images/youtube-icon.svg"),
  // },
];
export const META_DEFAULTS = {
  custom: [
    { name: "keywords", content: "liquor delivery, alcohol online malaysia, online liquor store, buy alcohol malaysia" },
  ],
  description:
    "Your online drinks store with a wide selection of beers, wines, liquors, soft drinks and more delivered across Malaysia. Shop for RM140 & above to get FREE Delivery",
  image: `${
    !ssrMode ? window.location.origin : ""
  }${require("../images/blade-card.jpg")}`,
  title: "Order Drinks Online in Malaysia | Drinkies",
  type: "ecommerce",
  url: !ssrMode ? window.location.origin : "",
};

import {
  FpxBankElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Form } from "react-final-form";
import React, { useState } from "react";

import { ErrorMessage } from "@components/atoms";
import { IFormError } from "@types";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Stripe credit card form.
 */
const StripeFpxForm: React.FC<IProps> = ({
  formRef,
  formId,
  errors = [],
  onSubmit,
}: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeErrors] = useState<IFormError[]>([]);

  const allErrors = [...errors, ...stripeErrors];

  return (
    <Form
      initialValues={null}
      onSubmit={async values => {
        await onSubmit(stripe, elements);
      }}
      render={({ handleSubmit, values, submitting, valid }) => (
        <S.Form id={formId} ref={formRef} onSubmit={handleSubmit}>
          <FpxBankElement
            // @ts-ignore
            style={{ borderRadius: "10px" }}
            options={{
              accountHolderType: "individual",
              style: {
                base: {
                  backgroundColor: "#fff",
                  fontSize: "15px",
                  lineHeight: "2em",
                  // @ts-ignore
                  padding: "5px 15px",
                },
              },
            }}
          />
          <ErrorMessage errors={allErrors} />
        </S.Form>
      )}
    />
  );
};

export { StripeFpxForm };

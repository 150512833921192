import { createGlobalStyle } from "styled-components";

import { DefaultTheme, media } from ".";

export const GlobalStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  html {
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  .maintenance {
    background-color: #58C2B5;
    display: flex;
    align-items: center;
    img {
      height: unset;
      width: 60vw;
      object-fit: cover;
      margin: 0 auto;
    }
    @media (max-width: 768px) {
      height: 60vh;
      img {
        width: 100vw;
      }
    }
  }

  .placeholder label {
    color:  #858585
  }

  body {
    margin: 0;
    min-width: 320px;
    font-family: ${props => props.theme.typography.baseFontFamily};
    font-size: ${props => props.theme.typography.baseFontSize};
    line-height: ${props => props.theme.typography.baseLineHeight};
    color: ${props => props.theme.colors.baseFont};
    font-family: Helvetica !important;
  }

  input, textarea, button {
    font-family: inherit;
  }

  h1 {
    font-size: ${props => props.theme.typography.h1FontSize};
    line-height: ${props => props.theme.typography.h1LineHeight};

    ${props => media.smallScreen`
      font-size: ${props.theme.typography.h2FontSize};
    `}
  }

  h3 {
    font-size: ${props => props.theme.typography.h3FontSize};
    line-height: 1.7rem;
  }

  h4 {
    font-size: ${props => props.theme.typography.h4FontSize};
  }

  a {
    text-decoration: none;
    font-weight: normal;
    color: inherit;
    &:hover .variant-selector > div:nth-child(2) div {
      color: #79DDC4 !important;
    }
    &:hover .variant-selector > div:nth-child(1) div {
      color: #79DDC4 !important;
    }
    &:hover h4.product-list-item__title {
      color: #79DDC4 !important;
    }
    &:hover{
      color: #79DDC4 !important;
    }
  }

  p {
    line-height: 1.5rem;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  #root,
  #__next {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    & > div:first-of-type {
      flex: 1;
    }
  }

  .slider-frame {
    padding-bottom: 100px !important;
    margin-bottom: -100px !important;
  }
  .slider-control-centerright, .slider-control-centerleft {
    top: calc(50% - 100px) !important;
  }

  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    display:none;
  }

  .geosuggest__suggests--show {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
    display:inline;
  }

  .geosuggest__item {
    height:28px;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }

  #invite-referrals-notification-bar {
    position: unset !important;
  }
`;

import React from 'react';
import { Col, Row } from 'antd';
import Image from 'next/future/image';
import { FormattedMessage } from "react-intl";

import "./scss/index.module.scss";
import email from '../../images/email.svg';
import phone from '../../images/phone.svg';
import whatsapp from '../../images/whatsapp.svg';
import fb_messenger from '../../images/fb_messenger.svg';
import illustration from '../../images/customerservice.png';

const Contact = () => {

  const onClickContact = type => {
    let link;
    if (type === 'whatsapp') {
      link = 'https://wa.me/+60122818888'
    } else if (type === 'messenger')  {
      link = 'https://m.me/drinkies.malaysia'
    } else if (type === 'phone') {
      link = "tel:+60122818888"
    } else {
      link = 'mailto:support@drinkies.my'
    }
    window.open(link, '_blank')
  }

  return (
    <Row style={{ marginTop: 60 }} className="contact">
      <Col xs={24} md={10}>
        <Image src={illustration} alt='Contact us' />
      </Col>
      <Col xs={24} md={14} className="text">
        <h4><FormattedMessage id="Customer service" defaultMessage="Customer service" /></h4>
        <p><FormattedMessage id="Do you still have questions about our draught service? Let us know! We can help you with a chat or a call." defaultMessage="Do you still have questions about our draught service? Let us know! We can help you with a chat or a call." /></p>
        <ContactItem 
          text='Whatsapp us at 012 281 8888'
          icon={whatsapp}
          onClick={() => onClickContact('whatsapp')}
        />
        <ContactItem 
          text='Use Messenger on Drinkies'
          icon={fb_messenger}
          onClick={() => onClickContact('messenger')}
          
        />
        <ContactItem 
          text='Email us at support@drinkies.my'
          icon={email}
          onClick={() => onClickContact('email')}
        />
        <ContactItem 
          text='Call us on 012 281 8888'
          icon={phone}
          onClick={() => onClickContact('phone')}
        />
      </Col>
    </Row>
  )
}

const ContactItem = ({ icon, text, onClick }) => <div onClick={onClick} className="contact-item">
  <Image src={icon} alt='Contact us' width={32} height={32} /> 
  <p>
    <FormattedMessage id={text} defaultMessage={text} />
  </p>
</div>

export default Contact;
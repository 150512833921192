import sha256 from 'crypto-js/sha256';

export const generateExternalID = (email) => {
    if (!email || email.replaceAll(' ', '') === '') {
        throw new Error('Email is required to generate an external ID')
    }
    try {
        return sha256(`aJX{-54cS0uUPDkLt'85;+M.Q}cKtkpp${[...sha256([...email.trim().toLowerCase()].reverse().join('')).toString()].reverse().join('#$@?')}'1s?nSlM1vw1t2y%,'t?7f$fS7y7gI%'`).toString();
    } catch (error) {
        throw new Error(error)
    }
}


// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import { Field } from "react-final-form";
import { InputSelect, TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { Checkbox } from "@components/atoms";
import * as S from "./styles";
import { IProps, PropsWithFormik } from "./types";
import countryAreas from "./countryAreas.json";
import { SavedLocationContext } from "../../contexts";
import SearchLocation from "./SearchLocation";
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import { useLocalStorage } from "@hooks";

export const AddressFormContent: React.FC<IProps> = ({
  touched,
  setTouchedFields,
  formRef,
  handleChange,
  handleBlur,
  formId,
  errors,
  handleSubmit,
  values,
  countriesOptions,
  defaultValue,
  form,
  testingContext,
  includeEmail = false,
  includeNotes,
  editAddress = false,
  setSaveShippingAddress,
  setSaveBillingAddress,
  saveShippingAddress,
  saveBillingAddress,
  savedShippingSelected,
  savedBillingSelected
}) => {
  // const basicInputProps = useCallback(
  //   () => ({ onBlur: handleBlur, onChange: handleChange }),
  //   [handleChange, handleBlur]
  // );

  const intl = useIntl();
  const { user } = useAuth();
  const fieldErrors: any = {};

  useEffect(() => {
    setTouchedFields(touched);
    if (errors && Array.isArray(errors)) {
      errors.map(({ field, message }: { field: string; message: string }) => {
        fieldErrors[field] = fieldErrors[field]
          ? [...fieldErrors[field], { message }]
          : [{ message }];
      });
    }
  }, []);

  const setAddressInfo = (address, type, field, fieldValues, locationName, hasPostCode) => {
    //get address line 1/2 info
    //remove non related info: postal_code, administrative_area_level_1, locality
    let newAddress = cloneDeep(address);
    if (type === "streetAddress") {
      let excludeInfo = [
        "postal_code",
        "administrative_area_level_1",
        "locality",
        "country",
      ];
      for (let x = 0; x < newAddress.length; x++) {
        newAddress.forEach((e, index) => {
          excludeInfo.forEach(i => {
            if (e.types.indexOf(i) >= 0) {
              newAddress.splice(index, 1);
            }
          });
        });
      }
      // console.log("adadawdwa", newAddress)
      //divide remainin item into 2 object
      if (hasPostCode){
        let postcode, result;
        for (const component of address.gmaps.address_components) {
          if (component.types.includes("postal_code")) {
            postcode = component.long_name;
            break; // Stop the loop if the postcode is found
          }
        }
        if (postcode !== null){
          const parts = address.gmaps.formatted_address.split(postcode);
          result = parts[0].trim();
          // // Remove the word 'Malaysia'
          // Remove the trailing comma (',') at the end
          result = result.replace(/,\s*$/, '').trim();          
          // console.log("result", result)
        }
        if (result){
          form.change("streetAddress1", result)
        }
      } else {
        form.change(
          "streetAddress1",
          `${locationName ? `${locationName}, ` : ""}${map(
            newAddress.slice(0, Math.ceil(newAddress.length / 2)),
            i => i.long_name
          ).join(",")}`
        );
      }
      
      //  setFieldValue('streetAddress2', _.map(newAddress.slice(-Math.floor(newAddress.length/2)), i=>i.long_name).join(','))
    } else {
      for (let x = 0; x < newAddress.length; x++) {
        if (newAddress[x].types.indexOf(type) >= 0) {
          if (fieldValues) {
            countryAreas.forEach(i => {
              if (newAddress[x].short_name.indexOf(i.countryArea) >= 0) {
                newAddress[x].short_name = i.countryArea;
              }
            });
            setSelectedState(
              newAddress[x].short_name === "Penang"
                ? "Pulau Pinang"
                : newAddress[x].short_name
            );
          }

          form.change(
            field,
            newAddress[x].short_name === "Penang"
              ? "Pulau Pinang"
              : newAddress[x].short_name
          );

        }
      }
    }
  };

  const onSelectedAddress = e => {
    const { address_components } = e.gmaps;
    const locationName = e.gmaps.name;
    setAddressInfo(address_components, "locality", "city", null, false);
    setAddressInfo(
      address_components,
      "administrative_area_level_1",
      "countryArea",
      { countryArea: "", code: "" }
    , false);
    setAddressInfo(address_components, "postal_code", "postalCode", null, false);
    if (e.no_postcode){
      setAddressInfo(e, "streetAddress", "", null, locationName, true);
    } else {
      setAddressInfo(address_components, "streetAddress", "", null, locationName, false);
    }
    
    
    form.change('streetAddress2', "")
  };

  const setDefaultAddress = defaultAddress => {

    const UPDATE_FIELDS = [
      "city",
      "companyName",
      "firstName",
      "lastName",
      "phone",
      "postalCode",
      "countryArea",
      "streetAddress1",
      "streetAddress2",
    ];

    UPDATE_FIELDS.map(field => {
      if (Object.keys(defaultAddress).indexOf(field) !== -1)
        form.change(field, defaultAddress[field])
    })

  };

  const { savedLocation } = useContext(SavedLocationContext);
  const [selectedState, setSelectedState] = useState("");
  const [updatedLocation, setUpdatedLocation] = useState(null);
  const defaultAddress = useLocalStorage("default-address", "");

  useEffect(() => {
    setSelectedState(values?.countryArea)
    form.change("countryArea", values?.countryArea);
  }, [values?.countryArea]);

  useEffect(() => {
    if (!editAddress) {
      //preset address info if user had selected the delivery address
      if (savedLocation && (!defaultAddress?.storedValue || defaultAddress?.storedValue == "")) {
        if (user) {
          const { firstName, lastName, email } = user
          form.change('firstName', firstName)
          form.change('lastName', lastName)
          form.change('email', email)
          if (user.defaultShippingAddress && !values.phone) {
            const { phone } = user.defaultShippingAddress
            form.change('phone', phone)
          }
        }
        onSelectedAddress(savedLocation);
      } else {
        if (defaultAddress?.storedValue && defaultAddress?.storedValue != "check") {
          if (user) {
            const { email } = user
            form.change('email', email)
          }
          setDefaultAddress(defaultAddress?.storedValue)
        }
      }
    }
  }, []);

  useEffect(() => {
    //if user changes the address, update the address components
    if (updatedLocation && updatedLocation != "") {
      onSelectedAddress(updatedLocation);
    } else {
      if (updatedLocation == ""){
        form.change("streetAddress1", "")
      }
    }
  }, [updatedLocation]);

  useEffect(() => {
    if (savedShippingSelected) {
      form.change('email', savedShippingSelected?.email)
      form.change('orderNotes', savedShippingSelected?.orderNotes)
      setDefaultAddress(savedShippingSelected)
    }
  }, [savedShippingSelected])

  useEffect(() => {
    if (savedBillingSelected) {
      setDefaultAddress(savedBillingSelected)
    }
  }, [savedBillingSelected])

  return (
    <S.AddressForm
      id={formId}
      ref={formRef}
      onSubmit={handleSubmit}
      data-test={testingContext}
    >
      <S.Wrapper>
        <S.RowWithTwoCells>
          <Field name="firstName">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage(commonMessages.firstName)}
                value={values!.firstName}
                autoComplete="given-name"
                errors={fieldErrors!.firstName}
                required={true}
                { ...input }
                wrapperClass="rounded-12"
              />
            )}
          </Field>
          <Field name="lastName">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage(commonMessages.lastName)}
                value={values!.lastName}
                autoComplete="family-name"
                errors={fieldErrors!.lastName}
                required={true}
                { ...input }
                wrapperClass="rounded-12"
              />
            )}
          </Field>
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <Field name="companyName">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage({
                  id: "Company Name (Optional)",
                  defaultMessage: "Company Name (Optional)",
                })}
                value={values!.companyName}
                autoComplete="organization"
                errors={fieldErrors!.companyName}
                { ...input }
                wrapperClass="rounded-12"
              />
            )}
          </Field>
          <Field name="phone">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage(commonMessages.phone)}
                value={values!.phone || undefined}
                autoComplete="tel"
                errors={fieldErrors!.phone}
                required={true}
                { ...input }
                wrapperClass="rounded-12"
              />
            )}
          </Field>
        </S.RowWithTwoCells>
        <S.RowWithOneCell>
          {/* <TextField
            name="streetAddress1"
            label={intl.formatMessage({ defaultMessage: "Address line 1" })}
            value={values!.streetAddress1}
            autoComplete="address-line1"
            errors={fieldErrors!.streetAddress1}
            {...basicInputProps()}
          />
           */}
          <Field name="streetAddress1">
            {({ input, meta }) => (
              <SearchLocation
                label={intl.formatMessage({ id: "Address line 1", defaultMessage: "Address line 1" })}
                value={values!.streetAddress1}
                setUpdatedLocation={setUpdatedLocation}
                formId={formId}
                errors={fieldErrors!.streetAddress1}
                onClear={() => {
                  form.change("streetAddress1", "")
                }}
                { ...input }
              />
            )}
          </Field>
          
        </S.RowWithOneCell>
        <S.RowWithOneCell>
          <Field name="streetAddress2">
            {({ input, meta }) => (
              <TextField
                value={values!.streetAddress2}
                label={intl.formatMessage({
                  id: "Unit number, e.g. A- 18 - 01",
                  defaultMessage: "Unit number, e.g. A- 18 - 01",
                })}
                autoComplete="address-line2"
                errors={fieldErrors!.streetAddress2}
                required={true}
                wrapperClass="rounded-12"
                { ...input }
              />
            )}
          </Field>
        </S.RowWithOneCell>
        <S.RowWithTwoCells>
          <Field name="city">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage({ id: "City", defaultMessage: "City" })}
                value={values!.city}
                autoComplete="address-level2"
                errors={fieldErrors!.city}
                readOnly={true}
                wrapperClass="rounded-12 disabled-field"
                { ...input }
              />
            )}
          </Field>
          <Field name="postalCode">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage({ id: "ZIP/Postal Code", defaultMessage: "ZIP/Postal Code" })}
                value={values!.postalCode}
                autoComplete="postal-code"
                errors={fieldErrors!.postalCode}
                readOnly={true}
                wrapperClass="rounded-12 disabled-field"
                { ...input }
              />
            )}
          </Field>
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <Field name="countryArea">
            {({ input, meta }) => (
              <TextField
                label={intl.formatMessage({ id: "State/province", defaultMessage: "State/province" })}
                name="countryArea"
                value={selectedState}
                errors={fieldErrors!.countryArea}
                readOnly={true}
                wrapperClass="rounded-12 disabled-field"
                { ...input }
              />
            )}
          </Field>
          {includeEmail && (
            <Field name="email">
              {({ input, meta }) => (
                <TextField
                  label={intl.formatMessage(commonMessages.shortEmail)}
                  value={values!.email}
                  autoComplete="email"
                  errors={fieldErrors!.email}
                  required={true}
                  wrapperClass="rounded-12"
                  { ...input }
                />
              )}
            </Field>
          )}
        </S.RowWithTwoCells>
        {includeNotes && (
          <S.RowWithOneCell>
            <Field name="orderNotes">
              {({ input, meta }) => (
                <TextField
                  placeholder={intl.formatMessage({
                    id: "Write here any instructions for delivery. E.g: “drop my delivery at the guardhouse”",
                    defaultMessage: "Write here any instructions for delivery. E.g: “drop my delivery at the guardhouse”",
                  })}
                  value={values!.orderNotes}
                  multiLine
                  wrapperClass="rounded-12"
                  { ...input }
                />
              )}
            </Field>
          </S.RowWithOneCell>
        )}
        {(user && !savedShippingSelected && formId == 'shipping-address-form') && (
          <Checkbox
            name="save-address-checkbox"
            checked={saveShippingAddress}
            onChange={() =>
              setSaveShippingAddress(!saveShippingAddress)
            }
          >
            {intl.formatMessage({ id: "Save this address in my account", defaultMessage: "Save this address in my account" })}
          </Checkbox>
        )}
        {(user && !savedBillingSelected && formId == 'billing-address-form') && (
          <Checkbox
            name="save-address-checkbox"
            checked={saveBillingAddress}
            onChange={() =>
              setSaveBillingAddress(!saveBillingAddress)
            }
          >
            {intl.formatMessage({ id: "Save this address in my account", defaultMessage: "Save this address in my account" })}
          </Checkbox>
        )}
      </S.Wrapper>
    </S.AddressForm>
  );
};

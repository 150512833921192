import React from "react";
import { Modal } from 'antd';
import { NextRouter } from "next/router";
import { ParsedUrlQueryInput } from "querystring";
import { FormattedMessage, IntlProvider } from "react-intl";

import { paths } from "@paths";
import { checkoutMessages } from "@temp/intl";
import type { IItems } from "@saleor/sdk/lib/api/Cart/types";
import type { CartSummaryProps } from "@components/organisms/CartSummary";

export const checkIfShippingRequiredForProducts = (items?: IItems) =>
  items?.some(({ variant }) => variant.product?.productType.isShippingRequired);


export enum CheckoutStep {
  Address = 1,
  Time,
  Payment,
  Review,
  PaymentConfirm,
}

interface CheckoutStepDefinition {
  index: number;
  link: string;
  name: string;
  step: CheckoutStep;
  nextActionName?: string;
  onlyIfShippingRequired?: boolean;
  withoutOwnView?: boolean;
}

export const CHECKOUT_STEPS: CheckoutStepDefinition[] = [
  {
    index: 0,
    link: paths.checkoutAddress,
    name: "Address",
    nextActionName: "Continue to Delivery Time",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Address,
  },
  {
    index: 1,
    link: paths.checkoutTime,
    name: "Time",
    nextActionName: "Continue to Payment",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Time,
  },
  {
    index: 2,
    link: paths.checkoutPayment,
    name: "Payment",
    nextActionName: "Continue to Review",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Payment,
  },
  {
    index: 3,
    link: paths.checkoutReview,
    name: "Review",
    nextActionName: "Place order",
    onlyIfShippingRequired: false,
    step: CheckoutStep.Review,
  },
  {
    index: 4,
    link: paths.checkoutPaymentConfirm,
    name: "Payment confirm",
    onlyIfShippingRequired: false,
    step: CheckoutStep.PaymentConfirm,
    withoutOwnView: true,
  },
];

export type SubpageCompleteHandler = () => void | Promise<void>;

export interface SubpageBaseProps {
  changeSubmitProgress: (submitInProgress: boolean) => void;
  onSubmitSuccess: (pageStep: CheckoutStep, data?: object) => void;
  deliverySlots: any;
  orderNotes?: string;
  setOrderNotes?: any;
  setAddressErrors?: any;
}

export const prepareCartSummaryProducts = (
  items?: IItems,
): CartSummaryProps["products"] | undefined =>
  items?.map(({ id, variant, totalPrice, quantity }) => {
    return {
        id: id || "",
        name: variant.product?.name || "",
        price: {
          gross: {
            amount: totalPrice?.gross.amount || 0,
            currency: totalPrice?.gross.currency || "",
          },
          net: {
            amount: totalPrice?.net.amount || 0,
            currency: totalPrice?.net.currency || "",
          },
        },
        quantity,
        sku: variant.sku || "",
        thumbnail: {
          alt: variant.product?.thumbnail?.alt || undefined,
          url: variant.product?.thumbnail?.url,
          url2x: variant.product?.thumbnail2x?.url,
        },
     }
    });

const continueButtonTextMap: Partial<Record<CheckoutStep, JSX.Element>> = {
  [CheckoutStep.Address]: (
    <FormattedMessage id="Continue to Delivery Time" {...checkoutMessages.addressNextActionName} />
  ),
  [CheckoutStep.Time]: (
    <FormattedMessage id="Continue to Payment"{...checkoutMessages.shippingNextActionName} />
  ),
  [CheckoutStep.Payment]: (
    <FormattedMessage id="Continue to Review"{...checkoutMessages.paymentNextActionName} />
  ),
  [CheckoutStep.Review]: (
    <FormattedMessage id="Place order"{...checkoutMessages.reviewNextActionName} />
  ),
};
export const getContinueButtonText = (step: CheckoutStep) =>
  continueButtonTextMap[step];

export const getAvailableSteps = (items: IItems | undefined) => {
  const isShippingRequired = checkIfShippingRequiredForProducts(items);
  const stepsWithViews = CHECKOUT_STEPS.filter(
    ({ withoutOwnView }) => !withoutOwnView,
  );
  return isShippingRequired
    ? stepsWithViews
    : stepsWithViews.filter(
        ({ onlyIfShippingRequired }) => !onlyIfShippingRequired,
      );
};

export const getCurrentStep = (
  pathname: string,
  steps: CheckoutStepDefinition[],
) => {
  const activeStepIndex = (() => {
    const matchingStepIndex = steps.findIndex(({ link }) => link === pathname);
    return matchingStepIndex !== -1 ? matchingStepIndex : steps.length - 1;
  })();
  const activeStep = steps[activeStepIndex];

  return { activeStepIndex, activeStep };
};

const setDefaultShippintMethod = (availableShippingMethods, setShippingMethod) => {
  const defaultShippingMethodId = availableShippingMethods && availableShippingMethods[0]?.id;
  if (defaultShippingMethodId) {
    setShippingMethod(defaultShippingMethodId);
  }
}

export const stepSubmitSuccessHandler = (
  push: NextRouter["push"],
  steps: CheckoutStepDefinition[],
  activeStepIndex: number,
  availableShippingMethods?: any, 
  setShippingMethod?: any,
) => (currentStep: CheckoutStep, data?: object, delivery?: any, checkoutId?) => {
  
  if (currentStep === CheckoutStep.Time) {
    setDefaultShippintMethod(availableShippingMethods, setShippingMethod);
  }
  if (currentStep === CheckoutStep.Review) {   
    push(
      {
        pathname: paths.orderFinalized,
        query : {
          ...data,
          type: 'card', // for all other payment methods the redirection is from another function,
          delivery,
          checkoutId,
        } as ParsedUrlQueryInput,
      },
      /**
       * Passing orderFinalized path as an `as` param makes query data hidden and
       * behaves simillar to history push state.
       */
      paths.orderFinalized,
    );
  } else {
    push(steps[activeStepIndex + 1].link);
  }
};

export const displayRemovedProducts = (removedItems) => {
  return new Promise( resolve => {
    Modal.info({
      title: <IntlProvider locale="en"><FormattedMessage id='Looks like you have changed your delivery address. Some of the items in your cart are not available for that address and have been removed.' defaultMessage='Looks like you have changed your delivery address. Some of the items in your cart are not available for that address and have been removed.' /></IntlProvider>,
      className: 'styled-modal',
      content: (
        <ul>
          {removedItems.map((productName, index) => <li key={index}>{productName}</li>)}
        </ul>
      ),
      okText: <IntlProvider locale="en"><FormattedMessage id='PROCEED' defaultMessage='PROCEED' /></IntlProvider>,
      width: 880,
      onOk() {
        // @ts-ignore
        resolve()
      },
    });
  })
}
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import React from "react";
import { useAlert } from "react-alert";
import { useIntl } from "react-intl";

import { ServiceWorkerContext } from "@components/containers";

const Notifications: React.FC = () => {
  const alert = useAlert();
  const intl = useIntl();

  const { updateAvailable } = React.useContext(ServiceWorkerContext);

  React.useEffect(() => {
    if (updateAvailable) {
      alert.show(
        {
          actionText: intl.formatMessage({ id: "Refresh", defaultMessage: "Refresh" }),
          content: intl.formatMessage({
            id: "To update the application to the latest version, please refresh the page!",
            defaultMessage:
              "To update the application to the latest version, please refresh the page!",
          }),
          title: intl.formatMessage({
            id: "New version is available!",
            defaultMessage: "New version is available!",
          }),
        },
        {
          onClose: () => {
            location.reload();
          },
          timeout: 0,
          type: "success",
        },
      );
    }
  }, [updateAvailable]); // eslint-disable-line react-hooks/exhaustive-deps

  const { authenticated } = useAuth();
  const [prevAuthenticated, setPrevAuthenticated] = React.useState<
    boolean | undefined
  >();

  React.useEffect(() => {
    if (prevAuthenticated !== undefined && authenticated !== undefined) {
      if (!prevAuthenticated && authenticated) {
        alert.show(
          {
            title: intl.formatMessage({
              id: "You are now logged in",
              defaultMessage: "You are now logged in",
            }),
          },
          { type: "success" },
        );
      } else if (prevAuthenticated && !authenticated) {
        alert.show(
          {
            title: intl.formatMessage({
              id: "You are now logged out",
              defaultMessage: "You are now logged out",
            }),
          },
          { type: "success" },
        );
      }
      setPrevAuthenticated(authenticated);
    } else if (authenticated !== undefined) {
      setPrevAuthenticated(authenticated);
    }
  }, [authenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Notifications;

import { ThemeProvider } from "styled-components";
import { Provider as AlertProvider } from "react-alert";
import * as React from "react";
import { SaleorProvider } from "@saleor/sdk";

import { useLocalStorage } from "@hooks";
import { defaultTheme, GlobalStyle } from "@styles";
import { NotificationTemplate } from "@components/atoms";
import { ServiceWorkerProvider } from "@components/containers";
import { LocaleProvider } from "../components/Locale";
import { NextQueryParamProvider } from "@temp/components";
import { 
    notificationConfig,
    isUnderMaintenance, 
    showMaintenanceBanner, 
    saleorConfig,
} from "./_appConfig";
import BrazeSMSUnsubscribe  from "@temp/components/Braze/SmsUnsubscribe";
import { App as StorefrontApp } from "../app"

export const AppBody = ({
    serviceWorkerTimeout,
    ua,
    footer, 
    mainMenu, 
    shopConfig, 
    Component, 
    pageProps,
    smsUnsubscribe,
}) => {

    const language = useLocalStorage('language', 'en')
    const [ translations, setTranslations ] = React.useState({})
    
    React.useEffect(() => {
        (async () => {
          // @ts-ignore
          const { loadMessagesJson } = await import('../components/Locale');
          const messages = await loadMessagesJson(language.storedValue);
          setTranslations(messages)
        })()
    }, [language.storedValue])

    return (
        <>
            <ThemeProvider theme={defaultTheme}>
                <AlertProvider
                    template={NotificationTemplate as any}
                    {...notificationConfig}
                >
                    <ServiceWorkerProvider timeout={serviceWorkerTimeout}>
                        <LocaleProvider messages={translations} language={language.storedValue}>
                        <GlobalStyle />
                        <NextQueryParamProvider>
                            <SaleorProvider config={saleorConfig}>
                                {smsUnsubscribe !== null ? <BrazeSMSUnsubscribe externalID={smsUnsubscribe}/> :
                                isUnderMaintenance()
                                    ? showMaintenanceBanner()
                                        : <StorefrontApp
                                            footer={footer}
                                            mainMenu={mainMenu}
                                            shopConfig={shopConfig}
                                            language={language}
                                        >
                                            <Component {...pageProps} />
                                        </StorefrontApp>
                                }
                            </SaleorProvider>
                        </NextQueryParamProvider>
                        </LocaleProvider>
                    </ServiceWorkerProvider>
                </AlertProvider>
            </ThemeProvider>
        </>
    );
}

export default AppBody;
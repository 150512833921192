import { ProductVariant } from "@saleor/sdk/lib/fragments/gqlTypes/ProductVariant";
import { OrderByToken_orderByToken_lines_unitPrice } from "@saleor/sdk/lib/queries/gqlTypes/OrderByToken";
import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import { generatePath } from "react-router";
import { useIntl } from "react-intl";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import { paths } from "@paths";

export type ILine = Omit<
  ProductVariant,
  "__typename" | "sku" | "quantityAvailable"
> & {
  quantity: number;
  totalPrice: OrderByToken_orderByToken_lines_unitPrice;
  quantityAvailable?: number;
};

interface ReadProductRowProps {
  mediumScreen: boolean;
  line: ILine;
  attribute: any
}

export interface EditableProductRowProps {
  processing?: boolean;
}

const ProductRow: React.FC<ReadProductRowProps & EditableProductRowProps> = ({
  mediumScreen,
  processing,
  line,
  attribute,
}) => {
  const productUrl = line.product.slug ? generatePath(paths.product, { slug: line.product.slug }) : '';
  const intl = useIntl();

  const getAttributes = () => {
    if (attribute === undefined) return '-';
    let attributes = attribute?.node?.attributes
    let volumeAttribute = attributes && attributes.find(attr => attr?.attribute?.name === 'Volume')
    volumeAttribute = volumeAttribute ? `${volumeAttribute.values[0]?.name || ''}` : ''
    
    let packTypeAttrbute = attributes && attributes.find(attr => attr?.attribute?.name === 'Pack Type')
    packTypeAttrbute = packTypeAttrbute && packTypeAttrbute.values[0]?.name
     ? ` | ${packTypeAttrbute.values[0]?.name.split(' ')[0]} ${intl.formatMessage({ id: packTypeAttrbute.values[0]?.name.split(' ')[1], defaultMessage: packTypeAttrbute.values[0]?.name.split(' ')[1] })}`
     : ''
    return `${volumeAttribute}${packTypeAttrbute}`;
  }

  return (
    <tr
      className={classNames({
        "cart-table-row--processing": processing,
      })}
    >
      <td className="cart-table__thumbnail">
        <div>
          {/* 
            Check if product has a url because certain products that comes with a 
            voucher does not have a link. 
          */}
          {
            productUrl === '' ? 
            <>
              {mediumScreen && (
                <Thumbnail source={line.product} />
              )}
              {line.name}
            </>
            :
            <>
              {mediumScreen && (
                <Link href={productUrl}>
                  <a>
                    <Thumbnail source={line.product} />
                  </a>
                </Link>
              )}
              <Link href={productUrl}>
                <a>{line.name}</a>
              </Link>
            </>
          }
        </div>
      </td>

      {mediumScreen && (
        <td>
          <TaxedMoney taxedMoney={line.pricing === null ? line.totalPrice : line.pricing.price} />
        </td>
      )}

      <td>
        <p>{getAttributes()}</p>
      </td>

      <td className="cart-table__quantity-cell">
        <p>{line.quantity}</p>
      </td>

      <td colSpan={2}>
        <TaxedMoney taxedMoney={line.totalPrice} />
      </td>
    </tr>
  );
};

export default ProductRow;

var escape = require("../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".breadcrumbs{display:inline-flex;flex-wrap:wrap;margin:2rem 0}@media(max-width: 540px){.breadcrumbs{margin:1rem 0}}.breadcrumbs li:not(:last-of-type):after{content:url(" + escape(require("../../../images/breadcrumbs-arrow.svg")) + ");display:inline-block;margin:0 .5rem}.breadcrumbs li a{color:#7d7d7d;text-decoration:none;vertical-align:middle;transition:.3s}.breadcrumbs li a:hover,.breadcrumbs li a:focus{color:#79ddc4}.breadcrumbs__active a{color:#323232;font-weight:600}.breadcrumbs__active a:hover{color:#323232}", "", {"version":3,"sources":["/vercel/path0/src/components/Breadcrumbs/scss/index.module.scss"],"names":[],"mappings":"AAA6C,aAAa,oBAAoB,eAAe,aAAa,CAAC,yBAAyB,aAAa,aAAa,CAAC,CAAC,yCAAyC,sCAAqD,qBAAqB,cAAc,CAAC,kBAAkB,cAAc,qBAAqB,sBAAsB,cAAc,CAAC,gDAAgD,aAAa,CAAC,uBAAuB,cAAc,eAAe,CAAC,6BAA6B,aAAa,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.breadcrumbs{display:inline-flex;flex-wrap:wrap;margin:2rem 0}@media(max-width: 540px){.breadcrumbs{margin:1rem 0}}.breadcrumbs li:not(:last-of-type):after{content:url(\"../../../images/breadcrumbs-arrow.svg\");display:inline-block;margin:0 .5rem}.breadcrumbs li a{color:#7d7d7d;text-decoration:none;vertical-align:middle;transition:.3s}.breadcrumbs li a:hover,.breadcrumbs li a:focus{color:#79ddc4}.breadcrumbs__active a{color:#323232;font-weight:600}.breadcrumbs__active a:hover{color:#323232}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import {
  loadStripe,
  PaymentMethod,
  Stripe,
  StripeElements,
} from "@stripe/stripe-js";
import {
  FpxBankElement,
  CardNumberElement,
  Elements,
} from "@stripe/react-stripe-js";
import Image from "next/image";
import { Collapse } from "antd";
import ReactSVG from "react-svg";
import { Icon } from '@iconify/react';
import { useIntl, FormattedMessage } from "react-intl";
import React, { useEffect, useMemo, useState, useContext } from "react";

import { IProps } from "./types";
import { IFormError } from "@types";
import fpxIcon from "images/fpx.svg";
import cardIcon from "images/card.svg";
import { useLocalStorage } from "@hooks";
import "./StripePaymentGateway.module.scss";
import grabpayIcon from "images/grabpay.jpg";
import { stripeErrorMessages } from "./intl";
import { StripeFpxForm } from "../StripeFpxForm";
import { paymentErrorMessages } from "@temp/intl";
import { StripeGrabForm } from "../StripeGrabForm";
import { DeliverySlotContext } from "../../contexts";
import { StripeCreditCardForm } from "../StripeCreditCardForm";
import { handleConfirmCardPayment, parsePaymentConfirmationData } from "./utils";


const { Panel } = Collapse;

/**
 * Stripe payment gateway.
 */
const StripePaymentGateway: React.FC<IProps> = ({
  config,
  processPayment,
  submitPayment,
  submitPaymentSuccess,
  formRef,
  formId,
  errors = [],
  onError,
}: IProps) => {
  const intl = useIntl();

  const dataCheckout = useLocalStorage("data_checkout", "");
  // @ts-ignore
  const [submitErrors, setSubmitErrors] = useState<IFormError[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [paymentType, setPaymentType] = useState<string | string[]>("card");
  const { currentDeliverySlot } = useContext(DeliverySlotContext)

  const apiKey = config.find(({ field }) => field === "api_key")?.value;

  errors = errors.map( err => ({
    ...err,
    message: err.code === 'parameter_invalid_empty'
      ? 'Please select your bank'
      : err.message,
  }))

  const stripePromise = useMemo(() => {
    if (apiKey) {
      return loadStripe(apiKey);
    }
    const stripeApiKeyErrors = [
      new Error(intl.formatMessage(stripeErrorMessages.gatewayMisconfigured)),
    ];
    setSubmitErrors(stripeApiKeyErrors);
    onError(stripeApiKeyErrors);
    return null;
  }, [apiKey]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = async (
    stripe: Stripe | null,
    elements: StripeElements | null,
  ) => {
    
    if (paymentType === "card") {
      const cartNumberElement = elements?.getElement(CardNumberElement);
      if (!cartNumberElement) {
        const stripeElementsErrors = [
          new Error(intl.formatMessage(stripeErrorMessages.geytwayDisplayError)),
        ];
        setSubmitErrors(stripeElementsErrors);
        onError(stripeElementsErrors);
        return;
      }

      const payload = await stripe?.createPaymentMethod({
        type: "card",
        card: cartNumberElement,
      });

      if (payload?.error) {
        const error = [
          {
            ...payload.error,
            message: payload.error.message || "",
          },
        ];
        setSubmitErrors(error);
        onError(error);
        return;
      }

      if (!payload?.paymentMethod) {
        const stripePayloadErrors = [
          new Error(intl.formatMessage(stripeErrorMessages.paymentSubmissionError)),
        ];
        setSubmitErrors(stripePayloadErrors);
        onError(stripePayloadErrors);
        return;
      }

      const { card } = payload.paymentMethod;
      if (card?.brand && card?.last4) {
        // processPayment(id, {
        //   brand: card?.brand,
        //   expMonth: card?.exp_month || null,
        //   expYear: card?.exp_year || null,
        //   firstDigits: null,
        //   lastDigits: card?.last4,
        // });
        processPayment("creditcard");
        setPaymentMethod(payload.paymentMethod);
      }
    }  else if (paymentType === "grabpay") { 

      const payload = await stripe?.createPaymentMethod({
        // @ts-ignore
        type: "grabpay",
      });

      if (payload?.error) {
        const error = [
          {
            ...payload.error,
            message: payload.error.message || "",
          },
        ];
        setSubmitErrors(error);
        onError(error);
        return;
      }

      processPayment("grabpay");
      setPaymentMethod(payload.paymentMethod);

    }  else {
      const fpxBankElement = elements?.getElement(FpxBankElement);

      const payload = await stripe?.createPaymentMethod({
        type: "fpx",
        fpx: fpxBankElement,
      });

      if (payload?.error) {
        const error = [
          {
            ...payload.error,
            message: payload.error.message || "",
          },
        ];
        setSubmitErrors(error);
        onError(error);
        return;
      }

      processPayment("ebanking");
      setPaymentMethod(payload.paymentMethod);
    }
  };

  const handleFormCompleteSubmit = async () => {
    const stripe = await stripePromise;
    const payment = await submitPayment();
    
    
    if (payment.errors?.length) {
      onError(payment.errors);
      return;
    }

    if (!payment?.confirmationNeeded) {
      submitPaymentSuccess(payment?.order);
      return;
    }

    if (!stripe?.confirmCardPayment) {
      onError([
        new Error(intl.formatMessage(paymentErrorMessages.cannotHandlePaymentConfirmation)),
      ]);
      return;
    }

    if (!payment?.confirmationData) {
      onError([
        new Error(intl.formatMessage(paymentErrorMessages.paymentNoConfirmationData)),
      ]);
      return;
    }

    const { parseError, paymentAction } = parsePaymentConfirmationData(
      payment.confirmationData,
    );

    if (parseError || !paymentAction) {
      onError([
        new Error(intl.formatMessage(paymentErrorMessages.paymentMalformedConfirmationData)),
      ]);
      return;
    }

    if (!paymentMethod?.id) {
      onError([
        new Error(intl.formatMessage(stripeErrorMessages.paymentMethodNotCreated)),
      ]);
      return;
    }

    const { confirmation, confirmationError } = await handleConfirmCardPayment(
      stripe,
      paymentAction,
      paymentMethod,
      dataCheckout?.storedValue?.id,
      currentDeliverySlot,
    );

    if (confirmationError) {
      onError([new Error(confirmationError)]);
      return;
    }

    if (confirmation?.error) {
      onError([new Error(confirmation.error.message)]);
      return;
    }

    handleFormCompleteSubmit();
  };

  useEffect(() => {
    if (stripePromise) {
      (formRef?.current as any)?.addEventListener(
        "submitComplete",
        handleFormCompleteSubmit,
      );
    }
    return () => {
      (formRef?.current as any)?.removeEventListener( // eslint-disable-line react-hooks/exhaustive-deps
        "submitComplete",
        handleFormCompleteSubmit,
      );
    };
  }, [formRef, stripePromise, paymentMethod]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div data-test="stripeGateway">
      <Elements stripe={stripePromise}>
        <Collapse
          accordion
          bordered={false}
          defaultActiveKey="card"
          expandIcon={({ isActive }) =>
            isActive ? (
              <Icon className="panelBtn" icon="mdi:radio-button-checked"/>
            ) : (
              <Icon className="panelBtn" icon="mdi:radio-button-unchecked"/>
            )
          }
          className="site-collapse-custom-collapse"
          onChange={e => setPaymentType(e)}
        >
          <Panel
            header={
              <div className="panelHeader" >
                <ReactSVG className="paymentIcon" path={cardIcon} />
                <span><FormattedMessage id="Credit Card" defaultMessage="Credit Card" /></span>
              </div>
            }
            key="card"
            className="site-collapse-custom-panel"
          >
            <StripeCreditCardForm
              formId={formId}
              formRef={formRef}
              errors={errors}
              onSubmit={handleFormSubmit}
            />
          </Panel>
          <Panel
            header={
              <div className="panelHeader" >
                <ReactSVG className="paymentIcon" path={fpxIcon} />
                <span><FormattedMessage id="Online Banking" defaultMessage="Online Banking" /></span>
              </div>
            }
            key="ebanking"
            className="site-collapse-custom-panel"
          >
            <StripeFpxForm
              formId={formId}
              formRef={formRef}
              errors={errors}
              onSubmit={handleFormSubmit}
            />
          </Panel>
          <Panel
            header={
              <div className="panelHeader" >
                <Image alt="grabpay" style={{ borderRadius: 5 }} src={grabpayIcon} width={37} height={25} />
                <span><FormattedMessage id="Grab Pay" defaultMessage="Grab Pay" /></span>
              </div>
            }
            key="grabpay"
            className="site-collapse-custom-panel site-collapse-custom-panel-grab"
          >
            <StripeGrabForm
              formId={formId}
              formRef={formRef}
              errors={errors}
              onSubmit={handleFormSubmit}
            />
          </Panel>
        </Collapse>
      </Elements>
    </div>
  );
};

export { StripePaymentGateway };

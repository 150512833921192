import * as React from "react";

import "./scss/index.module.scss";

const Loader: React.FC<{ full?: boolean, circle?: boolean, color?: string, height?: number }> = ({ full, circle = false, color = '#79DDC4', height = 15 }) => {
  const getHeight = () => {
    const headerHeight =
      document.getElementById("header") &&
      document.getElementById("header").offsetHeight;
    const footerHeight =
      document.getElementById("footer") &&
      document.getElementById("footer").offsetHeight;
    return window.innerHeight - headerHeight - footerHeight;
  };

  return (circle ? <div className="circle-loader" style={{
    width: `${height}px`,
    height: `${height}px`,
  }}>{[1, 1, 1, 1].map((_, index)=> <div key={index} style={{
    width: `${height - 4}px`,
    height: `${height - 4}px`,
    border: `4px solid ${color}`,
    borderColor: `${color} transparent transparent transparent`,
  }}></div>)}</div> :
    <div className="loader" style={full && { height: getHeight() }}>
      <div className="loader__items">
        <span />
        <span />
        <span />
      </div>
    </div>
  )

};

export default Loader;

import { useOrderDetails } from "@saleor/sdk/lib/react/queries";
import { OrderStatus } from "@saleor/sdk/lib/gqlTypes/globalTypes";
import Link from "next/link";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { defineMessages, FormattedMessage } from "react-intl";

import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";

import * as S from "./styles";
import { IProps } from "./types";

export const messages = defineMessages({
  unfulfilled: {
    id:
      "We’ve emailed you an order confirmation, and we’ll notify you when the order has been shipped.",
    description: "thank you subtitle",
  },
  unconfirmed: {
    id:
      "Your order has been placed, it needs to be confirmed by the staff, we'll send you an email when it's done.",
    description: "thank you subtitle",
  },
});

/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  orderStatus,
  orderNumber,
  continueShoppingUrl,
  orderDetailsUrl,
  delivery,
  type,
  token,
}: IProps) => {

  const { push } = useRouter();

  const { data: order } = useOrderDetails(
    { token },
    { fetchPolicy: "cache-first" },
  );

  const handleRedirectToOrderDetails = () => {
    push(
      {
        // @ts-ignore
        pathname: orderDetailsUrl,
        query: { delivery, type },
      },
    );
  }

  useEffect(() => {
    // @ts-ignore
    if (typeof ir === 'function' && order) {
      // @ts-ignore
      ir('track', {
        orderID: order.number,
        event: 'sale', 
        fname: order.shippingAddress.firstName,
        email: order.userEmail,
        mobile: order.shippingAddress.phone, 
        purchaseValue: order.total.net.amount.toString(),
        order_custom_val: '',
      })
    }
  // @ts-ignore
  }, [order])

  return (
    <Container data-test="thankYouView">
      <S.Wrapper>
        <S.ThankYouHeader>
          <FormattedMessage id="Thank you" defaultMessage="Thank you" />
          <br />
          <span>
            <FormattedMessage id="for your order!" defaultMessage="for your order!" />
          </span>
        </S.ThankYouHeader>
        <S.Paragraph>
          <FormattedMessage id="Your order number is" defaultMessage="Your order number is" />{" "}
          <span>{orderNumber}</span>
        </S.Paragraph>
        <S.Paragraph>
          <FormattedMessage
            {...(orderStatus === OrderStatus.UNCONFIRMED
              ? messages.unconfirmed
              : messages.unfulfilled)}
          />
        </S.Paragraph>
        <S.Buttons>
          <Link href={continueShoppingUrl}>
            <Button
              testingContext="continueShoppingButton"
              color="secondary"
              fullWidth
            >
              <FormattedMessage id="CONTINUE SHOPPING" {...checkoutMessages.continueShopping} />
            </Button>
          </Link>
          <Button onClick={handleRedirectToOrderDetails} testingContext="gotoOrderDetailsButton" fullWidth>
            <FormattedMessage id="ORDER DETAILS" defaultMessage="ORDER DETAILS" />
          </Button>
        </S.Buttons>
      </S.Wrapper>
    </Container>
  );
};

export { ThankYou };

// colors
export const autofillColor = "rgb(250, 255, 189)";
export const autofillColorSelected = "rgb(232, 240, 254)";
export const baseFontColor = "#323232";
export const baseFontColorSemiTransparent = "rgba(50,50,50,0.6)";
export const baseFontColorTransparent = "rgba(50,50,50,0.1)";
export const black = "#323232";
export const blue = "rgb(33,18,94)";
export const blueDark = "#190c4e";
export const blueLight = "#513CA3";
export const blueOverlay = `rgba(33,18,94,0.1)`;
export const blueOverlayDark = `rgba(33,18,94,0.2)`;
export const gray = "#7d7d7d";
export const grayMedium = "#c4c4c4";
export const grayDark = "#323232";
export const grayLight = "#f1f5f5";
export const green = "#3ed256";
export const overlayColor = "rgba(199, 207, 207, 0.8)";
export const rose = "#c22d74";
export const turquoise = "#79DDC4";
export const turquoiseDark = "#06a09e";
export const turquoiseLight = "rgba(6, 132, 123, 0.25)";
export const turquoiseTransparent = "rgba(6, 132, 123, 0.1)";
export const white = "#fff";
export const tabelGray = "#eaeaea";
export const darkGreen = "#06847B";
export const secondaryGrey = "#EBF1F6";
export const secondaryGreyDark = "#9D9FB1";
export const secondaryBlue = "#036DFF";


export const primary = '#7CD971';
export const secondary = '#323232';
export const gray1 = '#979797';
export const gray2 = '#ECECEC';
export const gray3 = '#F2F2F2';
export const white1 = '#FFFFFF';
export const white2 = '#fffffff2';
export const whiteText = '#ffffffe6';
export const error = '#C22D74';
export const lightGreen = '#E3F7ED';
export const gradient1 = 'linear-gradient(180deg, #79DDC4 0%, #6AD08A 100%);';



// theme colors
export const theme = {
  activeMenuOption: secondary,
  autofill: gray1,
  autofillSelected: gray2,
  bannerBackground: gray3,
  bannerEdge: gray1,
  bannerLink: secondaryBlue,
  baseFont: secondary,
  baseFontColorSemiTransparent: gray1,
  baseFontColorTransparent,
  dark: secondary,
  disabled: gray2,
  divider: gray2,
  dividerDark: gray2,
  error,
  lightGreen,
  hoverLightBackground: lightGreen,
  light: gray3,
  lightFont: gray1,
  listAttributeName: gray1,
  listBullet: secondary,
  overlay: overlayColor,
  primary,
  turquoise,
  primaryDark: secondary,
  primaryLight: gray3,
  primaryTransparent: turquoiseTransparent,
  secondary,
  secondaryDark: gray1,
  secondaryLight: gray2,
  secondaryOverlay: overlayColor,
  secondaryOverlayDark: overlayColor,
  success: primary,
  tabTitle: turquoise,
  tableDivider: gray1,
  tabsBorder: gray1,
  thumbnailBorder: turquoise,
  white,
  black,
  gradient1,
};

// typography
export const baseFontFamily = "'Inter', sans-serif";
export const baseFontSize = "1rem"; // 16px
export const baseLineHeight = "1.25rem"; // 20px
export const boldFontWeight = 600;
export const extraBoldFontWeight = 800;
export const h1FontSize = "4rem"; // 64px
export const h2FontSize = "3rem"; // 48px
export const h1LineHeight = 1;
export const h3FontSize = "1.5rem"; // 24px
export const h4FontSize = "1.125rem"; // 18px
export const labelFontSize = "0.75rem"; // 12px
export const smallFontSize = "0.875rem"; // 14px
export const ultraBigFont = "6rem"; // 96px

// spacing
export const spacer = 1; // rem
export const fieldSpacer = "1.875rem";

// breakpoints
export const xxxLargeScreen = 1920;
export const xxLargeScreen = 1600;
export const xLargeScreen = 1280;
export const largeScreen = 992;
export const mediumScreen = 720;
export const smallScreen = 540;

// @ts-nocheck
import * as React from "react";

import { useLocalStorage } from "@hooks";
import { getSaleorApi } from "@utils/ssr";
import { generateExternalID } from "./utils";
import { getUserInfo, smsUnsubscribeMutation } from "./queries";

const getUser = async () => {
    const { apolloClient } = await getSaleorApi();
    const { data } = await apolloClient.query({
        query: getUserInfo,
        fetchPolicy: 'no-cache',
    });

    return data
}

export const changeBrazeUser = async ({ isRegister }) => {
    const user = await getUser();

    if (user === null) {
        return
    }

    const { braze } = window;
    const { id, firstName, lastName, email, phone, dob = null } = user?.me;

    // Split dob date into year, month, and date as required by braze
    const dobDateSplit = dob ? dob.split('-') : null;

    // Latest order shipping and billing address info
    const shippingAddress = user?.me.orders?.edges?.[0]?.node?.shippingAddress || {}
    const billingAddress = user?.me.orders?.edges?.[0]?.node?.billingAddress || {}

    const external_id = await generateExternalID(email)
    braze.changeUser(external_id)
    setBrazeUserAttributes({ firstName, lastName, email, phone, dobDateSplit, isRegister })
    setBrazeUserCustomAttributes({ shippingAddress, billingAddress, isRegister })
}


const setBrazeUserAttributes = (details) => {
    const { braze } = window
    const { firstName, lastName, email, phone, dobDateSplit, isRegister } = details
    braze.getUser().setFirstName(firstName)
    braze.getUser().setLastName(lastName)
    braze.getUser().setEmail(email)
    braze.getUser().setPhoneNumber(phone)
    // Old users may not have provided thier DOB since this is a new feature, so this checks if DOB exists for the current user 
    if (dobDateSplit != null) braze.getUser().setDateOfBirth(dobDateSplit[0], dobDateSplit[1], dobDateSplit[2])
    if (isRegister) {
        braze.getUser().setEmailNotificationSubscriptionType("opted_in")
        braze.getUser().setPushNotificationSubscriptionType("opted_in")
    }
}

const setBrazeUserCustomAttributes = (attributes) => {
    const { city: recent_delivery_city = '', countryArea: recent_delivery_state = '', postalCode: recent_delivery_postcode = '', streetAddress1: recent_delivery_address_1 = '' } = attributes?.shippingAddress
    const { city: recent_billing_city = '', countryArea: recent_billing_state = '', postalCode: recent_billing_postcode = '', streetAddress1: recent_billing_address_1 = '' } = attributes?.billingAddress

    const customAttributes = {
        recent_delivery_city,
        recent_delivery_state,
        recent_delivery_postcode,
        recent_delivery_address_1: recent_delivery_address_1.replace(/"/g, "'"),
        recent_billing_city,
        recent_billing_state,
        recent_billing_postcode,
        recent_billing_address_1: recent_billing_address_1.replace(/"/g, "'"),
        ...(attributes.isRegister === true && { sms_subscribed: true }),
    }
    /* eslint-disable no-console */
    console.log(attributes)
    console.log(customAttributes)
    /* eslint-enable no-console */    
    const { braze } = window
    Object.keys(customAttributes || {}).map(key => {
        braze.getUser().setCustomUserAttribute(key, customAttributes[key]);
    })
}

export const editBrazeUserAttributes = async (details) => {
    const { braze } = window
    const { firstName, lastName, dob } = details

    // Split dob date into year, month, and date as required by braze
    const dobDateSplit = dob ? dob.split('-') : null;
    
    braze.getUser().setFirstName(firstName)
    braze.getUser().setLastName(lastName)
    // Old users may not have provided thier DOB since this is a new feature, so this checks if DOB exists for the current user 
    if (dob != null) braze.getUser().setDateOfBirth(dobDateSplit[0], dobDateSplit[1], dobDateSplit[2])
}


export const pushBrazePurchase = async (user, order) => {
    const { braze } = window

    if (order?.orderID) {
        // successful purchase
        let shippingAddress = {}
        let billingAddress = {}

        if (user) {
            const userInfo = await getUser()
            shippingAddress = userInfo?.me.orders?.edges?.[0]?.node?.shippingAddress || {}
            billingAddress = userInfo?.me.orders?.edges?.[0]?.node?.billingAddress || {}

        } else {
            //  guest purchase
            shippingAddress = order?.shippingAddress || {}
            billingAddress = order?.billingAddress || {}
            const { email, shippingAddress: { firstName, lastName, phone } } = order
            const external_id = await generateExternalID(email)
            braze.changeUser(external_id)
            setBrazeUserAttributes({ firstName, lastName, email, phone, isRegister: false })
        }

        const { orderID, orderNumber, checkoutId, type, totalPrice, items, voucher } = order
        const purchased_products = items?.map(({ quantity, productName, variant }) => {
            const { id, sku, product, pricing: { price: { net: { amount } } } } = variant;
            return {
                name: productName,
                category: product.category?.name,
                sku,
                quantity,
                total_price: amount.toFixed(2),
            }
        });

        const properties =
        {
            order_id: orderID,
            channel: "Web",
            coins_used: 0,
            promo_code: voucher?.code || "",
            time: new Date().toISOString(),
            payment_method: type.toUpperCase(),
            products: purchased_products,
            billing_address: {
                billing_address_1: billingAddress?.streetAddress1?.replace(/"/g, "'") || "",
                billing_city: billingAddress?.city || "",
                billing_state: billingAddress?.countryArea || "",
                billing_postcode: billingAddress?.countryArea || "",
            },
            delivery_address: {
                delivery_address_1: shippingAddress?.streetAddress1?.replace(/"/g, "'") || "",
                delivery_city: shippingAddress?.city || "",
                delivery_state: shippingAddress?.countryArea || "",
                delivery_postcode: shippingAddress?.countryArea || "",
            },
        }

        braze.logPurchase("Order Purchase", totalPrice.toFixed(2), "MYR", 1, properties)
        setBrazeUserCustomAttributes({ shippingAddress, billingAddress, isRegister: false })
    }

}

export const smsUnsubscribe = async (external_id) => {
    if (!external_id) {
        return
    }

    const { apolloClient } = await getSaleorApi();
    const { data } = await apolloClient.mutate({
        mutation: smsUnsubscribeMutation,
        variables: {
            externalID: external_id,
        },
        fetchPolicy: 'no-cache',
    });

    return data?.brazeSmsUnsubscribe?.status

}

const Braze: React.FC<{}> = () => {

    React.useEffect(() => {
        setTimeout(() => {
            //  for social login
            if (localStorage.getItem("userLoginCheck") && localStorage.getItem("userLoginCheck") !== "") {
                changeBrazeUser({ isRegister: false })
                localStorage.removeItem("userLoginCheck")
            }
        }, 2000)
    }, [])

    React.useEffect(() => {
        const onUserLoggedIn = e => {
            changeBrazeUser({ isRegister: false })
        }
        document.addEventListener("new-user-logged-in", onUserLoggedIn);
        return () => {
            document.removeEventListener("new-user-logged-in", onUserLoggedIn);
        };
    }, [])

    React.useEffect(() => {
        const onUserRegistered = e => {
            changeBrazeUser({ isRegister: true })
        }
        document.addEventListener("new-user-registered", onUserRegistered);
        return () => {
            document.removeEventListener("new-user-registered", onUserRegistered);
        };
    }, [])

    return null;
};


export default Braze;

import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";

import { OfflinePlaceholder } from "@components/atoms";

import closeImg from "../../../images/x.svg";
import {
  Offline,
  Online,
  Overlay,
  OverlayContextInterface,
  OtpRequestForm,
} from "../..";

import "./scss/index.module.scss";

class Otp extends React.Component<
  { overlay: OverlayContextInterface; }
> {
  static defaultProps = {
    active: "login",
  };

  constructor(props) {
    super(props);
    this.state = {
      // active: props.active,
    };
  }

  render() {
    const { overlay } = this.props;
    const { hide } = overlay;

    return (
      <Overlay testingContext="passwordOverlay" context={overlay}>
      <div className="password-reset">
        <Online>
          <div className="overlay__header">
            <p className="overlay__header-text">
              <FormattedMessage id="" defaultMessage="" />
            </p>
            <ReactSVG
              path={closeImg}
              onClick={hide}
              className="overlay__header__close-icon"
            />
          </div>
          <div className="password-reset__content">
            <OtpRequestForm hide={hide}/>
          </div>
        </Online>
        <Offline>
          <OfflinePlaceholder />
        </Offline>
      </div>
    </Overlay>
    );
  }
}


export default Otp;

import React from "react";
import dynamic from "next/dynamic";

import { Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";

import * as S from "./styles";
import { IProps } from "./types";

const AdyenPaymentGateway = dynamic(() => import('..').then((mod) => mod.AdyenPaymentGateway));
const DummyPaymentGateway = dynamic(() => import('..').then((mod) => mod.DummyPaymentGateway));
const StripePaymentGateway = dynamic(() => import('..').then((mod) => mod.StripePaymentGateway));

/**
 * Payment Gateways list
 */
const PaymentGatewaysList: React.FC<IProps> = ({
  paymentGateways,
  selectedPaymentGateway,
  selectedPaymentGatewayToken,
  selectPaymentGateway,
  formRef,
  formId,
  processPayment,
  submitPayment,
  submitPaymentSuccess,
  errors,
  onError,
}: IProps) => {
  return (
    <S.Wrapper>
      {paymentGateways.map(({ id, name, config }, index) => {
        const checked = selectedPaymentGateway === id;

        switch (name) {
          case PROVIDERS.DUMMY.label:
            return (
              <div key={index}>
                <S.Tile checked={checked}>
                  <Radio
                    data-test="checkoutPaymentGatewayDummyInput"
                    name="payment-method"
                    value="dummy"
                    checked={checked}
                    onChange={() =>
                      selectPaymentGateway && selectPaymentGateway(id)
                    }
                    customLabel
                  >
                    <span data-test="checkoutPaymentGatewayDummyName">
                      {name}
                    </span>
                  </Radio>
                </S.Tile>
                {checked && (
                  <DummyPaymentGateway
                    formRef={formRef}
                    formId={formId}
                    processPayment={token => processPayment(id, token)}
                    initialStatus={selectedPaymentGatewayToken}
                  />
                )}
              </div>
            );
          case PROVIDERS.ADYEN.label:
            return (
              <div key={index}>
                <S.Tile checked={checked}>
                  <Radio
                    data-test="checkoutPaymentGatewayAdyenInput"
                    name="payment-method"
                    value="adyen"
                    checked={checked}
                    onChange={() =>
                      selectPaymentGateway && selectPaymentGateway(id)
                    }
                    customLabel
                  >
                    <span data-test="checkoutPaymentGatewayAdyenName">
                      {name}
                    </span>
                  </Radio>
                </S.Tile>
                {checked && (
                  <AdyenPaymentGateway
                    config={config}
                    formRef={formRef}
                    scriptConfig={PROVIDERS.ADYEN.script}
                    styleConfig={PROVIDERS.ADYEN.style}
                    processPayment={(e) => { processPayment( id, '', e) }}
                    submitPayment={submitPayment}
                    submitPaymentSuccess={submitPaymentSuccess}
                    errors={errors}
                    onError={onError}
                  />
                )}
              </div>
            );

            case PROVIDERS.STRIPE.label:
              return (
                <div key={index}>
                  {/* <S.Tile checked={checked}>
                    <Radio
                      data-test="checkoutPaymentGatewayStripeInput"
                      name="payment-method"
                      value="stripe"
                      checked={checked}
                      onChange={() =>
                        selectPaymentGateway && selectPaymentGateway(id)
                      }
                      customLabel
                    >
                      <span data-test="checkoutPaymentGatewayStripeName">
                        {name}
                      </span>
                    </Radio>
                  </S.Tile> */}
                  {/* {checked && ( */}
                    <StripePaymentGateway
                      config={config}
                      formRef={formRef}
                      formId={formId}
                      // @ts-ignore
                      processPayment={(e) =>{
                        processPayment(id, '', e )
                      }
                      }
                      submitPayment={submitPayment}
                      submitPaymentSuccess={submitPaymentSuccess}
                      errors={errors}
                      onError={onError}
                    />
                  {/* )} */}
                </div>
              );
  

          default:
            return null;
        }
      })}
      {/* {!selectedPaymentGateway && errors && <ErrorMessage errors={errors} />} */}
    </S.Wrapper>
  );
};

export { PaymentGatewaysList };

import Link from "next/link";
import * as React from "react";
import { generatePath } from "react-router";
import { FormattedMessage } from "react-intl";

import { MenuItem } from "@graphql/gqlTypes/MenuItem";
import { paths } from "@paths";

interface NavLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  item: MenuItem;
}
export const NavLink: React.FC<NavLinkProps> = ({ item, ...props }) => {

  const { name, url, category, collection, page } = item;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const link = (url: string) => (
    <Link passHref href={url}>
      <a {...props}><FormattedMessage id={name} defaultMessage={name} /></a>
    </Link>
  );

  if (['draught-service', 'blade'].includes(category?.slug)) {
    return link(generatePath(paths.draught, {
      slug: category.slug,
    }))
  }

  if (name === 'Draught Beer') {
    return link(generatePath(paths.draughtMain));
  }

  if (['tiger', 'heineken', 'apple fox', 'guinness', 'edelweiss'].includes(name.toLowerCase()) || item.parent?.id ===  "TWVudUl0ZW06MjM=") {
    return link(generatePath(paths.brand, { slug: name }));
  }

  if (url) {
    return (
      <a href={url} {...props}>
        <FormattedMessage id={name} defaultMessage={name} />
      </a>
    );
  }
  

  if (category) {
    return link(generatePath(paths.category, { slug: category.slug }));
  }
  if (collection) {
    return link(generatePath(paths.collection, { slug: collection.slug }));
  }
  if (page) {
    return link(generatePath(paths.page, { slug: page.slug }));
  }

  return <span {...props}><FormattedMessage id={name} defaultMessage={name} /></span>;
};

// @ts-nocheck
/* eslint-disable */

import React, { useEffect, useState, useRef, useContext } from "react";
import Geosuggest from "@ubilabs/react-geosuggest";
import { Icon } from '@iconify/react';
import Modal from "react-modal";
import { isMobile } from "react-device-detect";
import { useLocalStorage } from "@hooks";
import { FormattedMessage } from "react-intl";

const SUGGEST_ITEM_HEIGHT = 38;
const DEFAULT_TITLE =
  "Please enter your delivery address first to make sure we can deliver to you. We currently deliver in Peninsular Malaysia.";
// const INVALID_STATE = ["Sabah", "Sarawak"];
const INVALID_STATE = [];

const SearchLocation = props => {
  const [inputValue, setInputValue] = useState(props.value ? props.value : "");
  const [focusInput, setFocusInput] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [selected, setSelected] = useState(null);
  const [suggestCount, setSuggestCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [newAddress, setNewAddress] = useState(null);
  const geosuggestEl = useRef(null);

  const persistDeliveryLocation = useLocalStorage("deliveryLocation", "");

  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
      setSelected(true);
      setIsInvalid(false);
    }
  }, [props.value]);

  const checkPostcode = async (e) => {
    const hasPostcode = e?.gmaps?.address_components.some((component) => component.types.includes('postal_code'))
    if (!hasPostcode){
      let lat = e?.location?.lat
      let lng = e?.location?.lng
      let latlng = lat + "," + lng
      let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}`
      try {
        const response = await fetch(url); // Replace this URL with your API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        let new_address = {
          'gmaps': data.results[0],
          'no_postcode': true
        }
        setNewAddress(new_address)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      props.setUpdatedLocation(e);
    }
  }

  useEffect(() => {
    if (newAddress !== null){
      props.setUpdatedLocation(newAddress);
      setSelected(newAddress)
      persistDeliveryLocation.setValue(newAddress);
    }
  }, [newAddress, props])


  const checkState = (address, type) => {
    for (let x = 0; x < address.length; x++) {
      if (address[x].types.indexOf(type) >= 0) {
        if (INVALID_STATE.indexOf(address[x].short_name) >= 0) {
          return false;
        } else {
          return true;
        }
      }
    }
    return false;
  };

  const renderSuggestItem = i => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <span style={{ flex: 1, maxWidth: "95%" }}>
          <p
            style={{
              ...styles.itemText,
              overflow: "hidden",
              width: "calc(100%)",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            dangerouslySetInnerHTML={{
              __html: i.description.replace(
                new RegExp(inputValue, "gi"),
                match => `<strong>${match}</strong>`
              ),
            }}
          ></p>
        </span>
        <span style={{ flex: 0 }}>
          <Icon icon="bi:geo-alt" width="20" height="20"/>
        </span>
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          ...styles.container,
        }}
        className="location-search-container"
      >
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <Geosuggest
            initialValue={inputValue}
            autoComplete="off"
            ref={geosuggestEl}
            onChange={val => {
              props?.onChange(val);
              setSelected(null);
              setInputValue(val);
            }}
            onFocus={() => setFocusInput(true)}
            onBlur={() => {
              if (!selected && inputValue !== "") {
                setInputValue("");
                props.onClear();
                setIsInvalid(true);
              } else {
                setIsInvalid(false);
              }
              setFocusInput(false);
            }}
            renderSuggestItem={renderSuggestItem}
            placeholder=""
            style={{
              input: {
                ...styles.geoInput,
                border: focusInput
                  ? `1px solid ${
                      inputValue == "" || !selected ? "#C22D74" : "#AAAAAA"
                    }`
                  : `0.5px solid ${
                      inputValue == "" || !selected ? "#C22D74" : "#AAAAAA"
                    }`,
                outline: focusInput
                  ? `1px solid ${
                      inputValue == "" || !selected ? "#C22D74" : "#323232"
                    }`
                  : `0.5px solid ${
                      inputValue == "" || !selected ? "#C22D74" : "#AAAAAA"
                    }`,
              },
              suggests: styles.geoSuggest,
              suggestItem: { marginBottom: 10 },
            }}
            country="my"
            onUpdateSuggests={e => {
              setSuggestCount(e.length);
            }}
            skipSuggest={suggest => {
              if (
                suggest.types.includes("political") ||
                suggest.types.includes("route")
              ) {
                return true;
              }
            }}
            onSuggestSelect={e => {
              if (e) {
                setIsInvalid(false);
                setFocusInput(false);
                setSelected(e);
                if (
                  checkState(
                    e?.gmaps?.address_components,
                    "administrative_area_level_1"
                  )
                ) {
                  checkPostcode(e)
                  if (props.formId == "shipping-address-form") {
                    persistDeliveryLocation.setValue(e);
                  }
                } else {
                  setInputValue("");
                  persistDeliveryLocation.setValue("");
                  setShowModal(true);
                }
              } else {
                props.setUpdatedLocation("");

                if (props.formId == "shipping-address-form") {
                  persistDeliveryLocation.setValue("");
                }
              }

              document.dispatchEvent(new Event("delivery-location-changed"));
            }}
          />
          <label
            style={{
              position: "absolute",
              left:
                selected || focusInput || inputValue != "" ? "0.5rem" : "1rem",
              padding:
                selected || focusInput || inputValue != ""
                  ? "0 0.5rem"
                  : "0 0rem",
              backgroundColor:
                selected || focusInput || inputValue != ""
                  ? "rgb(255,255,255)"
                  : "transparent",
              fontSize:
                selected || focusInput || inputValue != "" ? "0.75rem" : "1rem",
              top: selected || focusInput || inputValue != "" ? 0 : "50%",
              transform: "translateY(-50%)",
              transition: "all 0.3s ease,color 0s",
              pointerEvents: "none",
              color: inputValue == "" || !selected ? "#C22D74" : "#323232",
            }}
          >
            {props.label}
          </label>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              right: "16px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <path
              d="M13.333 9.77329V13.3333C13.333 13.6869 13.1925 14.0261 12.9425 14.2761C12.6924 14.5262 12.3533 14.6666 11.9997 14.6666H2.66634C2.31272 14.6666 1.97358 14.5262 1.72353 14.2761C1.47348 14.0261 1.33301 13.6869 1.33301 13.3333V3.99996C1.33301 3.64634 1.47348 3.3072 1.72353 3.05715C1.97358 2.8071 2.31272 2.66663 2.66634 2.66663H6.22634"
              stroke="#323232"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.9997 1.33337L14.6663 4.00004L7.99967 10.6667H5.33301V8.00004L11.9997 1.33337Z"
              stroke="#323232"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        {inputValue == "" || isInvalid ? (
          <p
            style={{
              position: "absolute",
              top: "100%",
              color: "#C22D74",
              margin: 0,
              padding: 0,
              fontSize: "0.75rem",
            }}
          >
            {isInvalid ? (
              <FormattedMessage id="Address is invalid, please select an address from the dropdown." defaultMessage="Address is invalid, please select an address from the dropdown." />
            ) : (
              <FormattedMessage id="Please fill in your address." defaultMessage="Please fill in your address." />
            )}
          </p>
        ) : null}

        {focusInput ? (
          <div
            style={{
              ...styles.geoSuggestHint,
              zIndex: 2,
              position: "relative",
            }}
          >
            <div
              style={{
                left: 0,
                right: 0,
                paddingTop: suggestCount > 0 ? 10 : 0,
                position: "absolute",
                top:
                  suggestCount > 0
                    ? `${suggestCount * SUGGEST_ITEM_HEIGHT + 10}px`
                    : 0,
                boxShadow: "0px 13px 19px -7px rgba(0,0,0,0.28)",
              }}
            >
              <div
                style={{
                  borderTop: "1px solid #ECECEC",
                  width: "100%",
                  backgroundColor: "#fff",
                  padding: "16px 24px",
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <p style={styles.text}>{DEFAULT_TITLE}</p>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        style={styles.modal}
        onRequestClose={() => {
          setShowModal(false);
          geosuggestEl.current.focus();
        }}
        shouldCloseOnOverlayClick={true}
        isOpen={showModal}
      >
        <p style={styles.modalTitle}>
          <FormattedMessage id="Sorry, we don’t deliver outside of peninsular Malaysia yet" defaultMessage="Sorry, we don’t deliver outside of peninsular Malaysia yet" />
        </p>
        <p style={styles.modalText}>
          <FormattedMessage id="We currently deliver in peninsular Malaysia from same-day delivery to a delivery time of 4 days, depending on item availability." defaultMessage="We currently deliver in peninsular Malaysia from same-day delivery to a delivery time of 4 days, depending on item availability." />
        </p>
      </Modal>
    </>
  );
};

const styles = {
  overlay: {
    display: "block",
    position: "fixed",
    zIndex: 1,
    width: "100%",
    height: "110vh",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  modalTitle: {
    fontFamily: "Helvetica",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    background: "linear-gradient(180deg, #79DDC4 0%, #6AD08A 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  modalText: {
    marginTop: 30,
    fontFamily: "Helvetica",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#323232",
  },
  modal: {
    overlay: {
      zIndex: 5,
      backgroundColor: "rgba(0,0,0,0.3)",
    },
    content: {
      overflow: "hidden",
      padding: 30,
      height: isMobile ? 230 : 200,
      borderRadius: 10,
      inset: isMobile ? "100px 5%" : "100px 20%",
    },
  },

  geoInput: {
    height: "50.73px",
    padding: "0.8rem 1rem",
    width: "100%",
    fontSize: "1rem",
    outline: "none",
    fontSize: "16px",
    color: "#323232",
    borderRadius: "12px",
    transition: "all 0.3s ease",
  },
  geoSuggest: {
    zIndex: 2,
    position: "absolute",
    backgroundColor: "rgba(255,255,255,1)",
    padding: "16px 24px",
    width: "100%",
    fontSize: "1rem",
    borderColor: "rgba(202,202,202,0.7)",
    borderWidth: "2px",
    boxShadow: "0px -4px 19px -7px rgba(0,0,0,0.28)",
    margin: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1.87rem",
    width: "100%",
    position: "relative",
  },

  geoSuggestHint: {
    boxShadow: "0px 6px 14px -1px rgba(0,0,0,0.28)",
    width: "100%",
    fontSize: "1rem",
  },
  itemText: {
    fontFamily: "Helvetica",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0px",
    textAlign: "left",
    margin: 0,
    padding: 0,
  },
};

export default SearchLocation;

/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import moment from "moment";
import dynamic from "next/dynamic";
import { FormattedMessage, useIntl } from "react-intl";
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import { useAccountUpdate } from '@saleor/sdk/lib/react/mutations'

import * as S from "./styles";
import { getSaleorApi } from "@utils/ssr";
import { commonMessages } from "@temp/intl";
import { Attribute, Tile } from "@components/atoms";
import { editBrazeUserAttributes } from "@temp/components/Braze";
import { getUserInfo } from "../../../../components/Braze/queries";

const AccountUpdateForm = dynamic(() => import("./AccountUpdateForm").then((mod) => mod.AccountUpdateForm));
const IconButton = dynamic(() => import("@components/atoms").then((mod) => mod.IconButton));

export const AccountTile: React.FC = () => {
  const intl = useIntl();
  const { user } = useAuth();
  const [userDob, setUserDob] = React.useState('');
  const [userGender, setUserGender] = React.useState('');
  const [emptyGender, setEmptyGender] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState(false);
  const [setAccountUpdate, { data, error }] = useAccountUpdate();
  const options = [
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
    { value: 'P', label: 'Prefer Not To Say' },
  ];

  const getUser = async () => {
    const { apolloClient } = await getSaleorApi();
    const { data } = await apolloClient.query({
      query: getUserInfo,
      fetchPolicy: 'no-cache',
    });

    if (data?.me.dob) {
      const formattedDate = moment(data?.me.dob).format('DD MMMM YYYY');  
      setUserDob(formattedDate);
    }
    if (data?.me.gender) {
      const selectedOption = options.find(option => option.value === data?.me.gender);
      setUserGender(selectedOption.label);
      setEmptyGender(false)
    }
    editBrazeUserAttributes(data?.me)
  }

  React.useEffect(() => {
    getUser();
    if (data && !error) {
      setIsEditing(false);
    }
  }, [data, error]);

  return (
    <S.TileWrapper>
      <Tile>
        <S.Wrapper>
          <S.Header>
            <FormattedMessage id="MY DATA" defaultMessage="MY DATA" />
          </S.Header>
          <S.Content>
            <S.HeaderSmall>
              <FormattedMessage id="Personal details" defaultMessage="Personal details" />
              {!isEditing && (
                <IconButton
                  testingContext="editDetailsButton"
                  name="edit"
                  size={22}
                  onClick={() => setIsEditing(isEditing => !isEditing)}
                />
              )}
            </S.HeaderSmall>
            {isEditing ? (
              <AccountUpdateForm
                initialValues={{
                  firstName: (user && user.firstName) || "",
                  lastName: (user && user.lastName) || "",
                  dob: userDob ?? "",
                  gender: userGender,
                  emptyGender: emptyGender,
                }}
                handleSubmit={data => {
                  setAccountUpdate({ input: data });
                }}
                hide={() => {
                  setIsEditing(false);
                }}
              />
            ) : (
              <>
                <S.ContentOneLine data-test="personalDetailsSection">
                  <Attribute
                    description={intl.formatMessage(commonMessages.firstName)}
                    attributeValue={(user && user.firstName) || "-"}
                    testingContext="firstNameText"
                  />
                  <Attribute
                    description={intl.formatMessage(commonMessages.lastName)}
                    attributeValue={(user && user.lastName) || "-"}
                    testingContext="lastNameText"
                  />
                  <Attribute
                    description={intl.formatMessage(commonMessages.shortEmail)}
                    attributeValue={(user && user.email) || "-"}
                    testingContext="emailText"
                  />
                </S.ContentOneLine>
                <S.ContentOneLine style={{ paddingTop: '50px' }}>
                  {
                    userDob ?
                    <Attribute
                      description={intl.formatMessage(commonMessages.dob)}
                      attributeValue={userDob}
                      testingContext="dobText"
                    />
                    :
                    <>
                      <div>
                        <S.Description>Date of birth</S.Description>
                        <div data-test="dobText" style={{ fontStyle: 'italic' }}>Enter your date of birth for special offers on your birthday.</div>
                      </div>
                    </>
                  }
                  <Attribute
                    description={intl.formatMessage(commonMessages.gender)}
                    attributeValue={userGender || "-"}
                    testingContext="genderText"
                  />
                </S.ContentOneLine>
              </>
            )}
          </S.Content>
        </S.Wrapper>
      </Tile>
    </S.TileWrapper>
  );
};

import { styled } from "@styles";

export const AddToCartSelection = styled.div``;

export const CartActionWrapper = styled.div.attrs((/* props */) => ({}))`
  &.mobile__view{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 5px;
    max-width: 267px;

     & > div{
      padding-top: 0px;
      margin-top: 0px;
    }

    & > button{
      margin-top: 0.5rem;
      max-width: 72px;
    }
  }
`;
export const ProductNameHeader = styled.h1`
  text-transform: uppercase;
  font-weight: ${props => props.theme.typography.boldFontWeight};
  margin-bottom: ${props => props.theme.spacing.spacer};
  font-size: 1.5rem;
  line-height: 1.7rem;
`;

export const ProductPricing = styled.h4`
  font-weight: ${props => props.theme.typography.boldFontWeight};
  margin-bottom: ${props => props.theme.spacing.spacer};
`;

export const UndiscountedPrice = styled.span`
  text-decoration: line-through;
  color: ${props => props.theme.colors.baseFontColorSemiTransparent};
  font-size: ${props => props.theme.typography.smallFontSize};
`;

export const VariantPicker = styled.div`
  display: grid;
  margin-top: 20px;

  .react-select-wrapper,
  .input {
    width: 50%;
    margin-bottom: 1rem;
  }
`;

export const QuantityInput = styled.div`
  margin-top: 20px;
  padding-top: 20px;
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.error};
`;

export const BuyNowButton = styled.p`
  margin-top: 20px;
  font-weight: 700;
  font-size: 18px;
  &.mobile__view{
    max-width: 267px;
  }
`;
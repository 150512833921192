exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".products-featured{padding:10px 0 4rem}.products-featured .container{margin-top:0px !important}.products-featured .title-row{display:flex;justify-content:space-between;padding-right:20px;margin-bottom:20px}.products-featured .title-row h3{font-weight:600;margin-bottom:2rem;text-transform:uppercase;margin-bottom:0px}.products-featured .title-row a{font-size:16px;margin-bottom:0px}.products-featured a{color:#323232;display:inline-block;max-width:255px;text-decoration:none}", "", {"version":3,"sources":["/vercel/path0/src/components/ProductsFeatured/scss/ProductsFeatured.module.scss"],"names":[],"mappings":"AAA6C,mBAAmB,mBAAmB,CAAC,8BAA8B,yBAAyB,CAAC,8BAA8B,aAAa,8BAA8B,mBAAmB,kBAAkB,CAAC,iCAAiC,gBAAgB,mBAAmB,yBAAyB,iBAAiB,CAAC,gCAAgC,eAAe,iBAAiB,CAAC,qBAAqB,cAAc,qBAAqB,gBAAgB,oBAAoB,CAAC","file":"ProductsFeatured.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.products-featured{padding:10px 0 4rem}.products-featured .container{margin-top:0px !important}.products-featured .title-row{display:flex;justify-content:space-between;padding-right:20px;margin-bottom:20px}.products-featured .title-row h3{font-weight:600;margin-bottom:2rem;text-transform:uppercase;margin-bottom:0px}.products-featured .title-row a{font-size:16px;margin-bottom:0px}.products-featured a{color:#323232;display:inline-block;max-width:255px;text-decoration:none}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import React, { useEffect } from "react";
import { generatePath } from "react-router";
import { Loader } from "@components/atoms";
// import ProductListItem from "../../../../components/ProductListItem";
import { paths } from "@paths";
import { isMobile } from "react-device-detect";

import * as S from "./styles";
import { IProps } from "./types";
import dynamic from "next/dynamic";

const ProductListItem = dynamic(() => import("../../../../components/ProductListItem"));

function gridColumnVal(productLength: number, index:number): string {
  if ((index > 0 && productLength > 1) || isMobile) {
    return "span 2";
  }
  switch (productLength) {
    case 1:
      return "4 / span 2";
    case 2:
      return "3 / span 2";
    case 3:
      return "2 / span 2";
    default:
      return "span 2";
  }
}

export const ProductList: React.FC<IProps> = ({
  products,
  canLoadMore = false,
  loading = false,
  testingContextId,
  onLoadMore = () => null,
}) => {

  useEffect(() => {
    const scrollDown = () => {
      if (window.pageYOffset % 50 >= 45) {
        if (canLoadMore && !loading) {
          onLoadMore()
        }
      }
    }
    window.addEventListener("scroll", scrollDown);
    return () => {
      window.removeEventListener("scroll", scrollDown);
    };
  }, [canLoadMore, loading]); // eslint-disable-line react-hooks/exhaustive-deps
  
  return (
    <>
      <S.List data-test="productList" data-test-id={testingContextId}>
        {products.map((product, index) => {
          const { slug, name } = product;
          return (
            slug &&
            name && (
              <ProductListItem style={{ gridColumn: gridColumnVal(products.length, index) }} key={slug} href={generatePath(paths.product, { slug })} product={product} />
            )
          );
        })}
      </S.List>
      <S.Loader>
        {loading ? (
          <Loader />
        ) :  null
        // (
        //   canLoadMore && (
        //     <Button
        //       testingContext="loadMoreProductsButton"
        //       color="secondary"
        //       onClick={onLoadMore}
        //     >
        //       <FormattedMessage defaultMessage="More +" />
        //     </Button>
        //   )
        // )
        }
      </S.Loader>
    </>
  );
};

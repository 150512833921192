import { Icon } from '@iconify/react';
import { Checkbox, Popover } from 'antd';
import { FormattedMessage } from "react-intl";
import React, { useContext, useEffect, useState } from 'react';

import './chilledDelivery.module.scss';
import { DeliverySlotContext } from '../../contexts/deliverySlotsContext';

export const ChilledDelivery = ({ popoverPosition = 'bottom', id }) => {
  
  const [ popoverOpen, setPopoverOpen ] = useState(false);
  const { chilledDelivery, setChilledDelivery } = useContext(DeliverySlotContext);

  const popoverContent = (
    <div className='content'>
      <div>
        <Icon icon="ant-design:question-circle-outlined" style={{
          color: '#4BE0C3', 
          fontSize: '16px',
          marginLeft: '4px',
          transition: 'fill .3s',
          verticalAlign: '-0.170em',
          textAlign: 'center',
        }}/>
        <h5>
          <FormattedMessage id="Which products are chilled?" defaultMessage="Which products are chilled?" />
        </h5>
      </div>
      <p>
        <FormattedMessage id="The chilled option is only applicable for beer and white wine only. Other products in the order will be delivered at room temperature." defaultMessage="The chilled option is only applicable for beer and white wine only. Other products in the order will be delivered at room temperature." />
      </p>
    </div>
  );

  return (
    <div className={`chilled-delivery ${id}`} id={id}>
      <Checkbox onChange={e => setChilledDelivery(e.target.checked)} checked={chilledDelivery}>
        <FormattedMessage id="I want my drinks to be delivered chilled" defaultMessage="I want my drinks to be delivered chilled" /> <span className="icon"> ❄️</span>.
      </Checkbox>
      <Popover 
        trigger="click" 
        content={popoverContent}
        overlayClassName={`chilled-delivery-info ${popoverPosition}`}
        onVisibleChange={visible => setPopoverOpen(visible)}
        placement={popoverPosition}
        getPopupContainer={() => document.getElementById(id)}
      >
        <Icon icon="ant-design:question-circle-outlined" style={{
          color: popoverOpen ? '#999999' : '#4BE0C3', 
          fontSize: '16px',
          marginLeft: '4px',
          transition: 'fill .3s',
          verticalAlign: '-0.170em',
          textAlign: 'center',
        }}/>
      </Popover>
    </div>
  );
}
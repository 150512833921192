import { Form, Field } from "react-final-form";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Chip, ErrorMessage, Input } from "@components/atoms";
import { commonMessages } from "@temp/intl";

import * as S from "./styles";
import { IProps } from "./types";

export const DiscountForm: React.FC<IProps> = ({
  handleSubmit,
  discount,
  errors,
  formId,
  formRef,
  removeVoucherCode,
}: IProps) => {
  const promoCode = discount && discount.promoCode;

  const [inputCode, setInputCode] = React.useState("");
  const [tempPromoCode, setTempPromoCode] = React.useState(promoCode);
  const intl = useIntl();

  const handleApplyBtnClick = async (newInputCode: string) => {
    let isValid = await handleSubmit({
        promoCode: newInputCode,
      })
    // @ts-ignore
    if (isValid) {
      setTempPromoCode(newInputCode);
      setInputCode("");
    }
  };

  const handleRemoveBtnClick = (newInputCode: string) => {
    setTempPromoCode(undefined);
    setInputCode(newInputCode);
    removeVoucherCode(newInputCode)
  };

  return (
    <Form
      initialValues={{
        errors,
        inputCode,
        tempPromoCode,
      }}
      keepDirtyOnReinitialize
      onSubmit={(values) => {
        return new Promise<void>(resolve => {
          if (handleSubmit) {
            handleSubmit({
              promoCode: values.tempPromoCode,
            });
          }
          resolve();
        });
      }}
      render = {({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        handleSubmit,
        values,
      }) => {
        const hasErrors = !!(values.errors && values.errors.length);

        return (
          <S.DiscountForm
            id={formId}
            ref={formRef}
            onSubmit={handleSubmit}
            data-test="discountForm"
          >
            <S.Input>
              <S.InputWithButton>
                <S.InputWrapper>
                  <Field name="inputCode">
                    {({ input, meta }) => (
                      <Input
                        error={hasErrors}
                        value={values.inputCode}
                        label={intl.formatMessage(commonMessages.promoCode)}
                        { ...input }
                        onChange={(e) => {
                          input.onChange(e);
                        }}
                      />
                    )}
                  </Field>
                  
                </S.InputWrapper>
                <S.ButtonWrapper>
                  <Button
                    type="button"
                    testingContext="applyPromoCodeButton"
                    onClick={() => handleApplyBtnClick(values.inputCode)}
                    color="gradient"
                  >
                    <FormattedMessage id="Apply" defaultMessage="Apply" />
                  </Button>
                </S.ButtonWrapper>
              </S.InputWithButton>
              <ErrorMessage errors={values.errors} />
            </S.Input>
            {values.tempPromoCode && (
              <>
                <span>
                  <FormattedMessage id="Promo code" {...commonMessages.promoCode} />:
                </span>
                <S.ChipsWrapper>
                  <Chip onClose={() => handleRemoveBtnClick(values.tempPromoCode)}>
                    <span data-test="promoCodeChip">
                      {values.tempPromoCode}
                    </span>
                  </Chip>
                </S.ChipsWrapper>
              </>
            )}
          </S.DiscountForm>
        );
      }}
    />
  );
};

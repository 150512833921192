/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
import axios from 'axios';
import Link from 'next/link';
import * as React from "react";
import Image from 'next/future/image';
import { Icon } from '@iconify/react';
import { useRouter } from 'next/router';
import AppleLogin from 'react-apple-login';
import GoogleLogin from 'react-google-login';
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import { useIntl, FormattedMessage } from "react-intl";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { useLocalStorage } from "@hooks";
import { getSaleorApi } from "@utils/ssr";
import { demoMode } from "@temp/constants";
import { commonMessages } from "@temp/intl";
import { Button, Form, TextField } from "..";
import { createToken, havePasswordQuery, getUserDetail } from "../OverlayManager/Login/queries";

import "./scss/index.module.scss";
import "./scss/social-buttons.module.scss";

export const tokenLogin = async (accessToken, backend, did, didh) => {
  const { apolloClient, api } = await getSaleorApi();
  const channel = "default-channel";
  const { data } = await apolloClient.mutate({
    mutation: createToken,
    variables: { 
      accessToken: accessToken, 
      backend: backend, 
      xDID: did, 
      xDIDH: didh, 
      channel: channel },
  });
  const token = data.oauthTokenCreate.token || data.oauthTokenCreate._token?.accessToken
  api.auth.jobsManager.localStorageHandler.setSignInToken(token)
}

const sendToDotdigital = async (data, provider) => {
  let email;
  let lastname; 
  let firstname; 
  if (provider === 'google-oauth2') {
    firstname = data.Ju?.hY
    lastname = data.Ju?.vW
    email = lastname = data.Ju?.zv // eslint-disable-line no-multi-assign
    let result = await axios.post('/api/sendLoginInfo', { firstname, lastname, email })
  }
}

const LoginForm = ({ hide, resetPassword }) => {
  const { signIn, user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [showReset, setShowReset] = React.useState(false)
  const [deviceID, setDeviceID] = React.useState(null);

  React.useEffect(() => {
    const DeviceUUID = require("device-uuid");
    setDeviceID(new DeviceUUID.DeviceUUID().get());
  }, []);

  const defaultAddress = useLocalStorage("default-address", "");

  const router = useRouter()

  const handleSocialLogin = async (data, backend) => {
    const result = await axios.post("/api/getDeviceId", { deviceID });

    const { did, didh } = result.data

    await tokenLogin(data.accessToken, backend, did, didh)
    if (!localStorage.getItem("deliveryLocation") || JSON.parse(localStorage.getItem("deliveryLocation")) == "") {
      defaultAddress.setValue("check");
    }
    localStorage.setItem("userLoginCheck", data.accessToken)
    router.reload()
  }

  
  const checkIfHasPassword = async (email) => {
    const { apolloClient } = await getSaleorApi();
    const { data } = await apolloClient.query({
      query: havePasswordQuery,
      variables: { email },
    });
    return data.havePassword
  }

  const handleOnSubmit = async (evt, { email, password }) => {
    evt.preventDefault();
    setLoading(true);

    const hasPassword = await checkIfHasPassword(email)
    if (hasPassword === 'NO') {
      setShowReset(true)
      return setLoading(false);
    }

    const { data, dataError } = await signIn(email, password);
    if (dataError?.error) {
      setErrors(dataError.error);
    } else if (data && hide) {
      if (!localStorage.getItem("deliveryLocation") || JSON.parse(localStorage.getItem("deliveryLocation")) === "") {      
       if (Object.keys(data).indexOf("defaultShippingAddress") !== -1) {
        if (data.defaultShippingAddress) {
          defaultAddress.setValue(Object.keys(data.defaultShippingAddress).length > 0 ? data.defaultShippingAddress : null);
          document.dispatchEvent(new Event("user-logged-in"));
        }
      }
     }
      document.dispatchEvent(new Event("new-user-logged-in"));
      setErrors(null);
      hide();
    }
  };

  const formData = demoMode
    ? {
        email: "admin@example.com",
        password: "admin",
      }
    : {};

  const intl = useIntl();

  const resetWarning = (
    <div className="warning rounded-16">
      <Icon icon="ci:info" style={{
          color: 'grey', 
          fontSize: '126px',
          textAlign: 'center',
        }}/>
      <div>
        <p>Dear customer, we have recently updated our website. We would kindly ask you to reset your password to be able to access your account.</p>
        <a onClick={resetPassword}><p>Click here to reset your password.</p></a>
      </div>
    </div>
  )

  return (
    <span className="login-form">
      <Form data={formData} errors={errors || []} onSubmit={handleOnSubmit}>
        <TextField
          name="email"
          autoComplete="email"
          label={intl.formatMessage(commonMessages.eMail)}
          type="email"
          required
        />
        <TextField
          name="password"
          autoComplete="password"
          label={intl.formatMessage(commonMessages.password)}
          type="password"
          required
        />
        {showReset && resetWarning}
        <div className="login-form__button">
          <Button
            testingContext="submit"
            type="submit"

            {...(loading && { disabled: true })}
          >
            {loading
              ? intl.formatMessage(commonMessages.loading)
              : intl.formatMessage({ id: "Sign in", defaultMessage: "Sign in" })}
          </Button>
        </div>
      </Form>
      <div className="social-login">
        <FacebookLogin
          appId={process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}
          callback={data => handleSocialLogin(data, 'facebook')}
          render={(renderProps) => (
            <button class="btn-facebook" type="button" onClick={renderProps.onClick}>
              <div class="facebook-content">
                <div class="logo">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 90 90">
                    <path fill="#FFF" d="M90 15.001C90 7.119 82.884 0 75 0H15C7.116 0 0 7.119 0 15.001v59.998C0 82.881 7.116 90 15.001 90H45V56H34V41h11v-5.844C45 25.077 52.568 16 61.875 16H74v15H61.875C60.548 31 59 32.611 59 35.024V41h15v15H59v34h16c7.884 0 15-7.119 15-15.001V15.001z"/>
                  </svg>
                </div>
                <div class="spacer"></div>
                <div>
                  <FormattedMessage id="Log in with Facebook" defaultMessage="Log in with Facebook" />
                </div>
              </div>
            </button>
          )}
        />
        <GoogleLogin
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENTID}
          onSuccess={data => handleSocialLogin(data, 'google-oauth2')}
          render={(renderProps) => (
            <button class="btn-google" type="button" onClick={renderProps.onClick}>
                <div class="google-content">
                  <div class="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="26px" height="26px">
                      <linearGradient id="95yY7w43Oj6n2vH63j6HJb" x1="29.401" x2="29.401" y1="4.064" y2="106.734" gradientTransform="matrix(1 0 0 -1 0 66)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#ff5840"></stop>
                        <stop offset=".007" stop-color="#ff5840"></stop>
                        <stop offset=".989" stop-color="#fa528c"></stop>
                        <stop offset="1" stop-color="#fa528c"></stop>
                      </linearGradient>
                      <path fill="url(#95yY7w43Oj6n2vH63j6HJb)" d="M47.46,15.5l-1.37,1.48c-1.34,1.44-3.5,1.67-5.15,0.6c-2.71-1.75-6.43-3.13-11-2.37	c-4.94,0.83-9.17,3.85-11.64, 7.97l-8.03-6.08C14.99,9.82,23.2,5,32.5,5c5,0,9.94,1.56,14.27,4.46	C48.81,10.83,49.13,13.71,47.46,15.5z"></path>
                      <linearGradient id="95yY7w43Oj6n2vH63j6HJc" x1="12.148" x2="12.148" y1=".872" y2="47.812" gradientTransform="matrix(1 0 0 -1 0 66)" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="#feaa53"></stop>
                        <stop offset=".612" stop-color="#ffcd49"></stop>
                        <stop offset="1" stop-color="#ffde44"></stop>
                      </linearGradient>
                      <path fill="url(#95yY7w43Oj6n2vH63j6HJc)" d="M16.01,30.91c-0.09,2.47,0.37,4.83,1.27,6.96l-8.21,6.05c-1.35-2.51-2.3-5.28-2.75-8.22	c-1.06-6.88,0.54-13.38, 3.95-18.6l8.03,6.08C16.93,25.47,16.1,28.11,16.01,30.91z"></path><linearGradient id="95yY7w43Oj6n2vH63j6HJd" x1="29.76" x2="29.76" y1="32.149" y2="-6.939" gradientTransform="matrix(1 0 0 -1 0 66)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#42d778"></stop><stop offset=".428" stop-color="#3dca76"></stop><stop offset="1" stop-color="#34b171"></stop></linearGradient><path fill="url(#95yY7w43Oj6n2vH63j6HJd)" d="M50.45,51.28c-4.55,4.07-10.61,6.57-17.36,6.71C22.91,58.2,13.66,52.53,9.07,43.92l8.21-6.05	C19.78,43.81, 25.67,48,32.5,48c3.94,0,7.52-1.28,10.33-3.44L50.45,51.28z"></path><linearGradient id="95yY7w43Oj6n2vH63j6HJe" x1="46" x2="46" y1="3.638" y2="35.593" gradientTransform="matrix(1 0 0 -1 0 66)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#155cde"></stop><stop offset=".278" stop-color="#1f7fe5"></stop><stop offset=".569" stop-color="#279ceb"></stop><stop offset=".82" stop-color="#2cafef"></stop><stop offset="1" stop-color="#2eb5f0"></stop></linearGradient><path fill="url(#95yY7w43Oj6n2vH63j6HJe)" d="M59,31.97c0.01,7.73-3.26,14.58-8.55,19.31l-7.62-6.72c2.1-1.61,3.77-3.71,4.84-6.15 c0.29-0.66-0.2-1.41-0.92-1.41H37c-2.21,0-4-1.79-4-4v-2c0-2.21,1.79-4,4-4h17C56.75,27,59,29.22,59,31.97z"></path>
                    </svg>
                  </div>
                  <div class="spacer"></div>
                  <div class="button-text">
                    <FormattedMessage id="Log in with Google" defaultMessage="Log in with Google" />
                  </div>
                </div>
            </button>
          )}
        />
        <AppleLogin
          clientId={process.env.NEXT_PUBLIC_APPLE_CLIENTID}
          redirectURI={process.env.NEXT_PUBLIC_REDIRECT_URL}
          onSuccess={data => handleSocialLogin(data, 'apple-id')}
          responseMode="fragment"
          responseType="code id_token"
          render={(renderProps) => (
            <button class="btn-apple" type="button" onClick={renderProps.onClick}>
                <div class="apple-content">
                  <div class="logo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="26px" viewBox="0 0 56 56">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M28.2226562,20.3846154 C29.0546875,20.3846154 30.0976562,19.8048315 30.71875,19.0317864 C31.28125,18.3312142 31.6914062,17.352829 31.6914062,16.3744437 C31.6914062,16.2415766 31.6796875,16.1087095 31.65625,16 C30.7304687,16.0362365 29.6171875,16.640178 28.9492187,17.4494596 C28.421875,18.06548 27.9414062,19.0317864 27.9414062,20.0222505 C27.9414062,20.1671964 27.9648438,20.3121424 27.9765625,20.3604577 C28.0351562,20.3725366 28.1289062,20.3846154 28.2226562,20.3846154 Z M25.2929688,35 C26.4296875,35 26.9335938,34.214876 28.3515625,34.214876 C29.7929688,34.214876 30.109375,34.9758423 31.375,34.9758423 C32.6171875,34.9758423 33.4492188,33.792117 34.234375,32.6325493 C35.1132812,31.3038779 35.4765625,29.9993643 35.5,29.9389701 C35.4179688,29.9148125 33.0390625,28.9122695 33.0390625,26.0979021 C33.0390625,23.6579784 34.9140625,22.5588048 35.0195312,22.474253 C33.7773438,20.6382708 31.890625,20.5899555 31.375,20.5899555 C29.9804688,20.5899555 28.84375,21.4596313 28.1289062,21.4596313 C27.3554688,21.4596313 26.3359375,20.6382708 25.1289062,20.6382708 C22.8320312,20.6382708 20.5,22.5950413 20.5,26.2911634 C20.5,28.5861411 21.3671875,31.013986 22.4335938,32.5842339 C23.3476562,33.9129053 24.1445312,35 25.2929688,35 Z" fill="#000000" fill-rule="nonzero"></path>
                      </g>
                    </svg>
                  </div>
                  <div class="spacer"></div>
                  <div class="button-text">
                    <FormattedMessage id="Log in with Apple" defaultMessage="Log in with Apple" />
                  </div>
                </div>
            </button>
          )}
        />
      </div>
    </span>
  );
};

export default LoginForm;

import { useRouter } from 'next/router';
import NextApp from "next/app";
import Head from "next/head";
import * as React from "react";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { WithUserAgentProps, withUserAgent } from 'next-useragent';

import type {
  AppContext as NextAppContext,
  AppProps as NextAppProps,
} from "next/app";
import { LocaleMessages } from "../components/Locale";
import { getShopConfig, ShopConfig } from "@utils/ssr";
import packageInfo from "../../package.json";
import {
  apiUrl,
  sentryDsn,
  serviceWorkerTimeout,
  ssrMode,
} from "../constants";
import { 
  attachClient, 
  GTMPageView, 
  sentryInit, 
  generateTitle, 
} from "./_appConfig";
import AppBody from './_appBody';
import "../globalStyles/scss/index.scss";

declare global {
  interface Window {
    __APOLLO_CLIENT__: any;
  }
}

if (!ssrMode) {
  window.version = packageInfo.version;
  if (process.env.NEXT_PUBLIC_ENABLE_APOLLO_DEVTOOLS === "true") attachClient();
}

if (sentryDsn) {
  sentryInit();
}

type AppProps = NextAppProps & ShopConfig & { messages: LocaleMessages } & WithUserAgentProps

const App = ({
  Component,
  pageProps,
  footer,
  mainMenu,
  messages,
  shopConfig,
  ua,
}: AppProps) => {
  const router = useRouter();
  const [ smsUnsubscribe, setSmsUnsubscribe ] = React.useState(null);

  React.useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (router.query && router.query?.sms_unsubscribe) {
      setSmsUnsubscribe(router.query?.sms_unsubscribe)
    } else {
      setSmsUnsubscribe(null)
    }
}, [router.query]);

  return (
    <>
      <Head>
        <title>{generateTitle()}</title>
        <link rel="preconnect" href={apiUrl} />
        <link href="https://rsms.me/inter/inter.css" rel="stylesheet" />
        <link rel="icon" type="image/png" href="/favicon-36.png" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href={typeof window !== 'undefined' && window.location.href} />
      </Head>
      <GoogleReCaptchaProvider reCaptchaKey="6LfMRd4dAAAAACAvUV_FDOTw4_ePUQUuiGu30_Fu">
        <AppBody 
          serviceWorkerTimeout={serviceWorkerTimeout}
          ua={ua}
          footer={footer}
          mainMenu={mainMenu}
          shopConfig={shopConfig}
          Component={Component}
          pageProps={pageProps}
          smsUnsubscribe={smsUnsubscribe}
        />
      </GoogleReCaptchaProvider>
    </>
  )
};

// Fetch shop config only once and cache it.
let shopConfig: ShopConfig | null = null;

App.getInitialProps = async (appContext: NextAppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);

  if (!shopConfig) {
    shopConfig = await getShopConfig();
  }
  return { ...appProps, ...shopConfig };
};
// @ts-ignore
export default withUserAgent(App);

import { checkoutMessages } from "@temp/intl";
import React from "react";
import { FormattedMessage } from "react-intl";
import { DiscountForm } from "../DiscountForm";
import { IDiscountFormData } from "../DiscountForm/types";
import * as S from "./styles";
import { IProps } from "./types";



/**
 * Payment options used in checkout.
 */
const CheckoutPayment: React.FC<IProps> = ({
  promoCodeErrors,
  promoCodeDiscountFormId,
  promoCodeDiscountFormRef,
  promoCodeDiscount,
  addPromoCode,
  removeVoucherCode,
}) => {

  const handleSubmitPromoCode = async (discountForm?: IDiscountFormData) => {
    const newPromoCode = discountForm?.promoCode;
    const savedPromoCode = promoCodeDiscount?.voucherCode;

    if (newPromoCode && newPromoCode !== savedPromoCode) {
      let isValid = await addPromoCode(newPromoCode);
      // @ts-ignore
      return !!isValid
    }
  };

  return (
    <S.Wrapper>
      <section>
        <S.Title data-test="checkoutPageSubtitle">
          <FormattedMessage id="PAYMENT METHOD" {...checkoutMessages.paymentMethod} />
        </S.Title>
          <S.DiscountField>
            <DiscountForm
              discount={{ promoCode: promoCodeDiscount?.voucherCode }}
              formId={promoCodeDiscountFormId}
              formRef={promoCodeDiscountFormRef}
              handleSubmit={handleSubmitPromoCode}
              errors={promoCodeErrors}
              removeVoucherCode={removeVoucherCode}
            />
          </S.DiscountField>
        <S.Divider />
      </section>
    </S.Wrapper>
  );
};

export { CheckoutPayment };

exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".contact{max-width:600px;margin:0 auto;margin-top:60px;margin-bottom:80px}.contact .text{padding-left:20px}.contact .text h4{font-weight:700;font-size:24px;margin-bottom:12px}.contact .text p{font-size:16px;color:#323232;margin-bottom:12px}.contact .text .contact-item{display:flex;margin-bottom:10px;align-items:center;cursor:pointer}.contact .text .contact-item img{margin-right:20px}.contact .text .contact-item p{margin-bottom:0px}@media(max-width: 540px){.contact{margin-bottom:20px}.contact img{margin:0 auto;display:block;margin-bottom:20px}.contact .contact-item img{margin-left:0px;margin-bottom:0px}}", "", {"version":3,"sources":["/vercel/path0/src/components/Contact/scss/index.module.scss"],"names":[],"mappings":"AAA6C,SAAS,gBAAgB,cAAc,gBAAgB,kBAAkB,CAAC,eAAe,iBAAiB,CAAC,kBAAkB,gBAAgB,eAAe,kBAAkB,CAAC,iBAAiB,eAAe,cAAc,kBAAkB,CAAC,6BAA6B,aAAa,mBAAmB,mBAAmB,cAAc,CAAC,iCAAiC,iBAAiB,CAAC,+BAA+B,iBAAiB,CAAC,yBAAyB,SAAS,kBAAkB,CAAC,aAAa,cAAc,cAAc,kBAAkB,CAAC,2BAA2B,gBAAgB,iBAAiB,CAAC,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.contact{max-width:600px;margin:0 auto;margin-top:60px;margin-bottom:80px}.contact .text{padding-left:20px}.contact .text h4{font-weight:700;font-size:24px;margin-bottom:12px}.contact .text p{font-size:16px;color:#323232;margin-bottom:12px}.contact .text .contact-item{display:flex;margin-bottom:10px;align-items:center;cursor:pointer}.contact .text .contact-item img{margin-right:20px}.contact .text .contact-item p{margin-bottom:0px}@media(max-width: 540px){.contact{margin-bottom:20px}.contact img{margin:0 auto;display:block;margin-bottom:20px}.contact .contact-item img{margin-left:0px;margin-bottom:0px}}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import React from "react";
import { IntlProvider } from "react-intl";

import { getKeyValueJson, Locale, LocaleMessages } from "./utils";

const DEFAULT_LOCALE = Locale.EN;

interface LocaleProviderProps {
  messages: LocaleMessages;
  language?: any;
}

const LocaleProvider: React.FC<LocaleProviderProps> = ({
  children,
  messages,
  language,
}) => {

  // For now locale can be set here

  return (
    <IntlProvider
      defaultLocale={DEFAULT_LOCALE}
      locale={language}
      messages={getKeyValueJson(messages)}
      key={language}
    >
      {children}
    </IntlProvider>
  );
};

export { LocaleProvider };

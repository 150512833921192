import React from 'react';
import "./scss/index.module.scss";

interface SideBannerProps {
    id: string;
    collections: any[];
}

export const SideBanner : React.FC<SideBannerProps> = (props) => {
    // @ts-ignore
    const img = props.collections?.node?.backgroundImage.url
    
    return (
        <div id={props.id} className='side-banner' style={{ backgroundImage: `url(${img})` }}>
        </div>  
    )
}
// @ts-nocheck
/* eslint-disable */
import { DeliverySlotContext } from "../components/contexts";
import { useContext, useEffect, useState, useCallback } from "react";
import moment from "moment";

export const useEarliestDeliveryTime = (
  product: any,
  selectedVariant: any,
  callback?: (deliveryTime: any) => void
) => {
  const [earLiestDeliveryTime, setEarLiestDeliveryTime] = useState({
    text: "Enter an address to view",
    status: null,
    timeLoading: false,
    buttonLoading: false,
    buttonText: "Add",
    isDraught: false,
    isAvailable: true,
  });

  const { postcode, draughtTypeID, getDeliverySlots } = useContext(
    DeliverySlotContext
  );
  const isDraught = product.productType?.id === draughtTypeID;

  const mockCart = (prod, selectedVariant, qty = 1) => [
    {
      quantity: qty,
      variant: {
        id: selectedVariant?.id,
        product: {
          id: prod.id,
          name: prod.name
        },
      },
    },
  ];

  const dateToFromNowDaily = days => {
    const date = moment().add(days, "days");
    const fromNow = date.fromNow();

    return moment(date).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "dddd",
      sameElse: () => {
        return "[" + fromNow + "]";
      },
    });
  };

  const getDeliveryTime = useCallback(
    lines =>
      new Promise(async (resolve, reject) => {
        try {
          resolve(
            await getDeliverySlots(
              {
                lines,
                isCheckingAvailability: false,
                isCheckingTimeslot: true,
                isReturnValue: true,
              },
              false
            )
          );
        } catch (error) {
          reject(error);
        }
      }),
    [selectedVariant, postcode]
  );

  useEffect(() => {
    if (postcode && product && selectedVariant) {
      setEarLiestDeliveryTime(prevDeliveryTime => {
        return { ...prevDeliveryTime, timeLoading: true, buttonLoading: true };
      });
      const lines = mockCart(product, selectedVariant);
      getDeliveryTime(lines)
        .then(
          ({
            warehouse_name,
            delivery_date_from_now,
            type,
            slots,
            warehouse_status,
          }) => {
            updateSlots({
              warehouse_name,
              delivery_date_from_now,
              type,
              slots,
              warehouse_status,
            });
          }
        )
        .catch(error => {
          // if error happens set the product as unavailable
          const deliveryTime = {
            text: null,
            status: null,
            buttonText: "Unavailable",
            isDraught,
            isAvailable: false,
          };
          setEarLiestDeliveryTime(() => deliveryTime);
          console.log("error updating earliest delivery time", error);
        });
    }
    if (!postcode) {
      const deliveryTime = {
        text: "Enter an address to view",
        status: null,
        loading: false,
        buttonText: "Add",
        isDraught,
        isAvailable: true,
      };
      setEarLiestDeliveryTime(() => deliveryTime);
    }
  }, [postcode, product, selectedVariant]);

  const updateSlots = ({
    warehouse_name,
    delivery_date_from_now,
    type,
    slots,
    warehouse_status,
  }) => {
    let deliveryTime;
    if (!!warehouse_name) {
      deliveryTime = {
        text: "",
        status: "",
        buttonText: "Add",
        isDraught,
        isAvailable: true,
      };
      if (type === "standard") {
        deliveryTime.text = `Up to ${delivery_date_from_now} days`;
        deliveryTime.status = "#FF8F00";
      } else {
        let fromNow = dateToFromNowDaily(Number(delivery_date_from_now));
        let timeFromTo = `${moment(
          `${slots[0].value.split("-")[0]}:00`,
          "H:mm"
        ).format("h:mma")} - ${moment(
          `${slots[0].value.split("-")[1]}:00`,
          "H:mm"
        ).format("h:mma")}`;
        deliveryTime.text = `${fromNow} ${timeFromTo}`;
        deliveryTime.status = "#FF8F00";
        if (fromNow.toLocaleLowerCase() === "today") {
          deliveryTime.status = "#1FD778";
        }
      }
    } else {
      deliveryTime = {
        text: null,
        status: null,
        buttonText: warehouse_status,
        isDraught,
        isAvailable: false,
      };
    }
    setEarLiestDeliveryTime(() => {
      return { ...deliveryTime, timeLoading: false, buttonLoading: false };
    });
    if (callback) {
      callback(deliveryTime);
    }
  };
  const refetchDeliveryTime = async (
    product,
    variant,
    quantity,
    cartItem = false
  ) => {
    const lines = mockCart(product, variant, quantity);
    setEarLiestDeliveryTime(prevDeliveryTime => {
      return { ...prevDeliveryTime, buttonLoading: true };
    });
    const {
      warehouse_name,
      delivery_date_from_now,
      type,
      slots,
      warehouse_status,
    } = await getDeliverySlots(
      {
        lines,
        isCheckingAvailability: false,
        isCheckingTimeslot: true,
        isReturnValue: true,
      },
      false
    );
    updateSlots({
      warehouse_name,
      delivery_date_from_now,
      type,
      slots,
      warehouse_status,
    });

    return cartItem
      ? { status: !!warehouse_name, text: warehouse_status }
      : !!warehouse_name;
  };

  return { earLiestDeliveryTime, refetchDeliveryTime };
};

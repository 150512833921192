import React from "react";
import Image from "next/image";

import { IProps } from "./types";

// import NoPhoto from "images/no-photo.svg";

export const PlaceholderImage: React.FC<IProps> = ({
  alt = "placeholder",
}: IProps) => {
  // return <img src={NoPhoto} alt={alt} className="rounded-16"/>;
  return <Image 
    src='/no-photo.webp'
    className="rounded-16"
    height='350px' 
    width='240%'
    alt={alt}
  />
};
 
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".password-reset{width:25rem;max-width:100vw}.password-reset__content{padding:1rem}", "", {"version":3,"sources":["/vercel/path0/src/components/OverlayManager/Otp/scss/index.module.scss"],"names":[],"mappings":"AAA6C,gBAAgB,YAAY,eAAe,CAAC,yBAAyB,YAAY,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.password-reset{width:25rem;max-width:100vw}.password-reset__content{padding:1rem}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import Link from "next/link";
import Image from "next/image";
import * as React from "react";
import { Col, Row } from 'antd';
import { FormattedMessage } from "react-intl";

import "./scss/index.module.scss";
import { NavLink, SocialMediaIcon } from "..";
import appstore from '../../images/appstore.png';
import { SOCIAL_MEDIA } from "../../core/config";
import googleplay from '../../images/googleplay.png';

export const Footer = ({ menu }) => (
  <div className="footer" id="footer">
    <div className="footer__favicons container">
      {SOCIAL_MEDIA.map(medium => (
        <SocialMediaIcon medium={medium} key={medium.ariaLabel} />
      ))}
    </div>
    <div className='footer-nav'>
      <Row className="container">
        <Col xs={24} md={6}>
          <h4><FormattedMessage id="ENJOY RESPONSIBLY" defaultMessage="ENJOY RESPONSIBLY" /></h4>
          {sections.map(item => <Link key={item.url} href={item.url}><a><FormattedMessage id={item.name} defaultMessage={item.name} /></a></Link>)}
        </Col>
        <Col xs={24} md={10}>
          <h4><FormattedMessage id="POPULAR CATEGORIES" defaultMessage="POPULAR CATEGORIES" /></h4>
          <Row>
            <Col xs={12} md={6}>
              {menu?.items.slice(0, 4).map((item, index) => <NavLink key={index} item={item} />)}
            </Col>
            <Col xs={12} md={6}>
              {menu?.items.slice(4, 8).map((item, index) => <NavLink key={index} item={item} />)}
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={6}>
          <div>
            <h4><FormattedMessage id="INSTALL APP" defaultMessage="INSTALL APP" /></h4>
            <Link href='https://apps.apple.com/us/app/drinkies/id1503178309'>
              <a>
                <Image 
                  src={appstore}
                  alt="app store icon"
                  width='165px'
                  height='64px'
                />
              </a>
            </Link>
            <Link href='https://play.google.com/store/apps/details?id=my.drinkies&hl=en_US&gl=US'>
              <a>
                <Image 
                  src={googleplay}
                  alt="google play icon"
                  width='165px'
                  height='64px'
                />
              </a>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);


const sections = [
  { name: 'Frequently Asked Questions', url: '/help' },
  { name: 'How to order', url: '/how-to-order' },
  { name: 'Contact us', url: '/contact' },
  { name: 'Terms and conditions', url: '/legal' },
]
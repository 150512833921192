import moment from "moment";
import * as Sentry from "@sentry/browser";
import { Integrations as ApmIntegrations } from "@sentry/apm";
import { positions } from "react-alert";
import { ConfigInput } from "@saleor/sdk/lib/types";

import { apiUrl, channelSlug, sentryDsn, sentrySampleRate } from "../constants";

export const attachClient = async () => {
    const { apolloClient } = await (import('@utils/ssr').then((mod) => mod.getSaleorApi()));
    window.__APOLLO_CLIENT__ = apolloClient;
};

export const GTMPageView = (url: string) => {
    if (typeof window !== 'undefined') {
      interface PageEventProps {
          event: string;
          page: string;
      }
  
      const pageEvent: PageEventProps = {
          event: 'pageview',
          page: url,
      };
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.dataLayer && window.dataLayer.push(pageEvent);
      return pageEvent;
    }
};

export function sentryInit() {
    Sentry.init({
        dsn: sentryDsn,
        // @ts-ignore
        integrations: [new ApmIntegrations.Tracing()],
        tracesSampleRate: sentrySampleRate,
    });
}

export const isUnderMaintenance = () => {
    if (typeof window !== 'undefined' && window.location.hostname === 'store.drinkies.my.lizard.global') {
      return false;
    }
    let timestamp = moment().valueOf()
    let from = 1645524000000
    let to = 1645563600000
    return timestamp <  to && timestamp > from
}

export const generateTitle = () => {
    let title = 'Order Drinks Online in Malaysia | Drinkies'
    if (typeof window !== 'undefined') {
        if (window.location.pathname.trim() !== '/') {
            if (window.location.pathname.split('/').indexOf('product') === -1) {
              title += ` | ${window.location.pathname.split('/').filter(path => path !== '').join(' | ').replaceAll('-', ' ')}`
            } else {
              title += ` | ${window.document.title}`
            }
        }
    }
    return title
}

export async function showMaintenanceBanner() {
    const maintenance_img = await import('images/maintenance.png');
    return `<div className="maintenance"><img alt="under maintenance" src=${maintenance_img} /></div>`;
}

export const saleorConfig: ConfigInput = { apiUrl, channel: channelSlug };

export const notificationConfig = { position: positions.BOTTOM_RIGHT, timeout: 2500 };
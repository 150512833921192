import { Form, Field } from "react-final-form";
import React from "react";

import { Radio } from "@components/atoms";

import * as S from "./styles";
import { IProps } from "./types";

export const statuses = [
  { token: "charged", label: "Charged" },
  { token: "fully-refunded", label: "Fully refunded" },
  { token: "not-charged", label: "Not charged" },
];

/**
 * Dummy payment gateway.
 */
const DummyPaymentGateway: React.FC<IProps> = ({
  processPayment,
  formRef,
  formId,
  initialStatus,
}: IProps) => {
  return (
    <Form
      initialValues={{ status: initialStatus || statuses[0].token }}
      onSubmit={(values) => {
        return new Promise<void>(resolve => {
          processPayment(values.status)
          resolve()
        });
      }}
      render = {({
        handleSubmit,
        values,
        submitting,
        valid,
      }) => (
        <S.Form
          id={formId}
          ref={formRef}
          onSubmit={handleSubmit}
          data-test="dummyPaymentGatewayForm"
        >
          {statuses.map(({ token, label }) => {
            return (
              <S.Status key={token}>
                <Field name="status">
                  {({ input, meta }) => (
                    <Radio
                      key={token}
                      type="radio"
                      { ...input }
                      value={token}
                      checked={values.status === token}
                      onChange={(e) => {
                        input.onChange(e);
                      }}
                    >
                      <span>{label}</span>
                    </Radio>
                  )}
                </Field>
              </S.Status>
            );
          })}
        </S.Form>
      )}
    />
  );
};

export { DummyPaymentGateway };

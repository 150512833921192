import { ICheckoutModelLine } from "@saleor/sdk/lib/helpers";
import {
  ProductDetails_product_pricing,
  ProductDetails_product_variants,
  ProductDetails_product_variants_pricing,
} from "@saleor/sdk/lib/queries/gqlTypes/ProductDetails";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { commonMessages } from "@temp/intl";
import { IProductVariantsAttributesSelectedValues } from "@types";

import AddToCartButton from "../../molecules/AddToCartButton";
import QuantityInput from "../../molecules/QuantityInput";
import ProductVariantPicker from "../ProductVariantPicker";
import {
  // canAddToCart,
  // getAvailableQuantity,
  getProductPrice,
} from "./stockHelpers";
import * as S from "./styles";
import { useEarliestDeliveryTime } from "@hooks/useEarliestDeliveryTime";
import { SavedLocationContext } from '../../contexts';


// const LOW_STOCK_QUANTITY: number = 5;
import { isMobile } from "react-device-detect";
import { pushCheckout } from "@temp/components/Analytics";
import { useRouter } from "next/router";
import { useAuth } from "@saleor/sdk/lib/react/hooks";
import { paths } from "@paths";

export interface IAddToCartSection {
  productId: string;
  productVariants: ProductDetails_product_variants[];
  name: string;
  productPricing: ProductDetails_product_pricing;
  items: ICheckoutModelLine[];
  queryAttributes: Record<string, string>;
  isAvailableForPurchase: boolean | null;
  availableForPurchase: string | null;
  variantId: string;
  setVariantId(variantId: string): void;
  onAddToCart(variantId: string, quantity?: number, buyNowButton?: boolean): void;
  onAttributeChangeHandler(slug: string | null, value: string): void;
  locale?: string;
  product?: any;
}

const AddToCartSection: React.FC<IAddToCartSection> = ({
  availableForPurchase,
  isAvailableForPurchase,
  items,
  name,
  productPricing,
  productVariants,
  queryAttributes,
  onAddToCart,
  onAttributeChangeHandler,
  setVariantId,
  variantId,
  locale,
  productId,
  product,
}) => {
  const intl = useIntl();
  const { push } = useRouter();
  const { user } = useAuth();
  const { validateAddr, setPromptInput } = React.useContext(SavedLocationContext);
  // const noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  // const purchaseAvailableDate =
  //   !isAvailableForPurchase &&
  //   availableForPurchase &&
  //   Date.parse(availableForPurchase);

  const [quantity, setQuantity] = useState<number>(1);
  const isOutOfStock = false
  const [preselect, setPreselect] = useState(true)
  const isNoItemsAvailable = false
  const isLowStock = false

  const [
    variantPricing,
    setVariantPricing,
  ] = useState<ProductDetails_product_variants_pricing | null>(null);

  const [selectedVariantId, setSelectedVariantId] = useState(null);
  const [deliveryTime, setDeliveryTime] = useState(null)
  const [maxQuantity, setMaxQuantity] = useState(0)
  const [selectedVariant, setSelectedVariant] = useState(null)

  const itemInCart = (items || []).find(item => item.variant.id === selectedVariantId);

  const { earLiestDeliveryTime, refetchDeliveryTime } = useEarliestDeliveryTime(product, selectedVariant);

  useEffect(() => {
    setDeliveryTime(earLiestDeliveryTime)
  }, [earLiestDeliveryTime])

  useEffect(() => {
    setMaxQuantity(() => selectedVariant
      ? selectedVariant.quantityAvailable > 15
        ? 15
        : selectedVariant.quantityAvailable
      : 0)
    setVariantId(selectedVariant?.id);
    setVariantPricing(() => selectedVariant?.pricing);
    setQuantity(1)
  }, [selectedVariant]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedVariant(() => selectedVariantId
      ? productVariants?.find(item => item.id === selectedVariantId)
      : productVariants && productVariants[0])
  }, [selectedVariantId]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setSelectedVariantId(() => product?.defaultVariant?.id);
    setPreselect(true)
  }, [productId]); // eslint-disable-line react-hooks/exhaustive-deps

  const noPurchaseAvailable = !isAvailableForPurchase && !availableForPurchase;
  const purchaseAvailableDate =
    !isAvailableForPurchase &&
    availableForPurchase &&
    Date.parse(availableForPurchase);

  const renderErrorMessage = (message: string, testingContextId: string) => (
    <S.ErrorMessage
      data-test="stockErrorMessage"
      data-testId={testingContextId}
    >
      {message}
    </S.ErrorMessage>
  );

  const onVariantPickerChange = (
    _selectedAttributesValues?: IProductVariantsAttributesSelectedValues,
    newSelectedVariant?: ProductDetails_product_variants,
  ): undefined => {
    if (!newSelectedVariant) {
      setVariantId("");
      setSelectedVariantId(null);
      return;
    }
    setSelectedVariantId(newSelectedVariant.id)
  };

  const proceedToCheckout = () => {
    pushCheckout(1, items, null);
    push(user ? paths.checkout : paths.login);
  }

  return (
    <S.AddToCartSelection>
      <S.ProductNameHeader data-test="productName">{name}</S.ProductNameHeader>
      {isOutOfStock ? (
        renderErrorMessage(
          intl.formatMessage(commonMessages.outOfStock),
          "outOfStock",
        )
      ) : (
        <S.ProductPricing>
          {getProductPrice(productPricing, variantPricing)}
        </S.ProductPricing>
      )}
      {noPurchaseAvailable &&
        renderErrorMessage(
          intl.formatMessage(commonMessages.noPurchaseAvailable),
          "notAvailable",
        )}
      {purchaseAvailableDate &&
        renderErrorMessage(
          intl.formatMessage(commonMessages.purchaseAvailableOn, {
            date: new Intl.DateTimeFormat("default", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }).format(purchaseAvailableDate),
            time: new Intl.DateTimeFormat("default", {
              hour: "numeric",
              minute: "numeric",
            }).format(purchaseAvailableDate),
          }),
          "timeRestrictedAvailability",
        )}
      {isLowStock &&
        renderErrorMessage(
          intl.formatMessage(commonMessages.lowStock),
          "lowStockWarning",
        )}
      {isNoItemsAvailable &&
        renderErrorMessage(
          intl.formatMessage(commonMessages.noItemsAvailable),
          "noItemsAvailable",
        )}
      <S.VariantPicker>
        <ProductVariantPicker
          productVariants={productVariants.map(item => ({
            ...item,
            // @ts-ignore
            name: locale === 'en' ? item.name : (item.translation?.name || item.name),
          }))}
          onChange={onVariantPickerChange}
          selectSidebar
          queryAttributes={queryAttributes}
          onAttributeChangeHandler={onAttributeChangeHandler}
          selectedVariantID={selectedVariant?.attributes?.values[0]?.id}
          preselectFirst={preselect}
          onPreselect={(finished) => {
            if (finished) {
              setPreselect(false)
            }
          }}
          productId={productId}
        />
      </S.VariantPicker>
      <S.CartActionWrapper className={isMobile ? "mobile__view" : ""}>
        <S.QuantityInput>
          <QuantityInput
            quantity={quantity}
            maxQuantity={deliveryTime?.isAvailable && maxQuantity || undefined}
            disabled={false}
            onQuantityChange={(qty) => {
              if (validateAddr) {
                return setPromptInput(true);
              }
              if (qty - 1 === maxQuantity) {
                return
              }
              let quantityToAdd = qty
              if (itemInCart) {
                quantityToAdd += itemInCart.quantity
              }
              refetchDeliveryTime(product, selectedVariant, quantityToAdd)
              setQuantity(() => qty)
            }}
            hideErrors={!variantId}
            testingContext="addToCartQuantity"
          />
        </S.QuantityInput>
        <AddToCartButton
          onSubmit={async () => {
            let quantityToAdd = quantity
            if (itemInCart) {
              quantityToAdd += itemInCart.quantity
            }
            // double check product availability before adding to cart
            if (!await refetchDeliveryTime(product, selectedVariant, quantityToAdd)) {
              return
            }
            onAddToCart(variantId, quantity, false)
          }
          }
          loading={deliveryTime?.buttonLoading}
          disabled={!deliveryTime?.isAvailable}
          buttonText={!deliveryTime?.isAvailable && deliveryTime?.buttonText || (isMobile ? "Add" : "Add to cart")}
        />
      </S.CartActionWrapper>
      <S.BuyNowButton className={isMobile ? "mobile__view" : ""}>
          <AddToCartButton
            onSubmit={async () => {
              let quantityToAdd = quantity
              if (itemInCart) {
                quantityToAdd += itemInCart.quantity
              }
              // double check product availability before adding to cart
              if (!await refetchDeliveryTime(product, selectedVariant, quantityToAdd)) {
                return
              }
              onAddToCart(variantId, quantity, true)
              proceedToCheckout()
            }
            }
            loading={deliveryTime?.buttonLoading}
            disabled={!deliveryTime?.isAvailable}
            buttonText="Buy Now"
            buyNowButton
          />
        </S.BuyNowButton>
    </S.AddToCartSelection>
  );
};
AddToCartSection.displayName = "AddToCartSection";
export default AddToCartSection;

import Modal from "react-modal";
import { Icon } from '@iconify/react';
import Geosuggest from "@ubilabs/react-geosuggest";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState, useRef, useContext } from "react";

import './index.module.scss';
import { useLocalStorage } from "@hooks";
import NewsletterModal from './newsletterModal';
import { SavedLocationContext } from "../../@next/components/contexts";

const SUGGEST_ITEM_HEIGHT = 30;
const PLACEHOLDER = "Where do you want to get delivered?";
const DEFAULT_TITLE =
  "Please enter your delivery address first to make sure we can deliver to you. We currently deliver in Peninsular Malaysia.";
// const INVALID_STATE = ["Sabah", "Sarawak"];
const INVALID_STATE = [];
const ADDRESS_FIELDS = [
  "streetAddress1",
  "streeAddress2",
  "postalCode",
  "countryArea",
];

const SearchLocation = props => {
  const { promptInput, setPromptInput } = useContext(SavedLocationContext);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const [selected, setSelected] = useState(null);
  const [confirmAddress, setConfirmAddress] = useState(null);
  const [suggestCount, setSuggestCount] = useState(0);
  const [controlOpacity, setControlOpacity] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [toRender, setToRender] = useState(true);
  const [clickEnter, setClickEnter] = useState(false);
  const [newAddress, setNewAddress] = useState(null);
  const [newsletterModalVisible, setNewsletterModalVisible] = useState(false);
  const geosuggestEl = useRef(null);
  const enterEl = useRef(null);

  const persistDeliveryLocation = useLocalStorage("deliveryLocation", "");
  const userInfo = useLocalStorage("apollo-cache-persist", "");
  const defaultAddress = useLocalStorage("default-address", "");

  useEffect(() => {
    if (promptInput) {
      setShowInput(true);
    }
  }, [promptInput]);

  const checkPostcode = async (e) => {
    const hasPostcode = e?.gmaps?.address_components.some((component) => component.types.includes('postal_code'))
    if (!hasPostcode){
      let lat = e?.location?.lat
      let lng = e?.location?.lng
      let latlng = lat + "," + lng
      let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}`
      try {
        const response = await fetch(url); // Replace this URL with your API endpoint
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        let new_address = {
          'gmaps': data.results[0],
          'no_postcode': true,
        }
        setNewAddress(new_address)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else {
      props.setSavedLocation(e);
    }
  }

  useEffect(() => {
    if (newAddress !== null){
      props.setSavedLocation(newAddress)
      setSelected(newAddress)
      persistDeliveryLocation.setValue(newAddress);
    }
  }, [newAddress, props]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    setToRender(true);
    const excludePath = ["checkout", "order-history", "cart", "order-finalized"];
    for (let x = 0; x < excludePath.length; x++) {
      if (props.pathname.indexOf(excludePath[x]) >= 0) {
        setToRender(false);
        break;
      }
    }
  }, [props]);

  useEffect(() => {
    // Check if user had manually changed the delivery address
    if (persistDeliveryLocation?.storedValue && defaultAddress?.storedValue !== "check") {
      if (persistDeliveryLocation?.storedValue?.description){
        setConfirmAddress(persistDeliveryLocation?.storedValue?.description);
      } else {
        setConfirmAddress(persistDeliveryLocation?.storedValue?.gmaps?.formatted_address);
      }
      props.setSavedLocation(persistDeliveryLocation?.storedValue);
      props.setValidateAddr(false);
    } else if (defaultAddress?.storedValue && defaultAddress?.storedValue === "check") {
      // If user had logged in, preset the addrees info at the bar
      setTimeout(() => {
        const _maxLoop = 15;
        let _counter = 0;
        // Check for user default address
        const lookupAddress = setInterval(() => {
          // To prevent the cached data
          const _userInfo = JSON.parse(localStorage.getItem("apollo-cache-persist"));
          Object.keys(_userInfo).filter(k => k.split(":").indexOf("Address") !== -1).map(key => {
            if (_userInfo?.[key].isDefaultShippingAddress) {
              clearInterval(lookupAddress);
              const address = ADDRESS_FIELDS.map(i => _userInfo?.[key][i])
                .join(", ")
                .replace(", ,", ",");
              defaultAddress.setValue(_userInfo?.[key]);
              setConfirmAddress(address);
              props.setValidateAddr(false);
              geosuggestEl.current?.selectSuggest();
              setClickEnter(prev => true);
            }
          })
          _counter++;
          if (_counter === _maxLoop) {
            clearInterval(lookupAddress);
          }
        }, 300);
      }, 500);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (showInput) {
      geosuggestEl.current.focus();
      setPromptInput(false);
    }
    setControlOpacity(1);
    setTimeout(function _() { }, 500);
  }, [showInput]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onLocationChange = e => {
      const newDeliveryLocation = localStorage.getItem("deliveryLocation");

      if (newDeliveryLocation) {
        const storedValue = JSON.parse(newDeliveryLocation);
        if (storedValue !== "") {
          // setConfirmAddress(storedValue?.description);
          if (storedValue?.description){
            setConfirmAddress(storedValue?.description);
          } else {
            setConfirmAddress(storedValue?.gmaps?.formatted_address);
          }
          props.setSavedLocation(storedValue);
          props.setValidateAddr(false);
          defaultAddress.setValue(null);
        }
      }
    }

    const onUserLoggedIn = e => {
      let defaultAddressInfo = localStorage.getItem("default-address");

      if (defaultAddressInfo) {
        defaultAddressInfo = JSON.parse(defaultAddressInfo);
        if (defaultAddressInfo) {
          const address = ADDRESS_FIELDS.map(i => defaultAddressInfo?.[i])
            .join(", ")
            .replace(", ,", ",");

          setConfirmAddress(address);
          props.setValidateAddr(false);
          geosuggestEl.current?.selectSuggest();
          setClickEnter(prev => true);
        }
      }
    }

    document.addEventListener("delivery-location-changed", onLocationChange);
    document.addEventListener("user-logged-in", onUserLoggedIn);

    return () => {
      document.removeEventListener("delivery-location-changed", onLocationChange);
      document.removeEventListener("user-logged-in", onUserLoggedIn);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const checkState = (address, type) => {
    for (let x = 0; x < address.length; x++) {
      if (address[x].types.indexOf(type) >= 0) {
        if (INVALID_STATE.indexOf(address[x].short_name) >= 0) {
          return false;
        } 
        return true;
      }
    }
    return false;
  };

  const fadeAction = hide => {
    setControlOpacity(0);
    setTimeout(() => {
      setShowInput(hide);
    }, 300);
  };

  const renderSuggestItem = i => {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span style={{ flex: 1 }}>
          <p
            style={{
              ...styles.text,
              height: 20,
              overflow: "hidden",
              width: "230px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {i.description}
          </p>
        </span>
        <span style={{ flex: 1 }}>
          <Icon icon="bi:geo-alt" width="20" height="20" />
        </span>
      </div>
    );
  };

  const _renderBarInfo = () => {
    if (!showInput) {
      if (confirmAddress) {
        return (
          <div
            onClick={() => fadeAction(true)}
            style={{
              ...styles.topBar,
              opacity: controlOpacity,
              backgroundColor: "#fff",
            }}
            className="search-location-div"
          >
            <div style={{ height: 47 }} />
            <span>
              <Icon icon="bi:geo-alt-fill" color="#000" width="20" height="20" />
            </span>
            <span
              style={{
                ...styles.barText,
                color: "#323232",
                overflow: "hidden",
                width: "330px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {" "}
              {confirmAddress}
            </span>
            <div
              className="voucher-desktop"
              style={styles.voucher}
              onClick={(e) => {
                e.stopPropagation();
                setNewsletterModalVisible(true);
              }}
            >
              <p style={styles.voucherText}>
                <FormattedMessage id={newsletterModalVisible === 'sent'
                  ? 'Thanks, you will shortly receive your voucher via email!'
                  : 'First order? Get a RM 20 voucher'
                } defaultMessage={newsletterModalVisible === 'sent'
                ? 'Thanks, you will shortly receive your voucher via email!'
                : 'First order? Get a RM 20 voucher'
                }/>
              </p>
            </div>
          </div>
        );
      }
      return (
        <div
          onClick={() => fadeAction(true)}
          style={{
            ...styles.topBar,
            opacity: controlOpacity,
            backgroundColor: "#E3F7ED",
          }}
          className="search-location-div"
        >
          <span>
            <Icon icon="bi:geo-alt" color="#6AD08A" width="20" height="20" />
          </span>
          <span style={{ ...styles.barText, color: "#979797" }}>
            {" "}
            {PLACEHOLDER}
          </span>
          <div
            className="voucher-desktop"
            style={styles.voucher}
            onClick={(e) => {
              e.stopPropagation();
              setNewsletterModalVisible(true);
            }}
          >
            <p style={styles.voucherText}>
              <FormattedMessage id={newsletterModalVisible === 'sent'
                ? 'Thanks, you will shortly receive your voucher via email!'
                : 'First order? Get a RM 20 voucher'
              } defaultMessage={newsletterModalVisible === 'sent'
              ? 'Thanks, you will shortly receive your voucher via email!'
              : 'First order? Get a RM 20 voucher'
              }/>
            </p>
          </div>
        </div>
      );
    }
  };

  if (!toRender) {
    return null;
  }

  return (
    <>
      {/* Overlay */}
      {showInput && !showModal ? <div onClick={() => fadeAction(false)} style={styles.overlay} /> : null}
      {/* Container */}
      <div
        style={{
          ...styles.topBar,
          opacity: 1,
          paddingTop: 0,
          display: showInput ? "flex" : "none",
          flexDirection: "column",
        }}
        className="search-location-div"
      >
        {/* Row 1 */}
        <div style={{ ...styles.row, width: '100%' }}>
          {/* Col 1 */}
          <Geosuggest
            initialValue={confirmAddress}
            autoComplete="off"
            ref={geosuggestEl}
            onChange={() => setSelected(null)}
            onFocus={() => setFocusInput(true)}
            onBlur={() => fadeAction(false)}
            renderSuggestItem={renderSuggestItem}
            style={{
              input: {
                ...styles.geoInput,
                borderColor: selected || focusInput ? "#323232" : "#979797",
              },
              suggests: styles.geoSuggest,
              suggestItem: { margin: 2 },
            }}
            country="my"
            onUpdateSuggests={e => {
              setSuggestCount(e.length);
            }}
            skipSuggest={suggest => {
              if (suggest.types.includes('political') || suggest.types.includes('route')) {
                return true;
              }
            }}
            onSuggestSelect={e => {
              if (e) {
                setFocusInput(false);
                if (e?.gmaps?.address_components.some((component) => component.types.includes('postal_code'))){
                  setSelected(e);
                }
                
                if (!e?.description) {
                  e.description = confirmAddress;
                }
                if (
                  checkState(
                    e?.gmaps?.address_components,
                    "administrative_area_level_1",
                  )
                ) {
                  checkPostcode(e)
                  if (e?.gmaps?.address_components.some((component) => component.types.includes('postal_code'))){
                    setConfirmAddress(e?.description);
                  } else {
                    setConfirmAddress(e?.gmaps?.formatted_address);
                  }
                  
                  props.setValidateAddr(false);
                  persistDeliveryLocation.setValue(e);
                  if (!clickEnter) {
                    defaultAddress.setValue(null);
                    fadeAction(false);
                  }
                } else {
                  persistDeliveryLocation.setValue("");
                  setShowModal(true);
                }
                setClickEnter(prev => false);
              } else {
                setConfirmAddress(null);
                props.setSavedLocation(null);
                props.setValidateAddr(true);
                persistDeliveryLocation.setValue("");
              }

              if (clickEnter) {
                enterEl.current?.click();
              }
            }}
            placeholder={PLACEHOLDER}
          />
          {/* Col 2 */}
          <div
            ref={enterEl}
            onClick={() => {
              if (selected) {
                
                if (!selected?.description) {
                  selected.description = confirmAddress;
                }
                if (
                  checkState(
                    selected?.gmaps?.address_components,
                    "administrative_area_level_1",
                  )
                ) {
                  if (selected?.gmaps?.address_components.some((component) => component.types.includes('postal_code'))){
                    setConfirmAddress(selected?.description);
                  } else {
                    setConfirmAddress(selected?.gmaps?.formatted_address);
                  }
                  
                  props.setSavedLocation(selected);
                  props.setValidateAddr(false);
                  persistDeliveryLocation.setValue(selected);
                  if (!clickEnter) {
                    defaultAddress.setValue(null);
                    fadeAction(false);
                  }

                } else {
                  persistDeliveryLocation.setValue("");
                  setShowModal(true);
                }
              }

              setClickEnter(prev => false);
            }}
            style={{
              ...styles.enterBtn,
              backgroundColor: selected || focusInput ? "#000" : "#979797",
            }}
          >
            <span> ENTER </span>
          </div>

          <div
            className="voucher-desktop"
            style={styles.voucher}
            onClick={(e) => {
              e.stopPropagation();
              setNewsletterModalVisible(true);
            }}
          >
            <p style={styles.voucherText}>
              <FormattedMessage id={newsletterModalVisible === 'sent'
                ? 'Thanks, you will shortly receive your voucher via email!'
                : 'First order? Get a RM 20 voucher'
              } defaultMessage={newsletterModalVisible === 'sent'
              ? 'Thanks, you will shortly receive your voucher via email!'
              : 'First order? Get a RM 20 voucher'
              } />
            </p>
          </div>
        </div>

        {/* Row 2 */}
        {focusInput ? (
          <div style={{ ...styles.row, zIndex: 2, position: "relative" }}>
            <div
              style={{
                ...styles.row,
                paddingTop: suggestCount > 0 ? 10 : 0,
                position: "absolute",
                top: `${suggestCount * SUGGEST_ITEM_HEIGHT}px`,
              }}
            >
              {/* Col 1 */}
              <div
                style={{
                  borderTop: "1px solid #ECECEC",
                  width: "290px",
                  backgroundColor: "#fff",
                  padding: 10,
                  borderBottomLeftRadius: 3,
                  borderBottomRightRadius: 3,
                }}
              >
                <p style={styles.text}>{DEFAULT_TITLE}</p>
              </div>
              {/* Col 2 */}
              <div style={{ width: "87px" }} />
            </div>
          </div>
        ) : null}

      </div>

      {/* Address Bar */}
      {_renderBarInfo()}

      <div
        className="voucher-mobile"
        onClick={(e) => {
          e.stopPropagation();
          setNewsletterModalVisible(true);
        }}
      >
        <p style={styles.voucherText}>
          <FormattedMessage id={newsletterModalVisible === 'sent'
            ? 'Thanks, you will shortly receive your voucher via email!'
            : 'First order? Get a RM 20 voucher'
          } defaultMessage={newsletterModalVisible === 'sent'
          ? 'Thanks, you will shortly receive your voucher via email!'
          : 'First order? Get a RM 20 voucher'
          } />
        </p>
      </div>

      <Modal
        style={styles.modal}
        onRequestClose={() => {
          setShowModal(false)
          geosuggestEl.current.focus();
          setPromptInput(false);
        }}
        shouldCloseOnOverlayClick
        isOpen={showModal}
      >
        <p style={styles.modalTitle}>
          <FormattedMessage id="Sorry, we don’t deliver outside of peninsular Malaysia yet" defaultMessage="Sorry, we don’t deliver outside of peninsular Malaysia yet" />
        </p>
        <p style={styles.modalText}>
          <FormattedMessage id="We currently deliver in peninsular Malaysia from same-day delivery to a delivery time of 4 days, depending on item availability." defaultMessage="We currently deliver in peninsular Malaysia from same-day delivery to a delivery time of 4 days, depending on item availability." />
        </p>
      </Modal>
      <NewsletterModal
        visible={newsletterModalVisible}
        closeModal={status => setNewsletterModalVisible(status)}
      />
    </>
  );
};

const styles = {
  overlay: {
    display: "block",
    position: "fixed",
    zIndex: 1,
    width: "100%",
    height: "110vh",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
  modalTitle: {
    fontFamily: "Helvetica",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    background: "linear-gradient(180deg, #79DDC4 0%, #6AD08A 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  modalText: {
    marginTop: 30,
    fontFamily: "Helvetica",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "#323232",
  },
  modal: {
    overlay: {
      zIndex: 5,
      backgroundColor: "rgba(0,0,0,0.3)",
    },
    content: {
      overflow: "hidden",
      padding: 30,
      height: isMobile ? 230 : 200,
      borderRadius: 10,
      inset: isMobile ? "100px 5%" : "100px 20%",
    },
  },
  hide: {
    opacity: 0,
    pointerEvents: "none",
  },
  show: {
    transition: "opacity 0.5s",
    opacity: 1,
  },
  geoInput: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderRadius: "3px 0px 0px 3px",
    borderRight: "none",
    height: "56px",
    padding: 10,
    width: "290px",
    outline: "none",
    fontSize: '16px',
  },
  geoSuggest: {
    zIndex: 2,
    position: "absolute",
    backgroundColor: "rgba(255,255,255,1)",
    padding: 10,
    width: "290px",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    fontSize: 13,
    borderColor: "rgba(202,202,202,0.7)",
    borderWidth: "2px",
  },
  container: {
    flexDirection: "column",
  },
  enterBtn: {
    cursor: "pointer",
    height: "56px",
    width: "87px",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  topBar: {
    zIndex: 2,
    transition: "opacity 0.5s ease",
    width: "100%",
    height: "56px",
    display: "flex",
    flexDirection: "row",
    position: "sticky",
    paddingLeft: 20,
    boxShadow: '1px 1px 20px 0px #88888817',
    backgroundColor: '#F2F5F5',
    alignItems: 'center',
  },
  row: {
    alignSelf: "start",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
  },
  barText: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fonWweight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    marginLeft: 10,
  },
  text: {
    fontFamily: "Helvetica",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  voucher: {
    marginLeft: 'auto',
    marginRight: '20px',
    background: 'rgba(50, 50, 50, 1)',
    borderRadius: '12px',
    padding: '5px 16px',
    marginTop: '0px',
    zIndex: -1,
    cursor: 'pointer',
    height: 34,
    marginBottom: 0,
  },
  voucherText: {
    margin: 0,
    color: 'white',
  },
};

export default SearchLocation;

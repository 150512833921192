import gql from "graphql-tag";

import { TypedMutation } from "../../../core/mutations";
import {
  RegisterAccount,
  RegisterAccountVariables,
} from "./gqlTypes/RegisterAccount";

const accountRegisterMutation = gql`
  mutation RegisterAccount(
    $email: String!
    $password: String!
    $redirectUrl: String
    $channel: String
    $firstName: String
    $lastName: String
    $phone: String
    $xDID: String!
    $xDIDH: String!
    $dob: Date
    $gender: String
  ) {
    accountRegister(
      input: {
        email: $email
        password: $password
        redirectUrl: $redirectUrl
        channel: $channel
        firstName: $firstName
        lastName: $lastName
        phone: $phone,
        xDID:  $xDID,
        xDIDH: $xDIDH,
        dob: $dob,
        gender: $gender,
      }
    ) {
      errors {
        field
        message
      }
      requiresConfirmation
    }
  }
`;

export const createToken = gql`
  mutation oauthTokenCreate(
    $accessToken: String!
    $backend: String!
    $xDID: String!
    $xDIDH: String!
    $channel: String!
  ) {
    oauthTokenCreate(
      accessToken: $accessToken
      backend: $backend
      xDID: $xDID
      xDIDH: $xDIDH
      channel: $channel
    ) {
      token
    }
  }
`;

export const havePasswordQuery = gql`
  query havePassword($email: String!) {
    havePassword( email: $email )
  }
`;

export const verifyPhoneOtpStatusQuery = gql`
query otpStatus($phone: String!) {
  otpStatus(phone:$phone){
    phone
    status
    id
    userId
}
}
`

// default saleor sdk won't reply 'phone'
export const getUserDetail = gql`
  query {
    me {
      id
      phone
    }
  }
`;

export const getUnhashedId = gql`
query unHash($hashedId: String!){
  unHash(hashedId: $hashedId)
}
`

export const TypedAccountRegisterMutation = TypedMutation<
  RegisterAccount,
  RegisterAccountVariables
>(accountRegisterMutation);

import { useIntl } from "react-intl";
import { Form } from "react-final-form";
import React, { useEffect, useState } from "react";

import { IProps } from "./types";
import { checkoutMessages } from "@temp/intl";
import { AddressFormModal } from "../AddressFormModal";
import { AddressTileOption } from "@components/molecules";
import { ErrorMessage, TileGrid } from "@components/atoms";

/**
 * Addresses tiles to select with add new address tile opening address form addition modal.
 */
const AddressGridSelector: React.FC<IProps> = ({
  addresses,
  selectedAddressId,
  countriesOptions,
  userId,
  errors,
  onSelect,
  formId,
  formRef,
  addNewModalTarget,
  newAddressFormId,
  testingContext,
  onSelectSavedAddress,
}: IProps) => {
  const [displayNewModal, setDisplayNewModal] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (selectedAddressId) {
      const address = addresses.find(
        addr => addr.id === selectedAddressId,
      );
      onSelectSavedAddress(address, selectedAddressId)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // const addNewTile = (
  //   <AddNewTile
  //     data-test={`${testingContext}AddressTileAddNew`}
  //     key="newTile"
  //     type={intl.formatMessage({ defaultMessage: "address" })}
  //     onClick={() => setDisplayNewModal(true)}
  //   />
  // );

  return (
    <>
      <Form
        initialValues={{
          addressTileOption: selectedAddressId,
        }}
        keepDirtyOnReinitialize
        onSubmit={(values) => {
          return new Promise<void>(resolve => {
            if (onSelect) {
              const address = addresses.find(
                addr => addr.id === values.addressTileOption,
              );
              onSelect(address?.address, values.addressTileOption);
            }
            resolve();
          });
        }}
        render = {({
          handleSubmit,
          values,
          form,
        }) => {
          return (
            <form id={formId} ref={formRef} onSubmit={handleSubmit}>
              <TileGrid
                columns={2}
                elements={addresses.reduce(
                  (elements, { id, address }, index) => {
                    elements.push(
                      <AddressTileOption
                        testingContext={testingContext}
                        data-test={`${testingContext}AddressTileOption`}
                        data-test-id={index}
                        key={`addressTile-${id}`}
                        id={id}
                        inputName="addressTileOption"
                        address={address}
                        onChange={() => {
                          if (onSelectSavedAddress) {
                            onSelectSavedAddress(address, id)
                          }
                          form.change("addressTileOption", id)
                        }}
                        checked={
                          !!values.addressTileOption &&
                          values.addressTileOption === id
                        }
                      />,
                    );
                    return elements;
                  },
                  [],
                )}
              />
              {errors?.filter(err => err?.field).length === 0 && <ErrorMessage errors={errors} />}
            </form>
          );
        }}
      />
      {displayNewModal && (
        <AddressFormModal
          hideModal={() => {
            setDisplayNewModal(false);
          }}
          submitBtnText="Add"
          title={intl.formatMessage(checkoutMessages.addNewAddress)}
          countriesOptions={countriesOptions}
          formId={newAddressFormId}
          userId={userId}
          target={addNewModalTarget}
        />
      )}
    </>
  );
};

export { AddressGridSelector };

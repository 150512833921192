exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".message{width:25rem;padding:1.5rem;background-color:#fff;box-shadow:0px 6px 15px 3px rgba(0,0,0,.25);position:fixed;bottom:1rem;right:1rem;border-left:.3rem solid #979797}.message--hidden{display:none}.message__status-success{border-color:#3ed256}.message__status-error{border-color:#fe6e76}.message__title{text-transform:uppercase;font-weight:600;margin:0 1.5rem 0 0}.message__content{margin:1rem 0 0}.message__close-icon{position:absolute;top:1.8rem;right:1.5rem;transition:.3s}.message__close-icon svg *{transition:.3s}.message__close-icon:hover svg *{fill:#979797}", "", {"version":3,"sources":["/vercel/path0/src/components/Message/scss/index.module.scss"],"names":[],"mappings":"AAA6C,SAAS,YAAY,eAAe,sBAAsB,4CAA4C,eAAe,YAAY,WAAW,+BAA+B,CAAC,iBAAiB,YAAY,CAAC,yBAAyB,oBAAoB,CAAC,uBAAuB,oBAAoB,CAAC,gBAAgB,yBAAyB,gBAAgB,mBAAmB,CAAC,kBAAkB,eAAe,CAAC,qBAAqB,kBAAkB,WAAW,aAAa,cAAc,CAAC,2BAA2B,cAAc,CAAC,iCAAiC,YAAY,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.message{width:25rem;padding:1.5rem;background-color:#fff;box-shadow:0px 6px 15px 3px rgba(0,0,0,.25);position:fixed;bottom:1rem;right:1rem;border-left:.3rem solid #979797}.message--hidden{display:none}.message__status-success{border-color:#3ed256}.message__status-error{border-color:#fe6e76}.message__title{text-transform:uppercase;font-weight:600;margin:0 1.5rem 0 0}.message__content{margin:1rem 0 0}.message__close-icon{position:absolute;top:1.8rem;right:1.5rem;transition:.3s}.message__close-icon svg *{transition:.3s}.message__close-icon:hover svg *{fill:#979797}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
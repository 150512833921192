import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { isMobile } from "react-device-detect";

import "./scss/index.module.scss";

const Carousel = dynamic(() => import("../Carousel"));

const Promotions = ({ collections }) => {
  const [mobileCollections, setMobileCollections] = React.useState([]);
  const [desktopCollections, setDesktopCollections] = React.useState([]);
  const [mobileView, setMobileView] = React.useState(isMobile);

  useEffect(() => {
    //* NOTE: This a workaround to force trigger the recalcualtion on image width
    window.setTimeout(() =>  {
      window.dispatchEvent(new Event('resize'));
    }, 300)
  }, [])

  const getLink = collection => {
    let urlMeta = collection.node.metadata.find(item => item.key === 'url')
    return urlMeta ? urlMeta.value : '/'
  }

  const sortCollecttions = (a, b) => {
    const getOrder = item => item.node.metadata.find( meta => meta.key === 'order')?.value
    const orderA = getOrder(a) ? Number(getOrder(a)) : 1000
    const orderB = getOrder(b) ? Number(getOrder(b)) : 1000
    if ( orderA < orderB ) {
      return -1;
    }
    if ( orderA > orderB ) {
      return 1;
    }
    return 0;
  }

  React.useEffect(()=> {
    let promotion_class = document.getElementsByClassName('promotions')[0]
    if (isMobile) {
      if (promotion_class) {
        if (mobileCollections.length === 0) {
          promotion_class.style.display = 'none'
        } else {
          promotion_class.style.display = 'block'
        }
      }
    } else if (promotion_class) {
      if (desktopCollections.length === 0) {
        promotion_class.style.display = 'none'
      } else {
        promotion_class.style.display = 'block'
      }
    }
  }, [isMobile, mobileCollections, desktopCollections]); // eslint-disable-line react-hooks/exhaustive-deps

  const hasBackgroundImage = item => item.node.backgroundImage;

  React.useEffect(() => {
    let collection = collections.collections.edges.filter(hasBackgroundImage)
    let mobileCollectionsArr = [];
    let desktopCollectionsArr = [];

    collection.forEach(item=> {
      item.node.metadata.forEach(meta_item => {
        if (meta_item.key.toLowerCase() === 'mobile' && meta_item.value.toLowerCase() === 'true') {
          mobileCollectionsArr.push(item)
        } else if (meta_item.key.toLowerCase() === 'main-page' && meta_item.value.toLowerCase() === 'true') {
          desktopCollectionsArr.push(item)
        }
      })
    })
    setMobileCollections(mobileCollectionsArr)
    setDesktopCollections(desktopCollectionsArr)
  }, [collections])

  const handleResize = () => {
    let width_window = window.innerWidth;
    if (width_window >= 540) {
      setMobileView(false)
    } else {
      setMobileView(true)
    }
  }

  React.useEffect(()=> {
    if (window) {
      window.addEventListener('resize', handleResize)
      handleResize()
    }
    return () => {window.removeEventListener('resize', handleResize)}
  }, [])

  React.useEffect(()=> {
    window.dispatchEvent(new Event('resize'));
  }, [mobileView])



  return (
    <div className="promotions">
      {mobileView ?
      <Carousel 
        slidesToShow={1}
        slidesToScroll={1} 
        renderBottomCenterControls={mobileCollections.length > 1 ? undefined : false}
        dragging={mobileCollections.length > 1}
        swiping={mobileCollections.length > 1}
        wrapAround
        autoplay={mobileCollections.length > 1}
      >
        {(mobileCollections ? [...mobileCollections.sort(sortCollecttions)] : []).map((collection, index) => (
          <Link key={index} href={getLink(collection)} target='_blank'>
              <a>
                  <Image 
                    loader={() => collection.node.backgroundImage.url}
                    src={collection.node.backgroundImage.url}
                    className="banner"
                    height='580px'
                    width='1920px'
                    alt=""
                  />
              </a>
          </Link>
        ))}
      </Carousel>
      :
      <Carousel 
        slidesToShow={1}
        slidesToScroll={1} 
        renderBottomCenterControls={desktopCollections.length > 1 ? undefined : false}
        dragging={desktopCollections.length > 1}
        swiping={desktopCollections.length > 1}
        wrapAround
        autoplay={desktopCollections.length > 1}
      >
        {(desktopCollections ? [...desktopCollections.sort(sortCollecttions)] : []).map((collection, index) => (
          <Link key={index} href={getLink(collection)} target='_blank'>
              <a>
                  <Image 
                    loader={() => collection.node.backgroundImage.url}
                    src={collection.node.backgroundImage.url}
                    className="banner"
                    height='580px'
                    width='1920px'
                    alt=""
                  />
              </a>
          </Link>
        ))}
      </Carousel>}
    </div>
  )
}

export default Promotions;

/* eslint-disable @typescript-eslint/no-shadow */
// @ts-nocheck
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import React, { useState, useEffect, useContext } from "react";
import Link from "next/link";
import { useIntl } from "react-intl";
import { Thumbnail, InputSelect } from "@components/molecules";
import { FeaturedProduct } from "@graphql/gqlTypes/FeaturedProduct";
import { useEarliestDeliveryTime } from "@hooks"
import { TaxedMoney } from "../../@next/components/containers";
import { AddToCartButton } from '@components/organisms';
import Loader from '@temp/components/Loader';
import "./scss/index.module.scss";

interface ProductListItemProps {
  product: FeaturedProduct;
  style?: object
  href?: string
  key?: string
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product, href, key, style }) => {

  const { category } = product;

  const intl = useIntl()


  const [selectedVariantId, setSelectedVariantId] = useState(product?.defaultVariant?.id);
  const selectedVariant = selectedVariantId
    ? product.variants.find(item => item.id === selectedVariantId)
    : product.variants && product.variants[0];

  let price = selectedVariant
    ? selectedVariant.pricing.price
    : product.pricing?.priceRange?.start;
  let priceUndiscounted = selectedVariant
    ? selectedVariant.pricing.priceUndiscounted
    : product.pricing?.priceRangeUndiscounted?.start;

  const onSelectVariant = (key, val) => setSelectedVariantId(key);

  const [deliveryTime, setDeliveryTime] = useState(null)
  const { earLiestDeliveryTime, refetchDeliveryTime } = useEarliestDeliveryTime(product, selectedVariant);

  useEffect(() => {
    setDeliveryTime(earLiestDeliveryTime)
  }, [earLiestDeliveryTime])

  const getProductPrice = () => {
    if (isEqual(price, priceUndiscounted)) {
      return <TaxedMoney taxedMoney={price} />;
    }
    return (
      <>
        <span className="product-list-item__undiscounted_price">
          <TaxedMoney taxedMoney={priceUndiscounted} />
        </span>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <TaxedMoney taxedMoney={price} />
      </>
    );
  };

  const getAttributes = () => {
    let { attributes } = product;
    let volumeAttribute = attributes && attributes.find(attr => attr?.attribute?.name === 'Volume')
    volumeAttribute = volumeAttribute ? `${volumeAttribute.values[0]?.name || ''}` : ''

    let packTypeAttrbute = attributes && attributes.find(attr => attr?.attribute?.name === 'Pack Type')
    packTypeAttrbute = packTypeAttrbute && packTypeAttrbute.values[0]?.name
      ? ` | ${packTypeAttrbute.values[0]?.name.split(' ')[0]} ${intl.formatMessage({ id: packTypeAttrbute.values[0]?.name.split(' ')[1], defaultMessage: packTypeAttrbute.values[0]?.name.split(' ')[1] })}`
      : ''
    return `${volumeAttribute}${packTypeAttrbute}`;
  }

  return (
    <Link
      href={href}
      key={key}
    >
      <a style={style} >
        <div className="product-list-item">
          {(deliveryTime && deliveryTime?.isAvailable) &&
            <div className="earliest-delivery-time">
              <div><span>Earliest delivery</span></div>
              {deliveryTime?.timeLoading ? <div style={{ marginTop:'20px' }}><Loader circle height={20} /> </div> :
                <div>{deliveryTime.status && <span className="time-status" style={{ backgroundColor: deliveryTime.status }}></span>} <span>{deliveryTime.text}</span></div>
              }
            </div>
          }
          <div className="product-list-item__image rounded-16">
            <Thumbnail source={product} variant={selectedVariant} class="rounded-16" />
          </div>
          {(!product.variants || product?.variants.length <= 1)
            ? <h4 className="product-list-item__title">{product.name}</h4>
            : <div onClick={e => e.preventDefault()}>
              <InputSelect
                className="variant-selector"
                defaultValue={product?.variants[0]?.id}
                name={selectedVariantId || product?.variants[0]?.id}
                options={product.variants}
                value={{
                  ...selectedVariant,
                  name: `${product.name} ${selectedVariant?.name}`,
                }}
                onChange={variant => setSelectedVariantId(variant.id)}
                optionLabelKey="name"
                optionValueKey="id"
              />
            </div>
          }
          <p className="product-list-item__attribute">{getAttributes()}</p>
          <p className="product-list-item__price">{getProductPrice()}</p>
          <AddToCartButton
            product={product}
            variant={selectedVariant}
            disabled={(!deliveryTime?.isAvailable && deliveryTime?.isDraught && deliveryTime?.buttonText === 'Unavailable')
              ? 'draught'
              : !deliveryTime?.isAvailable
            }
            isUnavailable={!deliveryTime?.isAvailable}
            className={!deliveryTime?.isAvailable ? 'isUnavailable' : ''}
            buttonClassname="rounded-12 add-cart-button"
            buttonText={deliveryTime?.buttonText || 'Add'}
            checkAvailability={(product, variant, quantity) => refetchDeliveryTime(product, variant, quantity)}
            loading={deliveryTime?.buttonLoading}
          />
        </div>
      </a>
    </Link>
  );
};




export default ProductListItem;

import { ErrorMessage } from "@components/atoms";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { IFormError } from "@types";
import { Form } from "react-final-form";
import React, { useState } from "react";
import * as S from "./styles";
import { IProps } from "./types";

/**
 * Stripe credit card form.
 */
const StripeGrabForm: React.FC<IProps> = ({
  formRef,
  formId,
  errors = [],
  onSubmit,
}: IProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [stripeErrors ] = useState<IFormError[]>([]);

  const allErrors = [...errors, ...stripeErrors];

  return (
    <Form
      initialValues={null}
      onSubmit={async (values) => {
        await onSubmit(stripe, elements);
      }}
      render = {({
        handleSubmit,
        values,
        submitting,
        valid,
      }) => (
        <S.Form id={formId} ref={formRef} onSubmit={handleSubmit}>
          <ErrorMessage errors={allErrors} />
        </S.Form>
      )}
    />
  );
};

export { StripeGrabForm };

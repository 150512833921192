import { useState, useEffect } from 'react'
import { getSaleorApi } from "@utils/ssr";
import { getDraughtTypeQuery } from './queries';

const useDraught = (getDeliverySlots, items) => {

  const [ draughtTypeID, setDraughtTypeId ] = useState('UHJvZHVjdFR5cGU6Mg==')
  const [ draughtProductsInCart, setDraughtProductsInCart ] = useState([])
  const [ isDraughtAllowed, setDraughtAllowed ] = useState()

  useEffect(() => {
    getDraughtTypeId()
  }, [])

  const getDraughtTypeId = async () => {
    const { apolloClient } = await getSaleorApi()
    const { data } = await apolloClient.query({
      query: getDraughtTypeQuery,
    })
    let draughtType = data.productTypes.edges.find(item => item.node.name === 'Draught')
    if (draughtType) {
      setDraughtTypeId(draughtType.node.id)
    }
  }

  const checkDraughtProducts = () => {
    let draughtProducts = items
      .filter(item => item.variant.product?.productType?.id === draughtTypeID)
      .map(item => item.variant.product.id)
    setDraughtProductsInCart(draughtProducts)
  }


  return [draughtTypeID, isDraughtAllowed, checkDraughtProducts, draughtProductsInCart, setDraughtAllowed]

}

export default useDraught;
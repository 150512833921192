import React, { useState, useEffect, useContext } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import { useCart } from "@saleor/sdk/lib/react/hooks";
import { Input, ErrorMessage, Icon, Button } from "@components/atoms";
import { Quantity, ErrorMessages, QuantityButtons } from '../CartRow/styles';
import { commonMessages } from "@temp/intl";
import './AddToCart.module.scss';
import { Breadcrumbs, Loader, OverlayContext, OverlayTheme, OverlayType } from "../../../../components";
import { SavedLocationContext } from '../../contexts';
import { Popover } from 'antd'
import Link from 'next/link'
import { useRouter } from 'next/router'

const QButtons = (add, subtract, index, color) => (
  <QuantityButtons data-test="quantityControls">
    <div onClick={subtract} data-test="subtractButton">
      <Icon size={16} name="horizontal_line" color={color} />
    </div>
    <div onClick={add} data-test="increaseButton">
      <Icon size={16} name="plus" color={color} />
    </div>
  </QuantityButtons>
);

const AddToCartButton = ({ product, variant, className, disabled, isUnavailable, customColor, buttonClassname, buttonText, checkAvailability, loading }) => {
  const { validateAddr, setPromptInput } = useContext(SavedLocationContext)

  const maxQuantity = variant
    ? variant.quantityAvailable > 15
      ? 15
      : variant.quantityAvailable
    : 0;

  const overlayContext = React.useContext(OverlayContext);
  const intl = useIntl();
  const [quantity, setQuantity] = useState(1);
  const [isTooMuch, setIsTooMuch] = useState(false);
  const router = useRouter()

  const {
    items,
    updateItem,
    removeItem,
    subtotalPrice,
    shippingPrice,
    discount,
    totalPrice,
    addItem,
  } = useCart();

  const itemInCart = (items || []).find(item => item.variant.id === variant?.id);


  useEffect(() => {
    setQuantity(1);
  }, [variant]);


  const add = () => {
    if (quantity + 1 === maxQuantity) {
      setIsTooMuch(true);
    } else if (validateAddr) {
      return setPromptInput(true);
    } else {
      let quantityToAdd = quantity + 1
      if (itemInCart) {
        quantityToAdd += itemInCart.quantity
      }
      checkAvailability(product, variant, quantityToAdd)
      setQuantity(q => q + 1);
    }
  };

  const subtract = () => {
    if (isTooMuch && quantity - 1 <= maxQuantity) {
      setIsTooMuch(false);
    }
    if (quantity > 1) {
      let quantityToSub = quantity - 1
      if (itemInCart) {
        quantityToSub = itemInCart.quantity + quantity - 1
      }
      checkAvailability(product, variant, quantityToSub)
      setQuantity(q => q - 1);
    }
  };


  const onConfirmQuantity = async () => {
    // Check if address selected
    if (validateAddr) {
      return setPromptInput(true)
    }
    let quantityToAdd = quantity;
    if (itemInCart) {
      quantityToAdd += itemInCart.quantity;
    }
    // double check product availability before adding to cart
    if (!(await checkAvailability(product, variant, quantityToAdd))) {
      return;
    }

    if (itemInCart) {
      updateItem(variant.id, quantity + itemInCart.quantity);
    } else {
      addItem(variant.id, quantity);
    }
    setQuantity(1);
    overlayContext.show(OverlayType.cart, OverlayTheme.right);

  };

  const quantityErrors = isTooMuch
    ? [
      {
        message: intl.formatMessage(commonMessages.maxQtyIs, { maxQuantity }),
      },
    ]
    : undefined;

  const draughtMessage = (
    <div>
      <p>Draught beer is not available in your area, sorry for the inconvenience. You can check out the <Link href='/how-to-order'>“How to order page 🔗 ”</Link> to find out what products are available in each region in Malaysia!</p>
    </div>
  )

  return (
    <div onClick={e => e.preventDefault()} className={`add-to-cart ${className || ''}`}>
      <Quantity>
        {customColor ?
          <div className='custom-atc'>
            <div className='float-title' style={{ color: customColor }}>Quantity</div>
            <div className='atc-container' style={{ borderColor: customColor }}>
              <span style={{ color: customColor }}>{quantity}</span>
              {QButtons(add, subtract, 0, customColor)}
            </div>
          </div>
          :
          <Input
            name="quantity"
            label={intl.formatMessage(commonMessages.quantity)}
            value={quantity}
            contentRight={QButtons(add, subtract, 0)}
            error={!!quantityErrors?.length}
          />
        }
        <ErrorMessages>
          <ErrorMessage errors={quantityErrors} />
        </ErrorMessages>
      </Quantity>
      <Popover
        content={draughtMessage}
        open={disabled === 'draught' ? undefined : false}
        placement='bottom'
        overlayClassName='draught-popover'
      >
        <Button
          name="add"
          color="gradient"
          onClick={onConfirmQuantity}
          disabled={isTooMuch || quantity === 0 || disabled || isUnavailable || loading}
          className={buttonClassname || ''}
        >
          {loading ? <Loader circle height={25} color="#ffffff" /> : <FormattedMessage id={buttonText} defaultMessage={buttonText} />}
        </Button>
      </Popover>
    </div>
  );
};

export { AddToCartButton };
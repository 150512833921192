exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "@media(min-width: 992px){.main-body{display:flex;flex-direction:row;overflow-y:hidden;height:100vh;justify-content:space-between}}.main-drinkies-menu{height:100vh;overflow-y:scroll;overflow-x:hidden;max-width:100%;width:100%}@media(min-width: 992px){.main-drinkies-menu{min-width:calc(100% - 712px)}}@media(min-width: 1281px)and (max-width: 1512px){.main-drinkies-menu{max-width:calc(100% - 304px)}}@media(min-width: 993px)and (max-width: 1280px){.main-drinkies-menu{max-width:calc(100% - 204px)}}#main-drinkies-menu::-webkit-scrollbar{display:none}", "", {"version":3,"sources":["/vercel/path0/src/app/scss/index.module.scss"],"names":[],"mappings":"AAA6C,yBAAyB,WAAW,aAAa,mBAAmB,kBAAkB,aAAa,6BAA6B,CAAC,CAAC,oBAAoB,aAAa,kBAAkB,kBAAkB,eAAe,UAAU,CAAC,yBAAyB,oBAAoB,4BAA4B,CAAC,CAAC,iDAAiD,oBAAoB,4BAA4B,CAAC,CAAC,gDAAgD,oBAAoB,4BAA4B,CAAC,CAAC,uCAAuC,YAAY,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}@media(min-width: 992px){.main-body{display:flex;flex-direction:row;overflow-y:hidden;height:100vh;justify-content:space-between}}.main-drinkies-menu{height:100vh;overflow-y:scroll;overflow-x:hidden;max-width:100%;width:100%}@media(min-width: 992px){.main-drinkies-menu{min-width:calc(100% - 712px)}}@media(min-width: 1281px)and (max-width: 1512px){.main-drinkies-menu{max-width:calc(100% - 304px)}}@media(min-width: 993px)and (max-width: 1280px){.main-drinkies-menu{max-width:calc(100% - 204px)}}#main-drinkies-menu::-webkit-scrollbar{display:none}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { DeliverySlotContext } from "../../contexts/deliverySlotsContext"
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";
import { IProps } from "./types";
import moment from "moment";

/**
 * DeliveryTime summary
 */
const DeliveryTimeSummary: React.FC<IProps> = ({ deliveryTime }: IProps) => {

    const { currentDeliverySlot, values } = useContext(DeliverySlotContext)

    if (values?.delivery_date_from_now === 7) {
      return (
        <S.Wrapper data-test="deliveryTimeTile">
          You can expect your order to be delivered in the next 7 days.
        </S.Wrapper>
      );
    }

    return (
      <S.Wrapper data-test="deliveryTimeTile">
        <FormattedMessage id="Delivery Date" {...checkoutMessages.deliveryDate} />
        <br />
        {moment(currentDeliverySlot).format('YYYY-MM-DD')}
        <br />
        <br />
        <FormattedMessage id="Delivery Time" {...checkoutMessages.deliveryTime} />
        <br />
        {moment(currentDeliverySlot).format('HH:mm')} - {moment(currentDeliverySlot).add(2, 'hours').format('HH:mm')}
      </S.Wrapper>
    );
};

export { DeliveryTimeSummary };

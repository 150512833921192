exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".address-summary{color:#7d7d7d;line-height:1.5;font-size:.875rem}.address-summary strong{font-weight:600;display:inline-block;padding-bottom:.5rem}", "", {"version":3,"sources":["/vercel/path0/src/components/AddressSummary/scss/index.module.scss"],"names":[],"mappings":"AAA6C,iBAAiB,cAAc,gBAAgB,iBAAiB,CAAC,wBAAwB,gBAAgB,qBAAqB,oBAAoB,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.address-summary{color:#7d7d7d;line-height:1.5;font-size:.875rem}.address-summary strong{font-weight:600;display:inline-block;padding-bottom:.5rem}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
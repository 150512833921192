import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Form, Field } from "react-final-form";
import React, { useState } from "react";
import { useIntl } from "react-intl";

import { ErrorMessage, StripeInputElement } from "@components/atoms";
import { IFormError } from "@types";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Stripe credit card form.
 */
const StripeCreditCardForm: React.FC<IProps> = ({
  formRef,
  formId,
  errors = [],
  onSubmit,
}: IProps) => {
  const stripe = useStripe();
  const intl = useIntl();
  const elements = useElements();

  const [stripeErrors, setStripeErrors] = useState<IFormError[]>([]);

  const allErrors = [...errors, ...stripeErrors];

  return (
    <Form
      initialValues={null}
      onSubmit={async (values) => {
        return onSubmit(stripe, elements);
      }}
      render = {({
        handleSubmit,
        values,
        submitting,
        valid,
      }) => (
        <S.Form id={formId} ref={formRef} onSubmit={handleSubmit}>
          <S.Card data-test="stripeForm">
            <S.CardNumberField>
              <Field name="CardNumber">
                {({ input, meta }) => (
                  <StripeInputElement
                    type="CardNumber"
                    label={intl.formatMessage({ id: "Card number", defaultMessage: "Card number" })}
                    onChange={event => {
                      input.onChange(event);
                      setStripeErrors([]);
                    }}
                  />
                )}
              </Field>
            </S.CardNumberField>
            <S.CardExpiryField>
              <Field name="CardExpiry">
                {({ input, meta }) => (
                  <StripeInputElement
                    type="CardExpiry"
                    label={intl.formatMessage({ id: "Expiration date", defaultMessage: "Expiration date" })}
                    onChange={event => {
                      input.onChange(event);
                      setStripeErrors([]);
                    }}
                  />
                )}
              </Field>
            </S.CardExpiryField>
            <S.CardCvcField>
              <Field name="CardCvc">
                {({ input, meta }) => (
                  <StripeInputElement
                    type="CardCvc"
                    label={intl.formatMessage({ id: "CVC", defaultMessage: "CVC" })}
                    onChange={event => {
                      input.onChange(event);
                      setStripeErrors([]);
                    }}
                  />
                )}
              </Field>
            </S.CardCvcField>
          </S.Card>
          <ErrorMessage errors={allErrors} />
        </S.Form>
      )}
    />
  );
};

export { StripeCreditCardForm };

import gql from "graphql-tag";

export const getCollectionsQuery = gql`
  query GetCollections($channel: String, $filter: CollectionFilterInput) {
    collections(channel: $channel, first: 100, filter: $filter) {
      edges {
        node {
          name
          slug
          backgroundImage {
            url
            alt
          }
          metadata {
            key
            value
          }
        }
      }
    }
  }
`;
exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".carousel{margin:0px -10px}.carousel__control{padding:.2rem .5rem;background-color:#fff;box-shadow:0px 0px 10px 0px rgba(0,0,0,.25)}.carousel__control:hover{cursor:pointer}.carousel__control--right{margin-right:-1.3rem}@media(max-width: 1140px){.carousel__control--right{margin-right:0}}.carousel__control--right svg{transform:rotate(180deg);margin-top:.5rem}.carousel__control--left{margin-left:-1.3rem}@media(max-width: 1140px){.carousel__control--left{margin-left:0}}.carousel__control--left svg{margin-top:.4rem}.carousel .slider-slide{text-align:center;padding:10px}@media(max-width: 540px){.promotions .carousel.slider{margin-top:0px !important}}", "", {"version":3,"sources":["/vercel/path0/src/components/Carousel/scss/index.module.scss"],"names":[],"mappings":"AAA6C,UAAU,gBAAgB,CAAC,mBAAmB,oBAAoB,sBAAsB,2CAA2C,CAAC,yBAAyB,cAAc,CAAC,0BAA0B,oBAAoB,CAAC,0BAA0B,0BAA0B,cAAc,CAAC,CAAC,8BAA8B,yBAAyB,gBAAgB,CAAC,yBAAyB,mBAAmB,CAAC,0BAA0B,yBAAyB,aAAa,CAAC,CAAC,6BAA6B,gBAAgB,CAAC,wBAAwB,kBAAkB,YAAY,CAAC,yBAAyB,6BAA6B,yBAAyB,CAAC,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.carousel{margin:0px -10px}.carousel__control{padding:.2rem .5rem;background-color:#fff;box-shadow:0px 0px 10px 0px rgba(0,0,0,.25)}.carousel__control:hover{cursor:pointer}.carousel__control--right{margin-right:-1.3rem}@media(max-width: 1140px){.carousel__control--right{margin-right:0}}.carousel__control--right svg{transform:rotate(180deg);margin-top:.5rem}.carousel__control--left{margin-left:-1.3rem}@media(max-width: 1140px){.carousel__control--left{margin-left:0}}.carousel__control--left svg{margin-top:.4rem}.carousel .slider-slide{text-align:center;padding:10px}@media(max-width: 540px){.promotions .carousel.slider{margin-top:0px !important}}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import axios from 'axios';
import { Modal } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { Form, Field } from "react-final-form";

import { getSaleorApi } from "@utils/ssr";
import { subscribeNewsletterMutation } from "../Braze/queries";
import { Button, Input, Checkbox, ButtonLink, Radio } from "@components/atoms";

const NewsletterModal = ({ visible, closeModal }) => {

  const [hasError, setError] = useState(false);

  const signUp = async (email) => {
    try {
      const { apolloClient } = await getSaleorApi();
      const { data } = await apolloClient.mutate({
          mutation: subscribeNewsletterMutation,
          variables: {
              user_email: email,
          },
          fetchPolicy: 'no-cache',
      });
      closeModal('sent')
      setError(false)
    } catch (err) {
      setError(true)
    }
  }

  return (
    <Modal 
      open={visible === true}
      width={880}
      footer={false}
      onCancel={() => closeModal(false)}
      wrapClassName='newsletter-modal'
    >
      <h3><FormattedMessage id="The first drink is on us!" defaultMessage="The first drink is on us!" /></h3>
      <p><FormattedMessage id="First time ordering on Drinkies? Get a welcome voucher of RM 20 on your first order. We will send you a code by email. Make sure to enter it upon checkout:)" defaultMessage="First time ordering on Drinkies? Get a welcome voucher of RM 20 on your first order. We will send you a code by email. Make sure to enter it upon checkout:)" /></p>
      <Form
        initialValues={{ email: '' }}
        onSubmit={async (values) => {
          signUp(values.email)
        }}
        render = {({
          handleChange,
          handleSubmit,
          values,
          submitting,
          valid,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field name="email">
                {({ input, meta }) => (
                  <Input
                    label='Email'
                    type="email"
                    value={values.email}
                    onChange={(e) => {
                      input.onChange(e);
                    }}
                  />
                )}
              </Field>
              
              <Button
                testingContext="submit"
                type="submit"
                disabled={!values.email}
                color='gradient'
              >
                SUBMIT
              </Button>
              {hasError && <p className='error'>Something went wrong. Please try again later.</p>}
            </form>
          );
        }}
      />
    </Modal>
  )
}

export default NewsletterModal;
import moment from 'moment';
import gql from "graphql-tag";
import { Icon } from '@iconify/react';
import { Form } from "react-final-form";
import { Popover, DatePicker } from 'antd';
import { FormattedMessage } from "react-intl";
import React, { useEffect, useState } from 'react';

import './CheckoutTime.module.scss';
import { Button } from "@components/atoms";
import { getSaleorApi } from "@utils/ssr";

const CheckoutTime = ({ onSubmitSuccess, checkoutTimeFormId, checkoutTimeFormRef, delivery, deliveryState }) => {

  const [ open, setOpen ] = useState(null);
  const [ warehouserInfo, setWarehouseInfo ] = useState({
    openDay: 1,
    closeDay: 7,
  })

  useEffect(() => {
    (async function _() {
      if (delivery.warehouse_name) {
        const { apolloClient } = await getSaleorApi()
        const { data } = await apolloClient.query({
          query: GET_WAREHOUSE,
          variables: {
            warehouseName: delivery.warehouse_name,
          },
        })
        if (data?.warehouseExtensionByName) {
          setWarehouseInfo(data.warehouseExtensionByName)
        }
      }
    })();
  }, [delivery])

  const { deliveryDate, setDeliveryDate, deliveryTime, setDeliveryTime } = deliveryState


  const getDisabledDates = (date) => {

    const { openDay, closeDay } = warehouserInfo
    const available_from = moment().add(delivery.delivery_date_from_now, 'days').valueOf();
    const timestamp = date.clone().endOf('day').valueOf();
    const dayNumber = date.clone().isoWeekday()
    if (dayNumber < openDay || dayNumber > closeDay) {
      return true
    }
    return available_from > timestamp;
  };

  const handleSelect = (type) => {
    if (type === 'date') {
      setDeliveryDate({ ...deliveryDate, selected: deliveryDate.current });
    } else {
      let { value } = delivery.slots.find(slot => slot.text === deliveryTime.current);
      setDeliveryTime({ ...deliveryTime, selected: deliveryTime.current, value });
    }
    // Close popovers programatially
    const element = document.getElementsByClassName('picker-btn')[type === 'date' ? 0 : 1];
    const clickEvent = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false,
    });
    element.dispatchEvent(clickEvent);
    setOpen(false);
  };

  const datePopover = (
    <div id="date-popover">
      <DatePicker
        open
        dropdownClassName="calendar-dropdown"
        getPopupContainer={() => document.getElementById('date-popover')} 
        disabledDate={getDisabledDates}
        showToday={false}
        onChange={date => setDeliveryDate({ ...deliveryDate, current: date })}
        renderExtraFooter={() => (
          <div>
            <Button onClick={() => handleSelect('date')} disabled={!deliveryDate.current} color="secondary" size="sm">
              <FormattedMessage id="SELECT" defaultMessage="SELECT" />
            </Button>
          </div>
        )}
      />
    </div>
  )

  const timePopover = (
    <div id="time-popover">
      <div className="time-slots">
        {delivery.slots.map((slot, index) => (
          <div 
            key={index}
            className={`timeslot ${deliveryTime.current === slot.text ? 'active' : ''}`} 
            onClick={() => setDeliveryTime({ ...deliveryTime, current: slot.text })}
          >
            {slot.text}
          </div>
        ))}
      </div>
      <Button onClick={() => handleSelect('time')}  disabled={!deliveryTime.current} color="secondary" size="sm">
        <FormattedMessage id="SELECT" defaultMessage="SELECT" />
      </Button>
    </div>
  )

  return (
    <div className="delivery-time">
      <h3><FormattedMessage id="Delivery time" defaultMessage="Delivery time" /></h3>
      
      <Form
        onSubmit={async (values) => {
          return onSubmitSuccess(null, null, { deliveryDate, deliveryTime });
        }}
        render = {({
          handleSubmit,
          values,
        }) => {
          if (delivery.type === 'standard') {
            return (
              <form 
                onSubmit={handleSubmit}
                id={checkoutTimeFormId}
                ref={checkoutTimeFormRef}
              >
                <div>
                  <p><FormattedMessage id="Please expect a standard delivery time of up to 7 days." defaultMessage="Please expect a standard delivery time of up to 7 days." /><br /><FormattedMessage id="You will receive a tracking ID by email and SMS to follow your delivery." defaultMessage="You will receive a tracking ID by email and SMS to follow your delivery." /> </p>
                </div>
              </form>
            )
          }

          return (
            <form 
              onSubmit={handleSubmit}
              id={checkoutTimeFormId}
              ref={checkoutTimeFormRef}
            >
              <Popover 
                overlayClassName="custom-popover" 
                placement="bottom" 
                content={datePopover} 
                trigger="click"
                getPopupContainer={() => document.getElementsByClassName('delivery-time')[0]}
                onVisibleChange={visible => setOpen(visible ? 'date' : null)}
              >
                <div className={`picker-btn ${open === 'date' ? 'open' : ''}`}>
                  <div>
                    <label><FormattedMessage id="Delivery date" defaultMessage="Delivery date" /></label>
                    <p>{deliveryDate.selected.format('DD of MMMM')}</p>
                  </div>
                  <Icon icon="ant-design:calendar-outlined" width="22" height="22"/>
                </div>
              </Popover>

              <Popover 
                overlayClassName="custom-popover" 
                placement="bottom" 
                content={timePopover} 
                trigger="click"
                onVisibleChange={visible => setOpen(visible ? 'time' : null)}
              >
                <div className={`picker-btn ${open === 'time' ? 'open' : ''}`}>
                  <div>
                    <label><FormattedMessage id="Delivery time" defaultMessage="Delivery time" /></label>
                    <p>{deliveryTime.selected}</p>
                  </div>
                  <Icon icon="ant-design:clock-circle-outlined" width="22" height="22"/>
                </div>
              </Popover>
            </form>
          );
        }}
      />
    </div>
  )
};

export { CheckoutTime };

const GET_WAREHOUSE = gql`
  query GetWarehouse($warehouseName: String!) {
    warehouseExtensionByName(warehouseName: $warehouseName) {
      openDay
      closeDay
    }
  }
`


exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".otp-request-form{padding-top:50px}.otp-request-form .input{margin:1rem 0 2rem}.otp-request-form__button{text-align:center;margin:2rem}", "", {"version":3,"sources":["/vercel/path0/src/components/OtpRequestForm/scss/index.module.scss"],"names":[],"mappings":"AAA6C,kBAAkB,gBAAgB,CAAC,yBAAyB,kBAAkB,CAAC,0BAA0B,kBAAkB,WAAW,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.otp-request-form{padding-top:50px}.otp-request-form .input{margin:1rem 0 2rem}.otp-request-form__button{text-align:center;margin:2rem}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import dynamic from "next/dynamic";

import { Checkbox } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";

import * as S from "./styles";
import { IProps } from "./types";

const AddressForm = dynamic(
  () => import('../AddressForm').then((mod) => mod.AddressForm),
);
const AddressGridSelector = dynamic(
  () => import('../AddressGridSelector').then((mod) => mod.AddressGridSelector),
);

/**
 * Address form used in checkout.
 */
const CheckoutAddress: React.FC<IProps> = ({
  checkoutShippingAddress,
  checkoutBillingAddress,
  billingAsShippingAddress,
  email,
  selectedUserShippingAddressId,
  selectedUserBillingAddressId,
  userAddresses,
  countries,
  userId,
  shippingFormId,
  shippingFormRef,
  billingFormId,
  billingFormRef,
  shippingAddressRequired,
  setShippingAddress,
  setBillingAddress,
  setBillingAsShippingAddress,
  setSaveShippingAddress,
  setSaveBillingAddress,
  shippingErrors,
  billingErrors,
  newAddressFormId,
  orderNotes,
  saveShippingAddress,
  saveBillingAddress,
  savedBillingSelected,
  savedShippingSelected,
  onFieldChange,
}: IProps) => {

  // const [ isAddressChanged, setAddressCHanged ] = useState(true)
  const [selectedAddress, setSelectedAddress] = useState(undefined)

  const [selectedBillingAddress, setSelectedBillingAddress] = useState(undefined)

  useEffect(() => {
    if (checkoutShippingAddress) {
      setSelectedAddress({
        ...checkoutShippingAddress,
        email,
      })
    }

  }, [checkoutShippingAddress, email])

  useEffect(() => {
    if (checkoutBillingAddress) {
      setSelectedBillingAddress(checkoutBillingAddress)
    }
  }, [checkoutBillingAddress])


  return (
    <S.Wrapper>
      {shippingAddressRequired && (
        <>
          <section>
            <S.Title data-test="checkoutPageSubtitle">
              <FormattedMessage id="Shipping Address" {...checkoutMessages.shippingAddress} />
            </S.Title>
            <AddressForm
              testingContext="shippingAddressForm"
              formId={shippingFormId}
              formRef={shippingFormRef}
              countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
              address={selectedAddress}
              handleSubmit={address => {
                setShippingAddress(address, address?.email)
              }}
              includeEmail
              errors={shippingErrors}
              // onChangeAddress={() => setAddressCHanged(true)}
              includeNotes
              orderNotes={orderNotes}
              setSaveShippingAddress={setSaveShippingAddress}
              saveShippingAddress={saveShippingAddress}
              savedShippingSelected={savedShippingSelected}
              onFieldChange={address => {
                onFieldChange(address, false)
              }}
            />
            {userAddresses && (
              <>
                <S.Title data-test="checkoutPageSubtitle">Saved Addresses</S.Title>
                <AddressGridSelector
                  testingContext="shipping"
                  formId={shippingFormId}
                  formRef={null}
                  addresses={userAddresses}
                  selectedAddressId={selectedUserShippingAddressId}
                  countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                  userId={userId}
                  errors={shippingErrors}
                  onSelect={(address, id) => {
                    setShippingAddress(address, undefined, id)
                  }}
                  onSelectSavedAddress={(address, id) => {
                    setShippingAddress(address, undefined, id, true)
                  }}
                  newAddressFormId={newAddressFormId}
                />
              </>
            )}
          </section>
          <S.Divider />
        </>
      )}
      <section>
        <S.Title data-test="checkoutPageSubtitle">
          <FormattedMessage id="BILLING ADDRESS" {...checkoutMessages.billingAddress} />
        </S.Title>
        {shippingAddressRequired && (
          <Checkbox
            data-test="checkoutAddressBillingAsShippingCheckbox"
            name="billing-same-as-shipping"
            checked={billingAsShippingAddress}
            onChange={() =>
              setBillingAsShippingAddress(!billingAsShippingAddress)
            }
          >
            <FormattedMessage id="Same as shipping address" defaultMessage="Same as shipping address" />
          </Checkbox>
        )}
        {!billingAsShippingAddress && (
          <>
            {shippingAddressRequired && <S.Divider />}
            {userAddresses ? (
              <>
                <AddressForm
                  testingContext="billingAddressForm"
                  formId={billingFormId}
                  formRef={billingFormRef}
                  countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                  address={selectedBillingAddress}
                  handleSubmit={address =>
                    setBillingAddress(address, undefined)
                  }
                  includeEmail={!shippingAddressRequired}
                  errors={billingErrors}
                  setSaveBillingAddress={setSaveBillingAddress}
                  saveBillingAddress={saveBillingAddress}
                  savedBillingSelected={savedBillingSelected}
                  onFieldChange={address => {
                    onFieldChange(address, true)
                  }
                  }
                />
                <AddressGridSelector
                  testingContext="billing"
                  formId={billingFormId}
                  formRef={null}
                  addresses={userAddresses}
                  selectedAddressId={selectedUserBillingAddressId}
                  countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                  userId={userId}
                  errors={billingErrors}
                  onSelect={(address, id) =>
                    setBillingAddress(address, undefined, id)
                  }
                  onSelectSavedAddress={(address, id) =>
                    setBillingAddress(address, undefined, id, true)
                  }
                  newAddressFormId={newAddressFormId}
                />
              </>
            ) : (
              <AddressForm
                testingContext="billingAddressForm"
                formId={billingFormId}
                formRef={billingFormRef}
                countriesOptions={countries?.filter(filterNotEmptyArrayItems)}
                address={selectedBillingAddress}
                handleSubmit={address =>
                  setBillingAddress(address, address?.email)
                }
                includeEmail={!shippingAddressRequired}
                errors={billingErrors}
                onFieldChange={address => {
                  onFieldChange(address, true)
                  // setBillingAddress(undefined, undefined, undefined, false, true)
                }}
              />
            )}
          </>
        )}
      </section>
    </S.Wrapper>
  );
};

export { CheckoutAddress };

import { media, styled } from "@styles";

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-gap: 2rem;
  margin-bottom: 20px;

  ${media.largeScreen`
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1.5rem;
  `}

  ${media.smallScreen`
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  `}
`;

export const Loader = styled.div`
  text-align: center;
`;

import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Icon } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartSummaryRow } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import { DeliverySlotContext } from '../../contexts/deliverySlotsContext'

import * as S from "./styles";
import { CartSummaryProps, ICostLine, ICosts } from "./types";

const CostLine = ({
  name,
  cost,
  last = false,
  negative = false,
}: ICostLine) => (
  <S.CostLine last={last}>
    <span>{name}</span>
    <span data-test={`cartSummaryCost${name.replace(/\s/g, "")}`}>
      {negative && "- "}
      <TaxedMoney taxedMoney={cost} />
    </span>
  </S.CostLine>
);

const Costs = ({ subtotal, promoCode, shipping, total }: ICosts) => {

  const intl = useIntl();

  return (
    <S.Costs>
      {subtotal && (
        <CostLine
          name={intl.formatMessage(commonMessages.subtotal)}
          cost={subtotal}
        />
      )}
      {shipping && (
        <CostLine
          name={intl.formatMessage(commonMessages.shipping)}
          cost={shipping}
        />
      )}
      {promoCode && promoCode.gross.amount > 0 && (
        <CostLine
          name={intl.formatMessage(commonMessages.promoCode)}
          cost={promoCode}
          negative
        />
      )}
      {total && (
        <CostLine
          name={intl.formatMessage(commonMessages.total)}
          cost={total}
          last
        />
      )}
    </S.Costs>
  );
};

/**
 * Cart summary displayed in checkout page
 */
const CartSummary: React.FC<CartSummaryProps> = ({
  subtotal,
  total,
  shipping,
  promoCode,
  products,
}) => {
  const [mobileCartOpened, setMobileCartOpened] = useState(false);
  const { chilledDelivery } = useContext(DeliverySlotContext);
  const { discreetPackaging } = useContext(DeliverySlotContext);


  return (
    <S.Wrapper mobileCartOpened={mobileCartOpened}>
      <S.Title
        data-test="cartSummaryTitle"
        onClick={() => setMobileCartOpened(!mobileCartOpened)}
      >
        <FormattedMessage id="Cart Summary" defaultMessage="Cart Summary" />
        <S.ArrowUp mobileCartOpened={mobileCartOpened}>
          <Icon name="arrow_up" size={24} />
        </S.ArrowUp>
      </S.Title>
      <S.Content>
        <S.HR />
        <S.CartSummaryProductList>
          {products?.map((product, index) => (
            <div key={product.sku}>
              <S.ProductLine>
                <CartSummaryRow
                  index={index}
                  sku={product.sku}
                  quantity={product.quantity}
                  name={product.name}
                  price={product.price}
                  thumbnail={product.thumbnail}
                />
              </S.ProductLine>
              <S.HR />
            </div>
          ))}
        </S.CartSummaryProductList>
        {chilledDelivery && (
          <S.ChilledDelivery>
            <p style={{ marginBottom: "1em" }}>
              <FormattedMessage id="You have opted for your drinks (beer and white wine only) to be delivered chilled" defaultMessage="You have opted for your drinks (beer and white wine only) to be delivered chilled" /> <span role="img" aria-label="emoji" className="icon">❄️</span>.
            </p>
          </S.ChilledDelivery>
        )}
        {discreetPackaging && (
          <S.DiscreetPackaging>
            <p style={{ marginBottom: "1em" }}>
              <FormattedMessage id="You have opted for discreet packaging" defaultMessage="You have opted for discreet packaging" /> <span role="img" aria-label="emoji" className="icon">📦</span>.
            </p>
          </S.DiscreetPackaging>
        )}
        <Costs
          subtotal={subtotal}
          total={total}
          shipping={shipping}
          promoCode={promoCode}
        />
      </S.Content>
    </S.Wrapper>
  );
};

export { CartSummary };
export type { CartSummaryProps };

import { Form } from "react-final-form";
import React, { useState, useEffect } from "react";
import { useAuth } from "@saleor/sdk/lib/react/hooks";

import { Button } from "..";
import "./scss/index.module.scss";
import { getSaleorApi } from "@utils/ssr";
import {  Input } from "@components/atoms";
import { otpRequestMutation, verifyOtpMutation, updateAccountMutation, getUserDetail } from "./queries";

const OtpRequestForm = ({ hide }) => {
  const {  user } = useAuth();
  const [initialPhone, setInitialPhone] = useState(null)
  const [phoneSubmitted, setPhoneSubmitted] = useState(null)
  const [validationSucceed, setValidationSucceed] = useState(false)
  const [otpCode, setOtpCode] = useState("")
  const [errorText, setErrorText] = useState(null)

  const DELAY_TIMER = 60;
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!timeLeft) {
      return;
    }

    const intervalId = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setTimeLeft(timeLeft => timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
      getUserPhone()
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
      let code = otpCode
    if (code && code?.length === 6) {
      validateOtp(code)
    }
  }, [otpCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateOtp = async (code) => {
    setErrorText(null)
    const { apolloClient } = await getSaleorApi()

    try {
      let variables = {
        phone: phoneSubmitted?.replace('+', '').toString() || '',
        code: code.toString(),
      }
      // console.log("verifyOtpMutation var :: ", variables)
      await apolloClient.mutate({
        mutation: verifyOtpMutation,
        variables,
      })
      setValidationSucceed(true)

    } catch (e) {
      // console.log("verifyOtpMutation error :: ", JSON.stringify(e))
      let message = "It is not a valid code. Please correct or request a new code.";
      setErrorText(message)
    }
  }

  const requestOtp = async (phone) => {
    let formattedPhone = phone.replace('-', '').replace(' ', '')
    
    try {
      const { apolloClient } = await getSaleorApi()
      const { data } = await apolloClient.mutate({
        mutation: updateAccountMutation,
        variables: {
          input:{ phone: formattedPhone },
        },
      })

      if (data.accountUpdate.errors.length === 0) {
        try {
          await apolloClient.mutate({
            mutation: otpRequestMutation,
            variables: {
              phone: formattedPhone.replace('+', ''),
            },
          })
          setPhoneSubmitted(formattedPhone.replace('+', ''))
          setTimeLeft(DELAY_TIMER);
          } catch (e) {
            setPhoneSubmitted(formattedPhone.replace('+', ''))
          }
      } else {
        // updateAccountMutation error
      }
      } catch (e) {
        // updateAccountMutation error
      }

  }

  const getUserPhone = async () => {
    try {
    const { apolloClient } = await getSaleorApi();
    const { data } = await apolloClient.query({
      query: getUserDetail,
    });
    
    let phone = data.me.phone || "+60"
    if (initialPhone === null) {
      setInitialPhone(phone)
    }
    return phone
  } catch (e) {
    if (initialPhone === null) {
      setInitialPhone("+60")
    }
    return "+60"
  }
  }

  return (
    <div className="otp-request-form">
      <p>To ensure a smooth service, we kindly ask you to verify your phone number.</p>
      {phoneSubmitted && (
        <p>Code sent to: +{phoneSubmitted.replace('+', '')}</p>
      )}

      {!phoneSubmitted ? 
        <div>
          <Input
            name="phone"
            // autoComplete="email"
            label="Phone"
            type="phone"
            value={initialPhone}
            required
            onChange={(val) => setInitialPhone(val.target.value)}
          />
          <Form
            initialValues={{ phone: '+60' }}
            onSubmit={async (values) => {
              // requestOtp(values.phone);
            }}
            render = {({
              handleSubmit,
              values,
              submitting,
              valid,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="otp-request-form__button">
                    <Button
                      testingContext="submit"
                      type="submit"
                      disabled={!values.phone}
                      color='gradient'
                      onClick={() => requestOtp(`${initialPhone}`)}
                    >
                      NEXT
                    </Button>
                  </div>
                </form>
              );
            }}
          />
        </div>
      :
      (
        <div>
          <Input
            error={errorText}
            name="code"
            label="Code"
            required
            value={otpCode}
            onChange={(val)=>setOtpCode(val.target.value)}
          />
          {errorText && <p style={{ color: '#C22D74' }}>{errorText}</p>}
          <p />
          {!validationSucceed && <p>{timeLeft && timeLeft > 0 ? <p style={{ opacity: 0.5 }}>New code in {timeLeft}s</p> : <p style={{ color: '#00A3FF' }} onClick={() => requestOtp(`+${phoneSubmitted}`)}>Didn’t receive any code? Resend code.</p>}</p>}
          {validationSucceed && <p>OTP validation succeed</p>}
          {validationSucceed && <p onClick={() => hide()} style={{ color: '#00A3FF' }}>Close</p>}

        <p/><p/>
        {!validationSucceed && <p onClick={() => setPhoneSubmitted(null)} style={{ color: '#00A3FF' }}>Have an account but not your number anymore? Update it here.</p>}
        </div>
      )
      }
    </div>
  );
};

export default OtpRequestForm;

// @ts-nocheck
import { Form, Field } from "react-final-form";
import React, { useEffect, useState } from "react";

import { IAddress } from "@types";
import { pick } from "@utils/misc";

import { AddressFormContent } from "./AddressFormContent";
import { IProps } from "./types";

const ADDRESS_FIELDS = [
  "city",
  "companyName",
  "countryArea",
  "firstName",
  "lastName",
  "country",
  "phone",
  "postalCode",
  "streetAddress1",
  "streetAddress2",
  "email",
];

let ADDRESS_REQUIRED_FIELDS = [
  "city",
  "countryArea",
  "phone",
  "postalCode",
  "streetAddress1",
 "streetAddress2",
  "email",
];

export const AddressForm: React.FC<IProps> = ({
  address,
  handleSubmit,
  formId,
  defaultValue,
  countriesOptions,
  onChangeAddress,
  orderNotes,
  setSaveShippingAddress,
  setSaveBillingAddress,
  onFieldChange,
  saveShippingAddress,
  saveBillingAddress,
  savedShippingSelected,
  savedBillingSelected,
  ...props
}: IProps) => {

  let addressWithPickedFields: Partial<IAddress> = {};
  const [ touchedFields, setTouchedFields ] = useState({});

  useEffect(() => {
    if (onChangeAddress && Object.keys(touchedFields).length) {
      onChangeAddress();
    }
  }, [touchedFields]); // eslint-disable-line react-hooks/exhaustive-deps

  // initialized fields to avoid error
  for (let x = 0 ; x < ADDRESS_FIELDS.length; x++) {
    addressWithPickedFields[ADDRESS_FIELDS[x]] = '';
  }

  if (address) {
    addressWithPickedFields = pick(address, ADDRESS_FIELDS);
  } else {
    if (ADDRESS_REQUIRED_FIELDS.indexOf('email') >= 0) {
      ADDRESS_REQUIRED_FIELDS.splice(ADDRESS_REQUIRED_FIELDS.indexOf('email'), 1);
    }
    delete addressWithPickedFields.email;
  }

  if (defaultValue) {
    addressWithPickedFields.country = defaultValue;
  }

  return (
    <Form
      initialValues={{ ...addressWithPickedFields, orderNotes }}
      keepDirtyOnReinitialize
      onSubmit={(values) => {
        return new Promise(resolve => {
          handleSubmit(values)
          resolve()
        });
      }}
      render = {({
        // eslint-disable-next-line @typescript-eslint/no-shadow
        handleSubmit,
        values,
        form,
        errors,
        touched,
      }) => {
        return (
          <AddressFormContent
            {...{
              touched,
              setTouchedFields,
              countriesOptions,
              defaultValue,
              formId,
              errors,
              handleSubmit,
              form,
              values,
              setSaveShippingAddress,
              saveShippingAddress,
              setSaveBillingAddress,
              saveBillingAddress,
              savedShippingSelected,
              savedBillingSelected,
            }}
            {...props}
          />
        );
      }}
    />
  );
};

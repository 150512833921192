exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".password-reset-form .input{margin:1rem 0 2rem}.password-reset-form__button{text-align:center;margin:2rem 0 2rem 2rem}", "", {"version":3,"sources":["/vercel/path0/src/components/PasswordResetRequestForm/scss/index.module.scss"],"names":[],"mappings":"AAA6C,4BAA4B,kBAAkB,CAAC,6BAA6B,kBAAkB,uBAAuB,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.password-reset-form .input{margin:1rem 0 2rem}.password-reset-form__button{text-align:center;margin:2rem 0 2rem 2rem}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import { useCart } from "@saleor/sdk/lib/react/hooks";
import * as React from "react";

const getBrand = (attributes) => {
  return attributes.reduce((brandValue, attr) => {
    if (attr.attribute.name.toLowerCase() === 'brand') {
      brandValue = attr.values[0].name;
    }
    return brandValue;
  }, '');
}

export const pushProductDetail = (product) => {  
  if (product != null && typeof window !== 'undefined') {
    const brand = getBrand(product.attributes)

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const { dataLayer } = window;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: 'details',
      ecommerce: {
        detail: {
          products: [{
            name: product.name,
            id: product.variants[0].id,
            price: product.pricing.priceRange.start.gross.amount?.toFixed(2),
            brand,
            category: product.category.name,
            variant: product.variants[0].sku,
          }],
        },
      },
    });
  }
};

export const pushCartUpdate = (products, event) => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const { dataLayer } = window;
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: event === 'add' ? 'addToCart' : 'removedFromCart',
      ecommerce: {
        currencyCode: 'MYR',
        [event]: {
          products,
        },
      },
    });
  }
};

export const pushPurchase = (id, cart) => {
  const products = cart.items?.map(({ quantity, productName, variant }) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { id, sku, product, pricing: { price: { net: { amount } } } } = variant;
    const brand = getBrand(product.attributes)
    return {
      name: productName,
      id,
      price: amount.toFixed(2),
      quantity,
      variant: sku,
      category: product.category.name,
      brand,
    };
  });

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  const { dataLayer } = window;
  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id,
          affiliation: 'default-channel',
          revenue: cart?.totalPrice?.toFixed(2),
          tax: '0.00',
          shipping: cart?.shippingPrice?.toFixed(2),
        },
        products,
      },
    },
  });
}

const getDifference = (array1, array2) => {
  return array1.filter(object1 => {
    return !array2.some(object2 => {
      return object1.id === object2.id && object1.quantity <= object2.quantity;
    });
  });
}

export const pushCheckout = (step, items = null, option = null) => {
  const products = items && items.map(({ quantity, variant }) => {
    const { id, product, pricing: { price: { net: { amount } } } } = variant;
    return {
      id,
      name: product.name,
      price: amount.toFixed(2),
      quantity,
      category: product.category?.name || "",
    };
  }) || null;

  const pushPayload = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step, ...(option && { option }) },
        ...(step === 1 && { products }),
      },
    },
  }
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  const { dataLayer } = window;
  dataLayer.push({ ecommerce: null });
  dataLayer.push(pushPayload);
}

const Analytics: React.FC<{}> = () => {
  const { items } = useCart();
  const [initialized, setInitialized] = React.useState(false);
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {    
    if (Array.isArray(items)) {
      updateProducts(items);
    }
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const updateProducts = items => {
    // making sure the productdata is loaded for all
    // cart items
    const isReady = items.reduce((allSet, { totalPrice }) => {
      if (allSet === false) {
        return allSet;
      }
      return totalPrice != null;
    }, true);
    if (!isReady) {
      return;
    }
    
    // create products list
    const list = items.map(({ quantity, variant }) => {
      const { id, product, name, sku, pricing: { price: { net: { amount } } } } = variant;
      
      return {
        name: product?.name || name,
        category: '',
        brand: '',
        id,
        price: amount.toFixed(2),
        quantity,
        variant: sku,
      };
    });

    if (!initialized) {
      setInitialized(true);
    } else {
      // removed from cart or decreased quantity
      const removed = getDifference(products, list);
      if (removed.length > 0) {
        // console.log('removed', removed.map(({name}) => name).join(','));
        pushCartUpdate(removed, 'remove');
      }

      // added new product to cart or increased quantity
      const added = getDifference(list,  products);
      if (added.length > 0) {
        // console.log('added', added.map(({name}) => name).join(',')); 
        pushCartUpdate(added, 'add');
      }
    }
    setProducts(list);
  }  

  return null;
};

export default Analytics;

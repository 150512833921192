import Link from "next/link";
import React, { useContext } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useCheckout } from "@saleor/sdk/lib/react/hooks";
import { checkoutMessages } from "@temp/intl";
import { ICheckoutStep } from "@types";
import { DeliverySlotContext } from "../../contexts";
import * as S from "./styles";
import { IProps } from "./types";

const activeDot = (
  <S.ActiveDot>
    <S.Dot done />
  </S.ActiveDot>
);

const doneDot = <S.Dot done />;

const inactiveDot = <S.Dot />;

const generateDot = (stepIndex: number, currentActiveStep: number) => {
  if (stepIndex < currentActiveStep) {
    return doneDot;
  }
  if (stepIndex === currentActiveStep) {
    return activeDot;
  }
  if (stepIndex > currentActiveStep) {
    return inactiveDot;
  }
};

const generateLabel = (
  stepIndex: number,
  name: string,
  numberOfSteps: number,
) => {
  if (stepIndex === 0) {
    return <S.LeftLabel>{name}</S.LeftLabel>;
  }
  if (stepIndex === numberOfSteps - 1) {
    return <S.RightLabel>{name}</S.RightLabel>;
  }
  return <S.Label>{name}</S.Label>;
};

const generateProgressBar = (
  index: number,
  currentActive: number,
  numberOfSteps: number,
) => {
  if (index !== numberOfSteps - 1) {
    return <S.ProgressBar done={currentActive > index} />;
  }
};


const checkAllowed = (name, checkoutAPI, currentDeliverySlot) => {
  let hasAddress = !!checkoutAPI?.checkout?.shippingAddress
  let hasTime = !!currentDeliverySlot
  let hasPayment = !!checkoutAPI?.payment?.id

  switch (name) {
    case "Address":
      return true
    case "Time":
      return hasAddress
    case "Payment":
      return hasAddress && hasTime
    case "Review":
      return hasAddress && hasTime && hasPayment
    default: 
      return true;
  }
}

const generateSteps = (
  steps: ICheckoutStep[],
  currentActive: number,
  intl: IntlShape,
  checkoutAPI: any,
  currentDeliverySlot: any,
) => {
  return steps?.map((step, index) => {
    let { name } = step;
    let isAllowed = checkAllowed(name, checkoutAPI, currentDeliverySlot)
    /* eslint-disable default-case */
    switch (step.name) {
      case "Address":
        name = intl.formatMessage(checkoutMessages.stepNameAddress);
        break;
      case "Time":
        name = intl.formatMessage(checkoutMessages.stepNameDelivery);
        break;
      case "Payment":
        name = intl.formatMessage(checkoutMessages.stepNamePayment);
        break;
      case "Review":
        name = intl.formatMessage(checkoutMessages.stepNameReview);
        break;
    }
    return (
      <S.Step key={step.index}>
        <Link href={step.link}>
          <a className={isAllowed ? '' : 'disabled'}>
            {generateDot(index, currentActive)}
            {generateLabel(index, name, steps.length)}
          </a>
        </Link>
        {generateProgressBar(index, currentActive, steps.length)}
      </S.Step>
    );
  });
};

/**
 * Progress bar showing current step of checkout process.
 */
const CheckoutProgressBar: React.FC<IProps> = ({
  steps,
  activeStep,
}: IProps) => {
  const intl = useIntl();
  const checkoutAPI = useCheckout();
  const { currentDeliverySlot } = useContext(DeliverySlotContext)

  return <S.Wrapper>{generateSteps(steps, activeStep, intl, checkoutAPI, currentDeliverySlot)}</S.Wrapper>;
};

export { CheckoutProgressBar };
import { defineMessages, IntlShape } from "react-intl";

export const commonMessages = defineMessages({
  dob: {
    defaultMessage: "Date of birth",
  },
  gender: {
    defaultMessage: "Gender",
  },
  search: {
    defaultMessage: "search",
  },
  outOfStock: {
    defaultMessage: "Out of stock",
  },
  lowStock: {
    defaultMessage: "Low stock",
  },
  noItemsAvailable: {
    defaultMessage: "No items available",
  },
  noPurchaseAvailable: {
    defaultMessage: "Not available for purchase",
  },
  purchaseAvailableOn: {
    defaultMessage: `Will become available for purchase on {date} at {time}`,
  },
  youMightLike: {
    defaultMessage: "You might like",
  },
  choosePaymentMethod: {
    defaultMessage: "Please choose payment method.",
  },
  provideEmailAddress: {
    defaultMessage: "Please fill in your email.",
  },
  provideFirstName: {
    defaultMessage: "Please fill in your first name.",
  },
  provideLastName: {
    defaultMessage: "Please fill in your last name.",
  },
  providePhone: {
    defaultMessage: "Please fill in your phone number.",
  },
  provideAddress: {
    defaultMessage: "Please fill in your address.",
  },
  provideUnitNumber: {
    defaultMessage: "Please fill in your unit number.",
  },
  provideCity: {
    defaultMessage: "Please fill in your city.",
  },
  provideState: {
    defaultMessage: "Please fill in your state.",
  },
  providePostalCode: {
    defaultMessage: "Please fill in your postal code.",
  },
  provideDob: {
    defaultMessage: "Please fill in your date of birth.",
  },
  provideGender: {
    defaultMessage: "Please fill in your gender.",
  },
  account: {
    id: "Account",
  },
  myAccount: {
    defaultMessage: "My Account",
  },
  orderHistory: {
    defaultMessage: "Order history",
  },
  addressBook: {
    defaultMessage: "Address book",
  },
  logOut: {
    defaultMessage: "Log Out",
  },
  firstName: {
    defaultMessage: "First Name",
  },
  lastName: {
    defaultMessage: "Last Name",
  },
  password: {
    defaultMessage: "Password",
  },
  quantity: {
    defaultMessage: "Quantity",
  },
  sku: {
    defaultMessage: "SKU",
  },
  maxQtyIs: {
    defaultMessage: "Maximum quantity is {maxQuantity}",
  },
  subtotal: {
    defaultMessage: "Subtotal",
  },
  shipping: {
    defaultMessage: "Shipping",
  },
  promoCode: {
    defaultMessage: "Promo code",
  },
  total: {
    defaultMessage: "Total",
  },
  totalPrice: {
    defaultMessage: "Total Price",
  },
  checkout: {
    defaultMessage: "Checkout",
  },
  eMail: {
    defaultMessage: "Email Address",
  },
  shortEmail: {
    defaultMessage: "Email",
  },
  loading: {
    defaultMessage: "Loading",
  },
  products: {
    defaultMessage: "Products",
  },
  price: {
    defaultMessage: "Price",
  },
  variant: {
    defaultMessage: "Variant",
  },
  phone: {
    defaultMessage: "Phone",
  },
  phoneNumber: {
    defaultMessage: "Phone number: {phone}",
  },
  showEmail: {
    defaultMessage: "Email: {email}",
  },
  save: {
    defaultMessage: "Save",
  },
  add: {
    defaultMessage: "Add",
  },
  filterHeader: {
    defaultMessage: "FILTERS",
  },
  clearFilterHeader: {
    defaultMessage: "CLEAR FILTERS",
  },
  status: {
    defaultMessage: "Status",
  },
  cancel: {
    defaultMessage: "Cancel",
  },
  home: {
    defaultMessage: "Home",
  },
});

export const checkoutMessages = defineMessages({
  stepNameAddress: {
    defaultMessage: "Address",
  },
  deliveryDate: {
    defaultMessage: "Delivery Date",
  },
  deliveryTime: {
    defaultMessage: "Delivery Time",
  },
  stepNameDelivery: {
    defaultMessage: "Time of Delivery",
  },
  stepNamePayment: {
    defaultMessage: "Payment",
  },
  stepNameReview: {
    defaultMessage: "Review",
  },
  addressNextActionName: {
    defaultMessage: "Continue to Delivery Time",
  },
  shippingNextActionName: {
    defaultMessage: "Continue to Payment",
  },
  paymentNextActionName: {
    defaultMessage: "Continue to Review",
  },
  reviewNextActionName: {
    defaultMessage: "Place order",
  },
  addNewAddress: {
    defaultMessage: "Add new address",
  },
  shippingMethod: {
    defaultMessage: "SHIPPING METHOD",
  },
  billingAddress: {
    defaultMessage: "BILLING ADDRESS",
  },
  paymentMethod: {
    defaultMessage: "PAYMENT METHOD",
  },
  reviewOrder: {
    defaultMessage: "REVIEW ORDER",
  },
  shippingAddress: {
    defaultMessage: "Shipping Address",
  },
  continueShopping: {
    defaultMessage: "CONTINUE SHOPPING",
  },
  ebanking:{
    defaultMessage: "Online Banking",
  },
  grabpay:{
    defaultMessage: "Grab Pay",
  },
  creditcard:{
    defaultMessage: "Credit Card",
  },
});

export const prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    defaultMessage: "Clear...",
  },
  sortOptionsPrice: {
    defaultMessage: "Price Low-High",
  },
  sortOptionsPriceDsc: {
    defaultMessage: "Price High-Low",
  },
  sortOptionsName: {
    defaultMessage: "Name Increasing",
  },
  sortOptionsNameDsc: {
    defaultMessage: "Name Decreasing",
  },
  sortOptionsUpdatedAt: {
    defaultMessage: "Last updated Ascending",
  },
  sortOptionsUpdatedAtDsc: {
    defaultMessage: "Last updated Descending",
  },
});

export const paymentStatusMessages = defineMessages({
  notCharged: {
    defaultMessage: "Not charged",
  },
  partiallyCharged: {
    defaultMessage: "Partially charged",
  },
  fullyCharged: {
    defaultMessage: "Fully charged",
  },
  partiallyRefunded: {
    defaultMessage: "Partially refunded",
  },
  fullyRefunded: {
    defaultMessage: "Fully refunded",
  },
});

export const paymentErrorMessages = defineMessages({
  paymentNoConfirmationData: {
    defaultMessage:
      "Payment needs confirmation but data required for confirmation not received from the server.",
    description: "payment gateway error",
  },
  paymentMalformedConfirmationData: {
    defaultMessage:
      "Payment needs confirmation but data required for confirmation received from the server is malformed.",
    description: "payment gateway error",
  },
  cannotHandlePaymentConfirmation: {
    defaultMessage:
      "Payment gateway did not provide payment confirmation handler.",
    description: "payment gateway error",
  },
});

export const orderStatusMessages = defineMessages({
  draft: {
    defaultMessage: "Draft",
  },
  unfulfilled: {
    defaultMessage: "Unfulfilled",
  },
  partiallyFulfilled: {
    defaultMessage: "Partially fulfilled",
  },
  fulfilled: {
    defaultMessage: "Fulfilled",
  },
  canceled: {
    defaultMessage: "Canceled",
  },
});

export function translatePaymentStatus(
  status: string,
  intl: IntlShape,
): string {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);
    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);
    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);
    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);
    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);
    default:
      return status;
  }
}

export function translateOrderStatus(status: string, intl: IntlShape): string {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);
    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);
    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);
    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);
    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);
    default:
      return status;
  }
}

import React from "react";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";

import { Loader } from "@temp/components";
import { Button } from "@components/atoms";
import { SavedLocationContext } from '../../contexts';

export interface IAddToCartButton {
  disabled: boolean;
  onSubmit: () => void;
  loading: boolean;
  buttonText: string;
  buyNowButton?: boolean;
}

export const AddToCartButton: React.FC<IAddToCartButton> = ({
  onSubmit,
  disabled,
  loading,
  buttonText,
  buyNowButton,
}) => {

  const { validateAddr, setPromptInput } = React.useContext(SavedLocationContext);

  return (
    <Button
      fullWidth
      testingContext="addProductToCartButton"
      onClick={() => {
        if (validateAddr) {
          return setPromptInput(true);
        }
        return onSubmit();
      }}
      color="gradient"
      disabled={disabled || loading}
      className={buyNowButton ? `buy-now-button ${isMobile && 'rounded-12'}` : `add-cart-button ${isMobile && 'rounded-12'}`}
    >
      {loading
        ? <Loader circle height={25} color="#ffffff" />
        : <FormattedMessage id={buttonText} defaultMessage={buttonText} />
      }
    </Button>
  );
};
AddToCartButton.displayName = "AddToCartButton";
export default AddToCartButton;

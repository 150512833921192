exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".login-form .input{margin-bottom:1rem}.login-form .warning{border:1px solid rgba(33,17,94,.6);display:flex;margin-top:20px;align-items:center}.login-form .warning p{padding:12px;margin:0px}.login-form .warning a{margin-top:-24px;color:#79ddc4;display:block;font-weight:bold}.login-form__button{margin-top:2rem;padding:0 1.5rem;text-align:center}.login-form .social-login{display:grid;width:100%;margin-top:40px}.login-form .social-login button{width:100% !important;margin:0 auto !important;margin-bottom:10px !important}", "", {"version":3,"sources":["/vercel/path0/src/components/LoginForm/scss/index.module.scss"],"names":[],"mappings":"AAA6C,mBAAmB,kBAAkB,CAAC,qBAAqB,mCAAmC,aAAa,gBAAgB,kBAAkB,CAAC,uBAAuB,aAAa,UAAU,CAAC,uBAAuB,iBAAiB,cAAc,cAAc,gBAAgB,CAAC,oBAAoB,gBAAgB,iBAAiB,iBAAiB,CAAC,0BAA0B,aAAa,WAAW,eAAe,CAAC,iCAAiC,sBAAsB,yBAAyB,6BAA6B,CAAC","file":"index.module.scss","sourcesContent":[":export{mediumScreen:992px;smallScreen:540px}.login-form .input{margin-bottom:1rem}.login-form .warning{border:1px solid rgba(33,17,94,.6);display:flex;margin-top:20px;align-items:center}.login-form .warning p{padding:12px;margin:0px}.login-form .warning a{margin-top:-24px;color:#79ddc4;display:block;font-weight:bold}.login-form__button{margin-top:2rem;padding:0 1.5rem;text-align:center}.login-form .social-login{display:grid;width:100%;margin-top:40px}.login-form .social-login button{width:100% !important;margin:0 auto !important;margin-bottom:10px !important}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"mediumScreen": "992px",
	"smallScreen": "540px"
};
import gql from "graphql-tag";

export const otpRequestMutation = gql`
mutation requestOtp($phone:String!){
  requestOtp(phone:$phone){
    otp{
      id,
      userId,
      phone,
      status
    }
  }
}
`;

export const verifyOtpMutation = gql`
mutation ($phone:String!,$code:String!){
  verifyOtp(phone:$phone, code:$code){
    otp{
      phone
      status
    }
  }
}
`;

export const updateAccountMutation = gql`
  mutation AccountUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      user {
        id
        email
        firstName
        lastName
        phone
        dob
        gender
      }
      errors {
        field
        code
        message
      }
    }
  }
`;

export const verifyPhoneOtpStatusQuery = gql`
query{
  otpStatus(phone: $phone){
    phone
    status
    id
    userId
}
}
`


// default saleor sdk won't reply 'phone'
export const getUserDetail = gql`
  query {
    me {
      id
      phone
    }
  }
`;

import React from "react";
import dynamic from "next/dynamic";
import { maybe } from "@utils/misc";

import { CachedImage } from "..";
import { IProps } from "./types";

const PlaceholderImage = dynamic(
  () => import("@components/atoms").then((mod) => mod.PlaceholderImage),
);

export const Thumbnail: React.FC<IProps> = ({
  source,
  variant,
  children,
  ...props
}: IProps) => {

  let { thumbnail, thumbnail2x, name = '' } = source;

  if (variant?.media && variant?.media[0]?.url) {
    thumbnail.url = variant?.media[0]?.url;
    thumbnail2x.url = variant?.media[0]?.url;
  }

  if (!thumbnail && !thumbnail2x) {
    return <PlaceholderImage />;
  }

  return (
    <CachedImage
      {...props}
      title={name}
      url={maybe(() => thumbnail!.url)}
      url2x={maybe(() => thumbnail2x!.url)}
      alt={maybe(() => (thumbnail!.alt ? thumbnail!.alt : name), name)}
    >
      {children}
    </CachedImage>
  );
};

import Link from "next/link";
import { generatePath } from "react-router";
import React, { useEffect, useState } from "react";
import { Icon as IconifyIcon } from '@iconify/react';
import { FormattedMessage, useIntl } from "react-intl";

import * as S from "./styles";
import { paths } from "@paths";
import { IProps } from "./types";
import { Loader } from "@temp/components";
import { commonMessages } from "@temp/intl";
import { CachedImage } from "@components/molecules";
import { useEarliestDeliveryTime } from "@hooks/useEarliestDeliveryTime";
import { ErrorMessage, Icon, IconButton, Input } from "@components/atoms";

const QuantityButtons = (
  add: (itemStatus: any) => void,
  subtract: (itemStatus: any) => void,
  index?: number,
  variant?: any,
  quantity?: number,
) => {
  const { product } = variant
  const { refetchDeliveryTime } = useEarliestDeliveryTime(product, variant);
  const [subLoading, setSubLoading] = useState(false)
  const [addLoading, setAddLoading] = useState(false)
  return <S.QuantityButtons data-test="quantityControls">
    <div onClick={!subLoading ? async () => {
      if (quantity - 1 >= 1) {
        setSubLoading(() => true)
        const itemStatus = await refetchDeliveryTime(product, variant, quantity - 1, true)
        subtract(itemStatus)
        setSubLoading(() => false)
      }
    } : undefined} data-test="subtractButton">
      {subLoading ? <Loader circle height={20} color="#323232" /> : <Icon size={16} name="horizontal_line" />}
    </div>
    <div onClick={!addLoading ? async () => {
      setAddLoading(() => true)
      const itemStatus = await refetchDeliveryTime(product, variant, quantity + 1, true)
      setAddLoading(() => false)
      add(itemStatus)
    } : undefined} data-test="increaseButton">
      {addLoading ? <Loader circle height={20} color="#323232" /> : <Icon size={16} name="plus" />}
    </div>
  </S.QuantityButtons>
};

/**
 * Product row displayed on cart page and in cart sidebar
 */
export const CartRow: React.FC<IProps> = ({
  index,
  totalPrice,
  unitPrice,
  name,
  sku,
  quantity,
  maxQuantity,
  onQuantityChange,
  thumbnail,
  attributes = [],
  onRemove,
  id,
  slug,
  type = "responsive",
  draughtProductsInCart = [],
  productDelayingDelivery,
  cartType = 'sider',
  variant,
}: IProps) => {
  const [tempQuantity, setTempQuantity] = useState<string>(quantity.toString());
  const [isTooMuch, setIsTooMuch] = useState(false);
  const [status, setStatus] = useState(true);
  const [statusText, setStatusText] = useState('');
  const intl = useIntl();

  const handleBlurQuantityInput = () => {
    let newQuantity = parseInt(tempQuantity, 10);

    if (isNaN(newQuantity) || newQuantity <= 0) {
      newQuantity = quantity;
    } else if (newQuantity > maxQuantity) {
      newQuantity = maxQuantity;
    }

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    const newTempQuantity = newQuantity.toString();
    if (tempQuantity !== newTempQuantity) {
      setTempQuantity(newTempQuantity);
    }

    setIsTooMuch(false);
  };

  useEffect(() => {
    setTempQuantity(quantity.toString());
  }, [quantity]);

  const add = React.useCallback(
    () => quantity < maxQuantity && onQuantityChange(quantity + 1),
    [quantity], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const subtract = React.useCallback(
    () => quantity > 1 && onQuantityChange(quantity - 1),
    [quantity], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleQuantityChange = (evt: React.ChangeEvent<any>) => {
    const newQuantity = parseInt(evt.target.value, 10);

    setTempQuantity(evt.target.value);

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  const preSubtract = (itemStatus) => {
    if (itemStatus?.status) {
      subtract()
    }
    setStatusText(() => itemStatus?.text)
    setStatus(() => itemStatus?.status)
  }

  const preAdd = (itemStatus) => {
    if (itemStatus?.status) {
      add()
    }
    setStatusText(() => itemStatus?.text)
    setStatus(() => itemStatus?.status)
  }

  const quantityErrors = isTooMuch
    ? [
      {
        message: intl.formatMessage(commonMessages.maxQtyIs, { maxQuantity }),
      },
    ]
    : !status
      ? [
        {
          message: statusText,
        },
      ] : undefined;

  const productUrl = generatePath(paths.product, { slug });

  return (
    <S.Container>
      <S.Wrapper cartRowType={type} data-test="cartRow" data-test-id={sku}>
        <S.Photo cartRowType={type}>
          <Link href={productUrl}>
            <a>
              <CachedImage data-test="itemImage" {...thumbnail} />
            </a>
          </Link>
        </S.Photo>
        <S.Description cartRowType={type}>
          <Link href={productUrl}>
            <a>
              <S.Name data-test="itemName">{name}</S.Name>
            </a>
          </Link>
          <S.Sku>
            <S.LightFont>
              <FormattedMessage id="SKU" {...commonMessages.sku} />:{" "}
              <span data-test="itemSKU">{sku || "-"}</span>
            </S.LightFont>
          </S.Sku>
        </S.Description>
        <S.Quantity cartRowType={type}>
          <Input
            readOnly
            name="quantity"
            label={intl.formatMessage(commonMessages.quantity)}
            value={tempQuantity}
            onBlur={handleBlurQuantityInput}
            onChange={handleQuantityChange}
            contentRight={QuantityButtons(preAdd, preSubtract, index, variant, quantity)}
            error={!!quantityErrors?.length}
          />
          <S.ErrorMessages>
            <ErrorMessage errors={quantityErrors} />
          </S.ErrorMessages>
        </S.Quantity>
        <S.Trash>
          <IconButton
            testingContext="removeButton"
            testingContextId={sku}
            size={22}
            name="trash"
            onClick={onRemove}
          />
        </S.Trash>

        <S.TotalPrice cartRowType={type}>
          <S.PriceLabel cartRowType={type}>
            <S.LightFont>
              <FormattedMessage id="Total Price" {...commonMessages.totalPrice} />:
            </S.LightFont>
          </S.PriceLabel>
          <p data-test="totalPrice">{totalPrice}</p>
        </S.TotalPrice>
        <S.UnitPrice cartRowType={type}>
          <S.PriceLabel cartRowType={type}>
            <S.LightFont>
              <FormattedMessage id="Price" {...commonMessages.price} />:
            </S.LightFont>
          </S.PriceLabel>
          <p data-test="unitPrice">{unitPrice}</p>
        </S.UnitPrice>
      </S.Wrapper>
      {(draughtProductsInCart.includes(id) || productDelayingDelivery === id) &&
        <S.DeliveryWarning type={cartType} className="rounded-16">
          <IconifyIcon icon="octicon:alert-16" style={{
            color: '#C22D74', 
            fontSize: '110px',
            transition: 'fill .3s',
            textAlign: 'center',
          }}/>
          <p>
            <FormattedMessage id={draughtProductsInCart.includes(id)
              ? 'This item delays delivery time by 3 days. If you do not wish any delays, please remove this item from cart.'
              : 'This item delays delivery time by up to 7 days. If you do not wish any delays, please remove this item from cart.'
            } defaultMessage={draughtProductsInCart.includes(id)
              ? 'This item delays delivery time by 3 days. If you do not wish any delays, please remove this item from cart.'
              : 'This item delays delivery time by up to 7 days. If you do not wish any delays, please remove this item from cart.'
            }/>
          </p>
        </S.DeliveryWarning>
      }
    </S.Container>
  );
};


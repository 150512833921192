import gql from "graphql-tag";

export const getDeliverySlotsQuery = gql`
  query GetDeliveryInfo($data: JSONString!) {
    getDeliveryInfo(data: $data) 
  }
`

export const setDeliveryInfoQuery = gql`
  query setDeliveryInfo($data: JSONString!) {
    setDeliveryInfo(data: $data) 
  }
`

export const getDraughtTypeQuery = gql`
  query GetDraughtType {
    productTypes(first: 100, filter: { search: "Draught"}) {
        edges {
          node {
            id
            name
          }
        }
      }
  }
`
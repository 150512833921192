/* eslint-disable @typescript-eslint/no-shadow */
import ReactSVG from "react-svg";
import React, { useEffect, useState } from 'react';

import './styles.module.scss';
import { getSaleorApi } from "@utils/ssr";
import xImg from "../../images/ion_close-outline.svg";
import { getCollectionsQuery } from "@temp/queries/queries";

export const Banner = () => {
    const [isBanner, setIsBanner] = useState(true)
    const [topBannerImg, setTopBannerImg] = useState()
    const [topBannerMobileImg, setTopBannerMobileImg] = useState()
    const [id, setId] = useState();
    const [mobileColorCode, setMobileColorCode] = useState('#ffffff')
    const [desktopColorCode, setDesktopColorCode] = useState('#ffffff')

    const getTopBanner = async () => {
        const { apolloClient } = await getSaleorApi();
        const hasBackgroundImage = item => item.node.backgroundImage

        const { data, errors, loading } = await apolloClient
            .query({
                query: getCollectionsQuery,
                fetchPolicy: 'no-cache',
                variables: { 
                channel: 'default-channel',
                perPage: 100,
                filter: { metadata: [{ key: "top-banner", value: "true" }] },
                },
            })
    
        if (!loading && !errors) {
            let filterdata = data?.collections?.edges.filter(hasBackgroundImage)

            filterdata?.forEach(item => {
                const displayTrue = item?.node?.metadata?.find(item => item.key === 'display' && item.value === 'true')
                const mobileTrue = item?.node?.metadata?.find(item => item.key === 'mobile' && item.value === 'true')
                const colorCode = item?.node?.metadata?.find(item => item.key === 'colour-code')
                if (displayTrue) {
                    setId(item?.node?.slug)
                    if (mobileTrue) {
                        setTopBannerMobileImg(item?.node?.backgroundImage.url)
                        if (colorCode && colorCode?.value !== "") {
                            setMobileColorCode(colorCode?.value)
                        }
                    } else {
                        setTopBannerImg(item?.node?.backgroundImage.url)
                        if (colorCode && colorCode?.value !== "") {
                            setDesktopColorCode(colorCode?.value)
                        }
                    }
                }
            })
        }
    }

    useEffect(()=> {
        getTopBanner()
    }, [])

    useEffect(()=> {
        if (topBannerMobileImg && topBannerImg && isBanner) {
            let classes = document.getElementsByClassName('search-location-div')
            if (window.innerWidth >= 768) {
                if (classes.length > 0) {
                    for (let i = 0; i < classes.length; i++) {
                        classes[i].style.top = '120px';
                    }
                }
            } else if (classes.length > 0) {
                for (let i = 0; i < classes.length; i++) {
                    classes[i].style.top = '128px'
                }
            }
        } else if (!(topBannerMobileImg && topBannerImg) || !isBanner) {
            let classes = document.getElementsByClassName('search-location-div')
            if (classes.length > 0) {
                for (let i = 0; i < classes.length; i++) {
                    classes[i].style.top = '80px'
                }
            }
        }
    }, [topBannerMobileImg, topBannerImg, isBanner])

    

    const closeBanner = () =>{
        setIsBanner(false);
    }
    
    return (
        <>
        {isBanner && topBannerMobileImg && topBannerImg ?
            <div className='top_sticky_banner' style={{ backgroundColor: `${window.innerWidth >= 768 ? desktopColorCode : mobileColorCode}` }}>
                <div id={id} className='top_sticky_banner__img' style={{ backgroundImage:`url(${window.innerWidth >= 768 ? topBannerImg : topBannerMobileImg })` }}>
                </div>  
                <button onClick={closeBanner} className='top_sticky_banner__close-logo'>
                    <ReactSVG path={xImg}/>
                </button>
            </div>
        : 
            <></> 
        }
        </>
    )
}